export enum MaintenanceStatus {
  Default = '',
  NotStarted = 'not_started',
  Started = 'started',
  Finished = 'finished',
  Expired = 'expired',
  Cancelled = 'cancelled',
  Error = 'error',
  NoRoute = 'no_route',
}

export interface Coordinates {
  lng: number;
  lat: number;
}

export interface RouteObject {
  duration: {
    pure: number;
    traffic: number;
  };
  distance: number;
  start: {
    latitude: number;
    longitude: number;
  };
  end: {
    latitude: number;
    longitude: number;
  };
  geometry: {
    polyline: string;
    type: string;
  };
}

export interface UrgentServiceInfo {
  userUuid: string;
  userName: string;
  userCoordinates: Coordinates;
  vehicleCoordinates: Coordinates;
  maintenanceStatus: MaintenanceStatus;
  vehiclePlateNumber: string;
  routes: Array<RouteObject>;
}
