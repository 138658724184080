
































import { isMobile } from '@/mixins/isMobile';
import { adaptBlockToMobile } from '@/utils/adaptBlockToMobile';

export default {
  name: 'SafetyMemo',
  mixins: [isMobile],
  mounted() {
    this.initSafetyMemo();
    window.addEventListener('resize', this.initSafetyMemo);
  },
  destroyed() {
    window.removeEventListener('resize', this.initSafetyMemo);
  },
  methods: {
    initSafetyMemo() {
      adaptBlockToMobile('.safety-memo-container');
    },
    onClickOutside(e: Event) {
      if (
        e.target === this.$refs.safetyNote ||
        e.composedPath().includes(this.$refs.safetyNote)
      ) {
        return;
      }
      this.$emit('close-memo');
    },
  },
};
