import Vue from 'vue';
import App from './App.vue';
// import './registerServiceWorker';

import vSelect from 'vue-select';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import { VueHammer } from 'vue2-hammer';
import i18n from './locales/i18n';

import store from './store';
import router from './router';

window.$ = window.jQuery = require('jquery');
window.fn = require('owl.carousel');

Vue.component('v-select', vSelect);
Vue.component('VuePerfectScrollbar', VuePerfectScrollbar);

Vue.use(VueHammer);

Vue.config.productionTip = false;
i18n.locale =
  localStorage.getItem('lang') || navigator.language.split('-')[0] || 'en';
i18n.fallbackLocale = 'en';

VueHammer.config.pan = {
  direction: 'vertical',
};

/* eslint-disable no-new */
new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    jQuery: any;
    $: any;
    fn: any;
  }
}
