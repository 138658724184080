import Vue from 'vue';
import store from './store/index';
import Router from 'vue-router';

import ENV from '@/environment';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/components/Home.vue'),
    },
    {
      path: '/how',
      name: 'How',
      component: () => import('@/components/How.vue'),
    },
    {
      path: '/map',
      name: 'Map',
      redirect: '/',
    },
    {
      path: '/about',
      name: 'About',
      component: () => import('@/components/About.vue'),
    },
    {
      path: '/contacts',
      name: 'Contacts',
      component: () => import('@/components/Contacts.vue'),
    },
    {
      path: '/feedback',
      name: 'FOS',
      component: () => import('@/components/FOS.vue'),
      beforeEnter: (to, from, next) => {
        if (ENV.REGION === 'ru') {
          next();
          return;
        }
        next('/');
      },

      children: [
        {
          path: '',
          name: 'FOSForm',
          component: () => import('@/components/FOS/FOSForm.vue'),
        },
        {
          path: 'agreement',
          name: 'FOSAgreement',
          component: () => import('@/components/FOS/FOSAgreement.vue'),
        },
      ],
    },
    {
      path: '/faq',
      name: 'FAQ',
      component: () => import('@/components/FAQ.vue'),
    },
    {
      path: '/terms',
      name: 'Rules',
      component: () => import('@/components/Rules.vue'),
    },
    {
      path: '/terms/:id',
      name: 'Article',
      component: () => import('@/components/Article.vue'),

      props: true,
    },
    {
      path: '/vehiclerentalcontract',
      redirect: '/terms/vehicle-rental-contract',
    },
    {
      path: '/dogovor',
      redirect: '/terms/contract',
    },
    {
      path: '/competition',
      name: 'Competition',
      component: () => import('@/components/Competition.vue'),
    },
    {
      path: '/service/:serviceIdentifier',
      name: 'UrgentService',
    },
    {
      path: '/search_by_car_number',
      name: 'SearchCar',
      component: () => import('@/components/CarSearch.vue'),
    },
    {
      path: '*',
      redirect: { name: 'Home' },
    },
    {
      path: '/guido-privacy-policy',
      name: 'Article',
      component: () => import('@/components/Article.vue'),
      props: { id: 'guido-policy' },
    },
    {
      path: '/agreement-breaker',
      name: 'Article',
      component: () => import('@/components/Article.vue'),
      props: { id: 'agreement-breaker' },
    },
  ],
});

router.beforeResolve((to, from, next) => {
  if (from.name && to.name === 'Home') {
    if (!store.state.home.firstLoad) {
      store.commit('home/hideVideo');
    }
  }
  next();
});

export default router;
