export default {
  namespaced: true,

  state: {
    firstLoad: true,
    showVideo: true,
  },
  mutations: {
    onFirstLoad(state) {
      state.firstLoad = false;
    },
    hideVideo(state) {
      state.showVideo = false;
    },
  },
  actions: {},
};
