import Vue from 'vue';
import axios from 'axios';

export default {
  namespaced: true,

  state: {
    apiUrl:
      process.env.VUE_APP_API_HOST ||
      'https://delimobil.webwalks.ru/api/api.php',
    pageLoaded: false,
    pagesLoaded: {},
    pages: {},
  },
  mutations: {
    pageLoaded(state, params) {
      state.pageLoaded = true;
      state.pagesLoaded[params.page] = true;
    },
  },
  actions: {
    async loadData({ commit, state }, params) {
      const response = await axios.get(state.apiUrl, {
        params: {
          action: 'loadData',
          page: params.page,
        },
      });

      const data = response.data.data;

      Vue.set(state.pages, params.page, data);
      commit('pageLoaded', params);
    },
  },
};
