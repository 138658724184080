<template>
  <div class="dropdown">
    <a class="link" @click="toggle()">{{ $t('lang.menu.language') }}</a>

    <ul v-if="opened">
      <li></li>
      <li
        class="link"
        v-for="(option, index) in options"
        :key="index"
        @click="handleSelect(option)"
      >
        {{ option.title }}
      </li>
      <li></li>
    </ul>
  </div>
</template>

<script>
// import DropdownOption from './DropdownOption';

export default {
  name: 'Dropdown',
  props: {
    options: {
      type: Array, // Array<DropdownOption>
      required: true,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      opened: false,
    };
  },
  methods: {
    toggle() {
      this.opened = !this.opened;
    },
    handleSelect(e) {
      this.$emit('change', e.value);
      this.opened = !this.opened;
    },
  },
};
</script>

<style lang="less" scoped>
@import '../../assets/css/_variables';

.dropdown {
  top: 2.60416667vw;
  right: 1.70833333vw;
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  > a {
    font-family: @EuclidSemibold;

    &:hover,
    &.active {
      text-decoration: none;
      color: @gray4;
      background: @gray5;
    }
  }
  &.hidden {
    top: -100px;
  }
}

.dropdown {
  font-family: @EuclidSemibold;
  ul {
    position: absolute;
    top: 70 / 1920 * 100vw;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  ul li {
    background-color: #e6eaed;
    padding: 10px 20px;
  }

  ul li:hover {
    background-color: #fff;
  }

  ul li:first-child {
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    padding: 10px;
  }
  ul li:first-child:hover,
  ul li:last-child:hover {
    background-color: #e6eaed;
  }

  ul li:last-child {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    padding: 10px;
  }
}

@media screen and (max-width: 767px) {
  .dropdown {
    top: 50 / 1920 * 100vw;
    right: 52 / 1920 * 100vw;

    a {
      font-size: 5.625vw;
      line-height: 5.625vw;
      height: 15.625vw;
      border-radius: 7.8125vw;
      padding: 4.6875vw 5.625vw 0;
      margin: 7.8125vw 0;
    }

    &.hidden {
      top: -100 / 1920 * 100vw;
    }

    ul {
      position: absolute;
      top: 50%;
    }
  }
}
</style>
