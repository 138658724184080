/* eslint-disable camelcase */
const full_name = 'full_name';
const login = 'login';
const client_id = 'client_id';
const rent_id = 'rent_id';
const digital_signature = 'digital_signature';
const renter = 'renter';

export const carRentContract = `
<div>
  <p align="right"><strong>УТВЕРЖДЕНО</strong> Приказом № ДД-28 от &laquo;18&raquo; ноября 2019 г.<br/> (в редакции
    Приказа № ДД-69 от &laquo;30&raquo; марта 2022 г., вступило в силу 00:00 01.04.2022)</p>
  <br/>
  <p align="center"><strong>ДОГОВОР<br/> АРЕНДЫ ТРАНСПОРТНОГО СРЕДСТВА № {____________}<br/></strong> (Договор, Договор
    аренды ТС)</p>
  <br/>
  <p>Общество с ограниченной ответственностью &laquo;Каршеринг Руссия&raquo; (ОГРН 1237700701534, ИНН 9718236471),
    именуемое в дальнейшем &laquo;Арендодатель&raquo;, с одной стороны, и _____, именуемый(-ая) в дальнейшем &laquo;Арендатор&raquo;,
    с другой стороны, при совместном упоминании в дальнейшем именуемые &laquo;Стороны&raquo;, заключили настоящий
    Договор (далее &ndash; "Договор") о нижеследующем.</p>
  <br/>
  <p align="center"><strong>ПРЕАМБУЛА</strong></p>
  <p>Настоящий Договор не является договором проката</p>
  <p>Настоящий Договор является офертой, адресованной действительным активированным и незаблокированным Пользователям
    Сервиса Делимобиль и соответствующим на момент заключения настоящего Договора требованиям, предъявляемым к
    Пользователям в соответствии с Договором Делимобиль.</p>
  <p>Оферта на условиях настоящего Договора (Предварительного и Основного договора аренды ТС) считается направленной с
    момента отображения ее в Мобильном приложении на стадии бронирования ТС. Арендодатель вправе отозвать оферту в любой
    момент до ее акцепта.</p>
  <p>Пользователь в случае присоединения к настоящему Договору однозначно определил и оценил свою возможность и
    необходимость заключения настоящего Договора (присоединения к нему).</p>
  <p>Настоящий Договор является договором присоединения постольку, поскольку его условия определены Арендодателем и
    принимаются только посредством присоединения к Договору в целом (ст. 428 Гражданского кодекса Российской
    Федерации).</p>
  <p>Термины и определения, используемые в настоящем Договоре, определены Сторонами в Договоре Делимобиль (<a
    href="https://delimobil.ru/terms/contract">https://delimobil.ru/terms/contract</a>). Термины и определения, не
    раскрытые в настоящем Договоре или Договоре Делимобиль, применяются в значении, определенном действующим
    законодательством.</p>
  <p align="center">1. ОБЩИЕ УСЛОВИЯ</p>
  <p align="center">Предмет договора</p>
  <p>1.1. По настоящему Договору Арендодатель предоставляет Арендатору (в т.ч. в лице Поверенного, включенного в список
    Поверенных и действующего от имени Арендатора), во временное владение и пользование (аренда) ТС без оказания услуг
    по управлению им, а Арендатор обязуется использовать ТС в соответствии с условиями настоящего Договора, Договора
    Делимобиль и иными документами об использовании Сервиса.</p>
  <p>1.2. Конкретный объект аренды, режим использования Сервиса (&laquo;Личный&raquo;, &laquo;Корпоративный&raquo;),
    тарифы и прочие условия аренды ТС выбираются Арендатором в Мобильном приложении перед бронированием ТС (заключением
    Предварительного договора аренды ТС) в соответствии с порядком, определенном Договором Делимобиль и функционалом
    Мобильного приложения.</p>
  <p>1.3. Заключая Договор, Арендатор подтверждает, что он:</p>
  <p>1.3.1. в полной мере ознакомился с условиями настоящего Договора, Договора Делимобиль, документами об использовании
    Сервиса, понимает их содержание, согласен с ними и обязуется исполнять установленные ими требования;</p>
  <p>1.3.2. соответствует требованиям, предъявляемым к Пользователю Сервиса Делимобиль, в т.ч. имеет действительное
    право на управление ТС;</p>
  <p>1.3.3. соответствует требованиям, предъявляемым к Арендатору для заключения настоящего Договора в отношении
    конкретного ТС;</p>
  <p>1.3.4. согласен с размещением в ТС и использованием Арендодателем технических средств, позволяющих отследить
    местонахождение ТС, а также возможные нарушения Договора (датчик дыма, GPS-маяк, фото- и/или видеонаблюдение в
    салоне ТС и проч.), с записью разговоров при обращении к Арендодателю, использованием данных геолокации и истории
    Сессий аренды;</p>
  <p>1.3.5. согласен с тем, что для целей организации взыскания (в т.ч. в безакцептном порядке) основания и размер
    взыскания определяются в соответствии с Договором. Данное условие не исключает право Арендатора на выражение
    несогласия с взысканными суммами, а также на применения методов защиты, предусмотренных законом.</p>
  <p>1.3.6. предупрежден об административной ответственности:</p>
  <p>а) по ст.ст. 12.8 (&laquo;Управление транспортным средством водителем, находящимся в состоянии опьянения, передача
    управления транспортным средством лицу, находящемуся в состоянии опьянения&raquo;), 12.26 (&laquo;Невыполнение
    водителем транспортного средства требования о прохождении медицинского освидетельствования на состояние опьянения&raquo;)
    КоАП РФ;</p>
  <p>б) по ст.ст. 264 (&laquo;Нарушение правил дорожного движения и эксплуатации транспортных средств&raquo;), 264.1 (&laquo;Нарушение
    правил дорожного движения лицом, подвергнутым административному наказанию&raquo;) УК РФ.</p>
  <p>1.4. Пользователь по Договору Делимобиль, заключая настоящий Договор как Поверенный, обязуется соблюдать
    требования, связанные с порядком использования ТС, предусмотренные настоящим Договором, Договором Делимобиль и иными
    документами об использовании Сервиса.</p>
  <p>1.5. Требования к возрасту и стажу, предъявляемые к Арендатору для заключения настоящего Договора в отношении
    конкретного ТС:</p>
  <table style="margin-left: 5.4pt; border-collapse: collapse; border: none;">
    <tbody>
    <tr>
      <td style="width: 123.5pt; border: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: center;"><strong>Модель, марка</strong></p>
      </td>
      <td
        style="width: 59.95pt; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: center;"><strong>Возраст</strong></p>
      </td>
      <td
        style="width: 83.45pt; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: center;"><strong>Водительский стаж</strong></p>
      </td>
      <td
        style="width: 241.65pt; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: center;"><strong>Иное</strong></p>
      </td>
    </tr>
    <tr>
      <td
        style="width: 123.5pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; height: 38.95pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: center;">Независимо от модели, марки ТС</p>
      </td>
      <td
        style="width: 59.95pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 38.95pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: center;">18 лет</p>
      </td>
      <td
        style="width: 83.45pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 38.95pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: center;">0 лет</p>
      </td>
      <td
        style="width: 241.65pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 38.95pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">отсутствие у Арендатора задолженности перед Арендодателем</p>
      </td>
    </tr>
    </tbody>
  </table>
  <p>Доступность конкретных автомобилей для аренды может дополнительно определяться специальным алгоритмом, учитывающим
    в т.ч. длительность использования Сервиса, количество оплаченных поездок в Сервисе, безаварийную езду, отсутствие
    задолженности, отсутствие нарушений. Общим требованием является получение Арендатором одобрения службы безопасности
    Арендодателя по результатам комплексной оценки рисков заключения Договора аренды ТС с данным Арендатором.
    Арендодатель не гарантирует наличие или доступность для аренды желаемых Арендатором ТС.</p>
  <p>1.6. Арендодатель обязуется включать Арендатора, являющегося физическим лицом (кроме Поверенных) и
    присоединившегося к Договору Делимобиль для личного использования, имеющего водительский стаж менее 1 (одного) года
    (исходя из даты выдачи водительского удостоверения), в реестр застрахованных лиц по договору группового /
    коллективного страхования от несчастных случаев, заключенному между Арендодателем и страховой компанией (договор в
    пользу третьего лица, по которому Арендатор является выгодоприобретателем). Включение иных Арендаторов в указанный
    реестр является правом Арендодателя.</p>
  <p>Заключая настоящий Договор, Арендатор дает свое согласие на включение Арендатора в реестр застрахованных лиц по
    договору группового / коллективного страхования от несчастных случаев, заключенному между Арендодателем и страховой
    компанией (договор в пользу третьего лица, по которому Арендатор является выгодоприобретателем). Включение в реестр
    застрахованных лиц не влечет дополнительных расходов Арендатора.</p>
  <p align="center">2. Заключение Договора. Порядок приема-передачи и использования ТС</p>
  <p>2.1. Заключение настоящего Договора (Предварительного или Основного договора аренды ТС) осуществляется посредством
    присоединения (ст. 428 ГК РФ) к нему Арендатора в электронной форме через Мобильное приложение. При этом создается
    электронная подпись в порядке, определенном Договором Делимобиль. Действия по бронированию (заключению
    Предварительного договора аренды ТС), принятию ТС по Акту приема-передачи ТС (заключению Основного договора аренды
    ТС), а также иные действия в Мобильном приложении осуществляются в электронной форме посредством нажатия активных
    клавиш (использование электронной подписи). В целях обеспечения безопасности Арендодатель оставляет за собой право
    на любой стадии заключения Договора требовать от Арендатора сделать селфи (фотографирование Арендатором самого себя)
    с использованием Мобильного приложения и с соблюдением определенных Арендодателем требований, и направить фотографию
    Арендодателю, а при несоблюдении указанных требований &ndash; отказать в заключении Договора (отозвать оферту).</p>
  <p>В случае введения пропускного режима на территории субъекта Российской Федерации, для заключения Предварительного и
    / или Основного Договора аренды ТС Мобильное приложение может предусматривать необходимость ввода сведений о
    выданном Пользователю пропуске с проверкой его действительности и срока действия. В заключении Предварительного /
    Основного договора аренды ТС может быть отказано случаях отсутствия подтверждения валидности пропуска на заявленный
    период аренды.</p>
  <p>В целях обеспечения безопасности заключение Предварительного Договора аренды ТС Мобильное приложение может
    предусматривать необходимость прохождения Пользователем тестирования, определяющего скорость реакции,
    внимательность, а также иные характеристики. Успешное завершение тестирования является дополнительным требованием
    для допуска к использованию ТС.</p>
  <p>2.2. Заключение Предварительного договора аренды ТС осуществляется при бронировании ТС Арендатором (в т.ч. в лице
    Поверенного).</p>
  <p>2.3. Бронирование (акцепт заключения Предварительного договора аренды ТС) осуществляется посредством нажатия
    активной клавиши &laquo;Забронировать&raquo; после ознакомления Арендатора с текстом условий Предварительного и
    Основного договора аренды ТС в Мобильном приложении. Отмена бронирования и отказ от заключения Основного договора
    аренды ТС осуществляется посредством нажатия активной клавиши &laquo;Отменить бронь&raquo;. При отказе от заключения
    Основного договора аренды ТС документами об использовании Сервиса может быть предусмотрена неустойка. Бронирование
    при наличии действующего Бронирования или Сессии аренды не допускается.</p>
  <p>2.4. Арендатор в срок, установленный настоящим пунктом, обязуется заключить Основной договор аренды ТС на условиях
    настоящего Договора или отказаться от Предварительного договора аренды ТС, отказавшись от бронирования.</p>
  <p>Для заключения Основного договора аренды ТС Арендатору предоставляется в совокупности 15 минут в 1 час (начиная с
    первого бронирования и далее каждый час), которые являются общими для всех Предварительных договоров (бронирований),
    заключаемых в этот период (1 час). По истечении каждого часа предоставленные 15 минут восстанавливаются. В случае,
    если 15 минут, предоставленные для заключения Основного договора аренды ТС, истекли до истечения часа, срок для
    заключения Основного договора аренды ТС будет составлять 1 минуту с момента начала бронирования (заключения
    Предварительного договора аренды ТС) &ndash; до восстановления срока для заключения Договора аренды ТС.</p>
  <p>2.5. За нарушение срока заключения Основного договора аренды ТС Положением о штрафах может быть предусмотрена
    неустойка.</p>
  <p>2.6. При бронировании ТС одновременно с присоединением к Предварительному договору аренды ТС Арендатор акцептует
    (указывает на) конкретное ТС, индивидуальные признаки которого фиксируются в Мобильном приложении и с необходимостью
    отражаются в Акте приема-передачи ТС при заключении Основного договора аренды ТС.</p>
  <p>2.7. До начала аренды (в период бронирования) Арендатор проводит внешний осмотр ТС. Для того, чтобы начать осмотр,
    Арендатор в Мобильном приложении нажимает кнопку &laquo;Начать осмотр&raquo;. В специальных графах в Мобильном
    приложении Арендатор отмечает выявленные недостатки ТС, с использованием встроенного сервиса Мобильного приложения
    фотографирует ТС для демонстрации недостатков ТС и направляет указанные сведения Арендодателю. Фотографии должны
    быть четкими, сделанными с ракурсов, позволяющих получить информацию о состоянии всех видимых наружных поверхностей
    ТС, а также внутреннего состояния ТС. Допускается дополнительное прицельное фотографирование дефектов ТС. В случае
    если при осмотре ТС не были выявлены внешние повреждения или иные недостатки ТС, равно как в случае получения
    согласия на использование ТС от Арендодателя после направления данных о повреждениях или иных недостатках ТС,
    Арендатор может принять ТС в аренду, подписав Электронной подписью Акт приема-передачи ТС в соответствии с порядком,
    предусмотренным настоящим Договором и Договором Делимобиль. Арендатор в любом случае должен отказаться от аренды в
    случае, если выявленные недостатки исключают допуск ТС к эксплуатации в соответствии с действующим
    законодательством. В случае невыполнения требований настоящего пункта по количеству, качеству и информативности
    предоставляемых фотографий ТС, при последующем подписании Пользователем Акта приема-передачи ТС Пользователь
    считается принявшим ТС в надлежащем состоянии и без повреждений.</p>
  <p>2.8. Заключение Основного договора аренды ТС на условиях настоящего Договора осуществляется при электронном
    подписании Арендатором (в т.ч. в лице Поверенного) Акта приема-передачи ТС, но в любом случае не позднее снятия
    блокировки дверей ТС / запуска двигателя / начала движения (по наиболее раннему событию).</p>
  <p>Аренда ТС (заключение Основного договора аренды ТС) означает, что Арендатор удовлетворен состоянием ТС, а
    Арендодатель исполнил обязательство по предоставлению ТС в техническом состоянии, соответствующем требованиям
    законодательства, условиям Договора аренды ТС и потребностям Арендатора.</p>
  <p>2.9. В случае, если в течение 5 (пяти) минут после начала Сессии аренды, но не далее момента начала движения ТС
    Арендатор завершил Сессию аренды по причине выявления повреждений или иных недостатков ТС, которые не могли быть
    выявлены при внешнем осмотре, Арендатор в специальных графах в Мобильном приложении отмечает указанные повреждения и
    недостатки ТС, фотографирует ТС для демонстрации недостатков ТС и посредством Мобильного приложения направляет
    указанные сведения Арендодателю. В таком случае плата за аренду ТС за период этой Сессии аренды не начисляется.</p>
  <p>2.10. В зависимости от выбранного тарифа в течение Сессии аренды Арендатор может изменять режимы аренды: "Аренда",
    "Ожидание" и прочие предусмотренные функционалом Мобильного приложения режимы, а также приобретать Пакеты с
    включенным временем Сессии аренды ТС ("Продление") посредством нажатия соответствующих кнопок в Мобильном
    приложении.</p>
  <p>2.11. Возвращение в режим "Аренда" из режима "Ожидание" осуществляется посредством нажатия кнопки "Возобновить
    аренду" в Мобильном приложении. Доступность использования режимов Сессии аренды, а также завершения Сессии аренды
    связана в том числе с соблюдением Арендатором требований и ограничений, установленных при применении указанных
    режимов.</p>
  <p>При этом:</p>
  <p>- переход в режим "Ожидание" из режима "Аренда", а также завершение Сессии аренды не допускаются, в том числе, если
    не были соблюдены следующие условия: заглушен двигатель, рычаг трансмиссии переведен в режим &laquo;P&raquo;, стекла
    всех дверей подняты, двери закрыты, пассажиры покинули ТС. Движение ТС в режиме &laquo;Ожидание&raquo; не
    допускается.</p>
  <p>- завершение Сессии аренды в нарушение требований действующего законодательства и Положения о территориальных
    ограничениях в использовании ТС не допускается.</p>
  <p>Мобильным приложением могут быть предусмотрены иные ограничения изменения режимов, обеспечивающие безопасность
    Арендатора и имущества Арендодателя.</p>
  <p>2.12. Для завершения Сессии аренды Арендатор нажимает активную клавишу &laquo;Завершить аренду&raquo; в Мобильном
    приложении. Мобильное приложение предоставляет Арендатору для ознакомления Акт возврата ТС, содержащий сведения о
    ТС, а также дату и время подписания Акта. В случае возникновения повреждения / утраты ТС и оборудования / документов
    ТС / стандартного автомобильного набора и иных обстоятельств, Арендатор должен сообщить Компании. В любом случае
    Арендатор при завершении Сессии аренды проводит фотофиксацию состояния ТС, в т.ч. выявленных повреждений и
    недостатков ТС, и направляет их Компании. Фотографии должны быть четкими, сделанными с ракурсов, позволяющих
    получить информацию о состоянии всех видимых наружных поверхностей ТС, а также внутреннего состояния ТС. Допускается
    дополнительное прицельное фотографирование дефектов ТС. В случае невыполнения требований настоящего пункта по
    количеству, качеству и информативности предоставляемых фотографий ТС предполагается, что обнаруженные впоследствии
    повреждения ТС были совершены в период владения ТС Арендатором. Сессия аренды завершается после подтверждения
    Мобильным приложением завершения Сессии аренды и подписания Акта возврата ТС посредством Электронной подписи. Во
    избежание случайного нажатия активной клавиши "Завершить аренду" Мобильное приложение может предусматривать
    необходимость удержания активной клавиши нажатой в течение определенного времени для подтверждения намерения
    Арендатора завершить Сессию аренды. При подтверждении завершения Сессии аренды двери ТС автоматически
    блокируются.</p>
  <p>2.13. После завершения Сессии аренды Мобильное приложение отображает детали завершенной Сессии аренды, в том числе
    стоимость аренды ТС в соответствии с используемым тарифом. Мобильное приложение предлагает Арендатору осуществить
    оплату поездки.</p>
  <p align="center">3. Срок аренды</p>
  <p>3.1. Если иное не предусмотрено Положением о тарифах, срок аренды исчисляется минутами и не может превышать 23 часа
    59 минут.</p>
  <p>3.2. Период аренды определяется фактическим временем использования (в течение Сессии аренды) ТС. Неполные минуты
    округляются в большую сторону до полной минуты. Момент начала и окончания Сессии аренды фиксируется в Мобильном
    приложении и в программном обеспечении Делимобиль.</p>
  <p>- начало Сессии аренды: момент заключения Основного договора аренды ТС;</p>
  <p>- окончание Сессии аренды: подтверждение завершения Сессии аренды программным обеспечением.</p>
  <p><u>4.1. Права Арендатора:</u></p>
  <p>4.1.1. требовать от Арендодателя исполнения им своих обязательств по Договору;</p>
  <p>4.1.2. получать круглосуточную информационную поддержку, связанную с выполнением Сторонами обязательств по Договору
    аренды ТС (Предварительному и Основному).</p>
  <p><u>4.2. Обязанности Арендатора:</u></p>
  <p>4.2.1. принять в соответствии с настоящим Договором и бережно использовать ТС в строгом соответствии с его
    назначением, требованиями настоящего Договора, документов об использовании Сервиса, инструкциями, указаниями и
    рекомендациями Арендодателя, Правилами страхования, изложенными в страховых полисах условиями, своевременно
    принимать меры по недопущению и предотвращению ущерба ТС;</p>
  <p>4.2.2. использовать ТС только в пределах Разрешенной зоны с учетом ограничений, установленных Договором и
    Положением о территориальных ограничениях в использовании ТС;</p>
  <p>4.2.3. соблюдать требования законодательства о безопасности дорожного движения, в т.ч. при допуске Поверенных к
    использованию ТС, ПДД, правила парковки и иные требования нормативных правовых актов, связанные с использованием ТС,
    а также обеспечивать соблюдение Поверенными требований действующего законодательства, условий настоящего Договора,
    Договора Делимобиль, иных документов об использовании Сервиса с добросовестностью автовладельца;</p>
  <p>4.2.4. использовать ТС самостоятельно, а для Пользователя-юридического лица &ndash; только уполномоченным
    Поверенным, заключившим в отношении данного ТС последовательно Предварительный и Основной договор аренды ТС.
    Передача ТС третьим лицам не допускается;</p>
  <p>4.2.5. обеспечивать сохранность ТС, проверить наличие и обеспечить сохранность документов ТС (топливных/парковочных
    карт, страхового полиса, свидетельства о регистрации ТС, иных документов на ТС), и оборудования ТС (регистрационных
    знаков ТС, ковриков, ключей от ТС, трекера, магнитолы / акустической системы, бортового компьютера, зарядных
    устройств), стандартного автомобильного набора (аптечка, огнетушитель, знак аварийной остановки), иного оборудования
    и документов ТС (далее, соответственно, &laquo;документы на ТС&raquo;, &laquo;оборудование ТС&raquo;, &laquo;стандартный
    автомобильный набор&raquo;);</p>
  <p>4.2.6. своевременно, в соответствии с условиями настоящего Договора, Договора Делимобиль и иных документов об
    использовании Сервиса вносить арендную плату, оплачивать иные платежи и предоставлять документы, связанные с
    исполнением Договора;</p>
  <p>4.2.7. по окончании использования ТС возвратить его в надлежащем техническом состоянии в порядке, предусмотренном
    настоящим Договором;</p>
  <p>4.2.8. в установленном Договором порядке на стадии бронирования ТС провести внешний осмотр предоставляемого в
    аренду ТС на предмет наличия внешних повреждений и иных недостатков, в т.ч. исключающих возможность использования
    ТС.</p>
  <p>4.2.9. немедленно (при первой физической возможности) сообщать в Арендодателю об угоне / хищении ТС, о любом
    повреждении ТС, его неисправностях, поломках, ДТП, претензиях третьих лиц в отношении ТС или в связи с управлением
    таким ТС, о событиях и фактах, в результате которых ТС может быть причинен вред, об утрате права на управление ТС, о
    факте утраты или приведении в негодность оборудования ТС и/или документов на ТС и/или стандартного автомобильного
    набора, сообщить достоверную информацию об указанных фактах и выполнять инструкции Арендодателя, а также требования
    действующего законодательства;</p>
  <p>4.2.10. в случае ДТП, наступления страхового случая, задержания ТС, нарушения Арендатором требований действующего
    законодательства / выявления уполномоченными органами нарушений Арендатор руководствуется требованиями действующего
    законодательства, ПДД, Правилами страхования, непротиворечащими документам об использовании Сервиса и действующему
    законодательству указаниями Арендодателя и принимает меры по сохранности ТС, незамедлительно (при первой физической
    возможности) самостоятельно сообщает об этом в органы ГИБДД и в иные уполномоченные органы, присутствует в
    назначенную дату при рассмотрении вопросов, связанных с ДТП, нарушениями, перемещением (эвакуации) ТС в
    уполномоченных органах государственной власти, своевременно оформляет и получает необходимые и предусмотренные
    действующим законодательством Российской Федерации, ПДД, а также Правилами страхования документы, в т.ч.:</p>
  <p>- извещение о ДТП, &laquo;Европротокол&raquo;;</p>
  <p>- постановление или определение уполномоченного органа государственной власти, с указанием даты, времени и места
    происшествия, участников происшествия, их места проживания или работы, сведений о наличии у них водительских
    удостоверений, нахождения кого-либо из них в состоянии алкогольного опьянения, описанием полученных арендуемым ТС
    повреждений, официально подтверждающую факт наступления соответствующего происшествия и виновных лиц (отсутствие
    происшествия, отсутствие виновных лиц), выданное уполномоченными на то государственными органами;</p>
  <p>- в случае угона / хищения ТС &ndash; письменное заявление о хищении или угоне арендуемого ТС, справку из органов
    МВД РФ о результатах расследования уголовного дела (если применимо);</p>
  <p>- полис ОСАГО;</p>
  <p>- свидетельство о регистрации ТС;</p>
  <p>- протокол задержания транспортного средства;</p>
  <p>- иные документы, разумно и правомерно требуемые Арендодателем.</p>
  <p>Указанные в настоящем пункте надлежащим образом оформленные действительные документы (подлинные экземпляры)
    Арендатор обязуется нарочно передать Арендодателю в течение 1 (одного) рабочего дня с момента их оформления, если
    иные сроки и способы передачи не установлены Арендодателем. Арендатор принимает на себя все финансовые риски,
    вызванные несвоевременным предоставлением надлежаще оформленных документов Арендодателю, в том числе в случае отказа
    страховщика от страховой выплаты либо предъявлением страховщиком регрессных требований или требований в порядке
    суброгации. Ненадлежащим образом оформленные документы, представленные Арендатором, приравниваются к не
    представленным.</p>
  <p>4.2.11. в случае принятия Арендодателем решения о самостоятельном получении документов, связанных со страховым
    случаем, не позднее 1 рабочего дня с момента получения соответствующего требования Арендодателя выдать представителю
    Арендодателя доверенность (с правом передоверия) на получение соответствующих документов и представление своих
    интересов в ГИБДД, территориальных органах внутренних дел, а также в иных уполномоченных органах и организациях;</p>
  <p>4.2.12. по требованию Арендодателя обеспечивать свою явку, явку своих представителей, Поверенных в органы власти, к
    должностным лицам, в организации по вопросам, связанным с использованием Арендатором ТС.</p>
  <p>4.2.13. в случае эвакуации (перемещения) ТС в период Сессии аренды, а равно вне пределов Сессии аренды в связи с
    действиями (бездействием) Арендатора, при первой возможности сообщить об этом Арендодателю.</p>
  <p>Уведомление Арендатора об эвакуации (перемещении) ТС, а равно привлечение Арендатора к процессу возврата ТС со
    специализированной (штрафной) стоянки является правом, но не обязанностью Арендодателя.</p>
  <p>В случае привлечения Арендодателем Арендатора для возврата ТС, Арендодатель предоставляет Арендатору необходимые
    документы для получения разрешения на возвращение ТС со специализированной (штрафной) стоянки: свидетельство о
    регистрации транспортного средства, полис ОСАГО, заверенную копию договора аренды ТС с собственником (если
    применимо), доверенность на представителя Арендодателя. При возвращении ТС Арендодателю со специализированной
    (штрафной) стоянки либо по истечении срока, предоставленного Арендодателем Арендатору для организации возврата ТС со
    специализированной (штрафной) стоянки, Арендатор обязан возвратить Арендодателю все документы, связанные с
    эвакуацией (перемещением) ТС (в т.ч. указанные в настоящем пункте).</p>
  <p>Если Арендодатель принимает решение о возврате ТС силами Арендатора, Арендатор в течение 1 (одного) рабочего дня со
    момента получения соответствующего уведомления Арендодателя, если иной срок не установлен Арендодателем, своими
    силами и за свой счет совершает действия, необходимые для возврата ТС со специализированной (штрафной) стоянки, в
    т.ч. по требованию Арендодателя и в указанные им сроки обеспечивает получение разрешения на возврат ТС со
    специализированной (штрафной) стоянки, свое участие в оформлении протокола об административном
    правонарушении/постановления по делу об административном правонарушении, протокола о задержании ТС, иных документов
    и передает указанные документы Арендодателю в течение 1 (одного) рабочего дня с момента их оформления, если иные
    сроки не установлены Арендодателем. После получения ТС на специализированной (штрафной) стоянке Арендатор оставляет
    ТС с соблюдением требований законодательства, настоящего Договора, Договора Делимобиль и документов об использовании
    Сервиса в месте, указанном Арендодателем.</p>
  <p>Отказ сотрудников уполномоченного органа либо специализированной (штрафной) стоянки в возврате ТС при условии
    предоставления Арендатором этим органам и организациям полученных от Арендодателя документов не является основанием
    для освобождения Арендатора от штрафных санкций по Договору;</p>
  <p>4.2.14. в случаях и в порядке, предусмотренных Договором и приложениями к нему, пополнять уровень топлива в ТС
    топливом того типа и марки, которые указаны заводом-изготовителем, за исключением случаев, когда Арендодателем при
    заправке указано на необходимость использовать иное топливо (в т.ч. с более высоким октановым числом); пополнять
    уровень заряда батарей ТС, использующих электродвигатели, способами и с использованием средств, указанных
    заводом-изготовителем, нормативными актами, если Документами об использовании Сервиса не установлены более жесткие
    требования;</p>
  <p>4.2.15. по окончании Сессии аренды оставить ТС в соответствии с условиями Договора и Положением о территориальных
    ограничениях в использовании ТС с документами на ТС, оборудованием ТС и стандартным автомобильным набором,
    имевшимися на момент принятия ТС. В случае, если в предполагаемом месте оставления ТС отсутствует доступ к системе
    ГЛОНАСС/GPS и(или) к сети Интернет, и(или) мобильной связи, Арендатор обязуется переместить ТС в место, где имеется
    доступ к системе ГЛОНАСС/GPS и(или) к сети Интернет, и(или) мобильной связи.</p>
  <p>4.2.16. самостоятельно обеспечивать сохранность имущества, перемещаемого с использованием ТС;</p>
  <p>4.2.17. нести дополнительные расходы, связанные с эксплуатацией ТС, выходящими за пределы покрытия Арендодателем, в
    т.ч., но не ограничиваясь: оплата за передвижение ТС по платным дорогам, расходы на оплату парковки (за исключением
    оплаты парковки, где в соответствии с Положением о территориальных ограничениях в использовании ТС допускается
    оставление ТС), оплату за перемещение и/ли хранение ТС на специализированной (штрафной) стоянке;</p>
  <p>4.2.18. осуществлять обработку контактных поверхностей ТС перед началом и в период Сессии аренды с использованием
    дезинфицирующих средств, размещенных в ТС (для разрешенной зоны &laquo;Нижний Новгород&raquo;);</p>
  <p>4.2.19. контролировать дорожную обстановку независимо от использования функционала адаптивного круиз-контроля и
    иных средств автоматизации (при их наличии), постоянно оценивать дорожную обстановку для корректировки направления и
    скорости движения транспортного средства во избежание дорожно-транспортных происшествий;</p>
  <p>4.2.20. соблюдать иные обязанности, предусмотренные Договором, Договором Делимобиль, документами об использовании
    Сервиса, а также вытекающие из существа обязательства.</p>
  <p><u>4.3. Арендатору (в т.ч. в лице Поверенных) запрещается:</u></p>
  <p>4.3.1. использовать ТС при несоответствии требованиям, предъявляемым к Пользователю Сервиса Делимобиль, в т.ч. при
    отсутствии действительного права на управление ТС (в т.ч. в части управления ТС с различными типами коробки
    передач);</p>
  <p>4.3.2. использовать ТС при выявлении повреждений или иных недостатков, не исключающих возможность использования ТС
    в соответствии с действующим законодательством, без получения явно выраженного подтверждения Арендодателя;</p>
  <p>4.3.3. нарушать технические требования к эксплуатации ТС, установленные производителем ТС;</p>
  <p>4.3.4. изменять какие-либо характеристики, производить улучшения/ухудшение ТС, монтировать (демонтировать)
    какое-либо оборудование, устройства в ТС, блокировать или затруднять работу устройств (в т.ч. средств аудио-, фото-
    и видеофиксации) осуществлять ремонт (независимо от степени сложности) или организовывать его осуществление третьими
    лицами;</p>
  <p>4.3.5. осуществлять демонтаж, отключение либо порчу оборудования ТС;</p>
  <p>4.3.6. отключать или повреждать трекер (устройства GPS/ГЛОНАСС);</p>
  <p>4.3.7. использовать, оставлять ТС с нарушением Положения о территориальных ограничениях в использовании ТС;</p>
  <p>4.3.8. оставлять ТС с открытыми дверями, окнами, багажником, капотом, в ситуации потенциально возможного причинения
    ТС повреждения, угона, хищения ТС, совершения иных неправомерных действий в отношении ТС;</p>
  <p>4.3.9. заправлять ТС топливом, не предусмотренным Договором и не предназначенным для арендуемого ТС; пополнять
    уровень заряда батарей ТС с нарушением требований и условий, определенных заводом-изготовителем, нормативными
    правилами, Документами об использовании Сервиса;</p>
  <p>4.3.10. продавать, вносить в качестве залога, вклада, любым иным образом отчуждать ТС, утрачивать или передавать
    право владения и пользования ТС, уступать свои права по Договору;</p>
  <p>4.3.11. передавать ТС в пользование третьим лицам (лицам, не заключившим в отношении данного ТС последовательно
    настоящий Предварительный и Основной договор аренды ТС);</p>
  <p>4.3.12. использовать ТС для оказания услуг третьим лицам, в т.ч. для исполнения договоров перевозки (гл. 40 ГК
    РФ);</p>
  <p>4.3.13. использовать ТС в учебных целях;</p>
  <p>4.3.14. использовать ТС в состязаниях/соревнованиях (в том числе неофициальных), пари, конкурсах, испытаниях любого
    вида;</p>
  <p>4.3.15. опасное вождение / дрифт / вождение, которое может повредить транспортному средству, третьим лицам;</p>
  <p>4.3.16. использовать ТС для транспортировки груза, который может повредить или испачкать ТС; груза, ограниченного
    или недопустимого к перевозке соответствующим ТС; опасных грузов; груза, масса которого превышает допустимую для
    соответствующего ТС; для ТС, предназначенных для перевозки грузов (грузовое ТС), суммарная масса ТС с учетом груза и
    пассажиров должна быть менее 3500 кг;</p>
  <p>4.3.17. использовать ТС для езды вне дорог (п. 1.2 ПДД);</p>
  <p>4.3.18. использовать ТС для буксировки;</p>
  <p>4.3.19. использовать ТС для транспортировки груза, за исключением ручной клади (пакеты, сумки, чемоданы, саквояжи,
    дипломаты и подобное). Данное требование не распространяется на использование ТС, предназначенных для перевозки
    грузов (грузовое ТС);</p>
  <p>4.3.20. использовать ТС для транспортировки животных, за исключением транспортировки животных в закрытых
    переносках, обеспечивающих отсутствие непосредственного контакта животных с ТС;</p>
  <p>4.3.21. курить, употреблять электронные сигареты (в т.ч. бестабачных), парогенераторы (вейпы и подобных), кальяны
    (в т.ч. бестабачных), закись азота (кроме употребления при наличии медицинских показаний) в ТС, употреблять
    алкогольные напитки, употреблять наркотические средства, а равно находиться в состоянии опьянения;</p>
  <p>4.3.22. транспортировать лиц в состоянии алкогольного, наркотического или иного опьянения;</p>
  <p>4.3.23. загрязнять ТС в период Сессии аренды ТС. К загрязнению относится также оставление посторонних вещей в ТС
    после окончания Сессии аренды.</p>
  <p><u>4.4. Права Арендодателя:</u></p>
  <p>4.4.1. требовать от Арендатора исполнения своих обязательств по Договору;</p>
  <p>4.4.2. осуществлять видео- и электронное наблюдение в ТС, а также определять порядок доступа Арендатора к ТС в
    целях обеспечения безопасности ТС, а также контроля за надлежащим соблюдением условий Договора;</p>
  <p>4.4.3. в любое время осуществлять контроль за обеспечением сохранности ТС, за техническим состоянием ТС, за
    соблюдением Арендатором условий Договора;</p>
  <p>4.4.4. отозвать оферту о заключении настоящего Договора в любой момент до ее акцепта;</p>
  <p>4.4.5. в случае принятия Арендодателем решения о наличии риска дальнейшего использования ТС, прекратить Сессию
    аренды Арендатора;</p>
  <p>4.4.6. передавать данные Арендатора и его Поверенных в органы государственной власти в целях разрешения вопросов,
    связанных с правонарушениями, допущенными в период Сессии аренды данных лиц;</p>
  <p>4.4.7. передавать права и обязанности по настоящему Договору третьим лицам, а также привлекать третьих лиц к
    исполнению отдельных функций по Договору, в т.ч. на основе агентского договора, без получения дополнительного
    согласия Арендатора. Уведомление Арендатора о данных обстоятельствах осуществляется по усмотрению Арендодателя;</p>
  <p>4.4.8. имеет иные права, установленные Договором и приложениями к нему, а также вытекающие из существа обязательств
    по Договору.</p>
  <p><u>4.5. Обязанности Арендодателя:</u></p>
  <p>4.5.1. предоставлять Арендатору ТС, отвечающее требованиям эксплуатации транспортных средств, укомплектованное и
    пригодное к эксплуатации, с необходимой документацией (свидетельство о регистрации ТС, страховой полис); передача
    указанных документов не оформляется какими-либо дополнительными документами, а происходит одновременно с электронным
    подписанием Акта приема-передачи ТС;</p>
  <p>4.5.2. нести расходы на содержание и ремонт ТС, его страхование (ОСАГО), а также иные расходы, возникающие в связи
    с его эксплуатацией;</p>
  <p>4.5.3. своевременно, за свой счет, в соответствии с действующим законодательством Российской Федерации
    организовывать прохождение технических осмотров ТС;</p>
  <p>4.5.4. обеспечивать ТС горюче-смазочными материалами (топливо, масла и проч) за исключением случаев,
    предусмотренных Договором.</p>
  <p>4.6. В том случае, если Сторона не воспользовалась какими-либо правами, предоставленными ей настоящим Договором,
    соответствующие права не прекращаются, и Сторона имеет право воспользоваться ими в дальнейшем.</p>
  <p align="center"><strong>5. Заправка ТС</strong></p>
  <p>5.1. Заправка (зарядка) ТС осуществляется Арендодателем и привлеченными им третьими лицами за пределами действующих
    Сессий аренды.</p>
  <p>5.2. Арендатор обязан осуществить заправку ТС самостоятельно в случае, если в течение Сессии аренды в ТС загорелась
    лампочка датчика топлива, за исключением случаев, когда для данного ТС не предусмотрена самостоятельная заправка
    Арендатором.</p>
  <p>Арендатор обязан осуществить зарядку батарей ТС, использующих электродвигатели, либо завершить аренду ТС при
    снижении уровня заряда до 25%.</p>
  <p>Заправка топливом осуществляется по топливной карте, находящейся в ТС, либо, в исключительных случаях и по
    согласованию с Арендодателем, без использования топливной карты. Для осуществления заправки Арендатор сообщает
    Арендодателю о необходимости произвести заправку ТС. Оператор Арендодателя сообщает Арендатору о допустимости /
    недопустимости самостоятельной заправки данного ТС, о способах заправки ТС, ближайших заправочных станциях, тип и
    марку топлива, pin-код для использования топливной карты и определяет объем подлежащего пополнению топлива.</p>
  <p>Зарядка батарей ТС, использующих электродвигатели, осуществляется только на специализированных стационарных
    зарядных станциях, совместимых со стандартами IEC60309 //Tesla CHAdeMO (1-3 фазы, напряжение от 230 до 400 В, сила
    тока 10-32А). Для зарядки может использоваться только оборудование ТС и соответствующей специализированной зарядной
    станции. Использование собственных кабелей, а равно заправка вне специализированных стационарных зарядных станций (в
    т.ч. гараж, дом и проч.) &ndash; не допускается. Общее описание процедуры зарядки описано на стр. <a
      href="https://tesla.delimobil.ru/faq">https://tesla.delimobil.ru/faq</a>.</p>
  <p>5.3. Арендодатель не компенсирует затраты Арендатора на заправку (зарядку), а также время аренды ТС в период
    заправки (зарядки) ТС.</p>
  <p>5.4. В случае самостоятельного пополнения Арендатором уровня топлива ТС, Арендодатель предоставляет Арендатору
    Бонусы в следующих размерах:</p>
  <p>А) в случае пополнения уровня топлива ТС посредством использования топливной карты Арендодателя более чем на 20
    литров за 1 заправку &ndash; в размере 70 (семьдесят) Бонусов.</p>
  <p>Б) в случае пополнения уровня топлива ТС без использования топливной карты Арендодателя &ndash; в размере
    документально подтвержденных затрат Арендатора на заправку;</p>
  <p>В) в случае пополнения уровня топлива ТС без использования топливной карты Арендодателя более чем на 20 литров за 1
    заправку &ndash; в размере документально подтвержденных затрат Арендатора на заправку и дополнительно в размере 70
    (семьдесят) Бонусов.</p>
  <p>Бонус предоставляется при условии направления Арендатором (в т.ч. через Поверенного) на электронную почту
    Арендодателя <a href="info@delimobil.ru">info@delimobil.ru</a> сканкопий (фото) кассового чека, подтверждающего
    произведенные затраты, а также фото указателя уровня топлива в ТС до и после заправки - в течение 2 (двух)
    календарных месяцев со дня заправки.</p>
  <p>Бонус предоставляется и используется в порядке, определенном Договором Делимобиль.</p>
  <p>Арендатор обязуется обеспечить предоставление достоверных сведений о фактическом объеме заправленного в
    соответствии с настоящим разделом топлива. Арендатор соглашается с достоверностью сведений об объеме заправленного
    топлива, полученными Арендодателем с использованием Программного обеспечения, фиксирующего показания датчиков
    ТС.</p>
  <p>В случае, если самостоятельная заправка ТС осуществляется в нарушение настоящего раздела Договора, в том числе с
    нарушением сроков предоставления кассового чека или фото указателя уровня топлива, Бонус не предоставляется.</p>
  <p>5.5. Положения п. 5.4 Договора не распространяется на зарядку батарей ТС, использующих электродвигатели.</p>
  <p>5.6. Риск последствий несоблюдения указаний оператора Арендодателя о необходимости осуществить дозаправку ТС
    топливом (зарядку батарей) несет Арендатор, в том числе при вынужденном оставлении ТС.</p>
  <p align="center"><strong>6. Стоимость услуг и порядок оплаты</strong></p>
  <p>6.1. За пользование ТС Арендатор уплачивает Арендодателю арендную плату, а также осуществляет иные платежи, в т.ч.
    возмещение убытков, уплату неустойки (штрафы, пени), уплату сумм администрирования в соответствии с настоящим
    Договором, Договором Делимобиль, документами об использовании Сервиса (в т.ч. Положением о тарифах, Положением о
    штрафах).</p>
  <p>6.2. Сведения о Тарифах размещаются в Мобильном приложении и доступны Арендатору при бронировании ТС.</p>
  <p>6.3. Сведения о задолженности и платежах отображаются в Личном кабинете Арендатора.</p>
  <p>6.4. Оплата осуществляется в порядке, определенном Договором Делимобиль. Обязательство Арендатора по оплате
    считается надлежащим образом исполненным в момент успешного списания денежных средств (в т.ч. в безакцептном
    порядке). Арендатор обязан обеспечивать достаточное количество денежных средств на связанном с Сервисом счете для
    совершения оплаты (в т.ч. безакцептного списания). В случае невозможности списания денежных средств со счета
    Арендатора (в т.ч. в связи с их недостаточностью) в течение 1 (одного) календарного дня со дня первой неудачной
    попытки списания, Арендодатель вправе начислить неустойку в связи с неисполнением денежного обязательства.</p>
  <p>6.5. При оплате денежными средствами с использованием банковской карты возможно холдирование 390 (триста девяносто)
    рублей 00 коп., а при заранее определенной стоимости Сессии аренды &ndash; в пределах такой стоимости.</p>
  <p>6.6. Сервисом может быть предусмотрен функционал, позволяющий Поверенному самостоятельно оплатить задолженность и
    иные платежи, связанные с использованием ТС в качестве Поверенного (в т.ч. с использованием банковской карты,
    связанной с личной Учетной записью Поверенного) от имени Арендатора, Поверенным которого он является. В таком случае
    все платежи Поверенного, поступающие в оплату задолженности Арендатора, Поверенным которого он является, признаются
    платежами такого Арендатора, сделанными Поверенным по его поручению.</p>
  <p align="center"><strong>7. Ответственность</strong></p>
  <p>7.1. За неисполнение или ненадлежащее исполнение Договора Стороны несут ответственность в соответствии с
    действующим законодательством Российской Федерации, настоящим Договором, документами об использовании Сервиса.</p>
  <p>7.2. При наличии у Арендатора, не являющегося потребителем в смысле Закона РФ &laquo;О защите прав потребителей&raquo;,
    задолженности более суток (24 часа), Арендодатель вправе отказаться от Договора с уведомлением Арендатора не позднее
    чем за 5 (пять) минут до прекращения Сессии аренды Арендодателем.</p>
  <p>7.3. За неисполнение или ненадлежащее исполнение Арендатором условий настоящего Договора (в т.ч. приложений к
    нему), Арендодатель вправе взыскать с Арендатора неустойку (штрафы, пени) в размере, определенном Положением о
    штрафах, а также причиненные убытки в полном объеме. <br/>Для целей организации взыскания Стороны установили
    следующий порядок определения размеров убытков Арендодателя, связанных с повреждением ТС: расчет производится
    Арендодателем на основании заключения эксперта, отчета оценщика, в т.ч. с использованием методики Audatex исходя из
    рекомендованных розничных цен на услуги и запасные части у официальных дилеров, а при невозможности такого расчета
    &ndash; исходя из стоимости фактически проведенного ремонта. <br/>Арендатор вправе потребовать проведение
    независимой технической экспертизы для определения объема повреждений ТС и стоимости восстановительного ремонта.
    Такая экспертиза производится за счет Арендатора. При этом Арендатор возмещает Арендодателю убытки (в т.ч. упущенную
    выгоду) за период задержки проведения ремонта и возврата ТС в эксплуатацию.</p>
  <p>7.4. В объем убытков Арендодателя включаются, в т.ч., но не ограничиваясь:</p>
  <p>&bull; убытки Арендодателя, возникшие в результате нарушения Арендатором действующего законодательства Российской
    Федерации и положений Договора в период Сессии аренды ТС, а также после окончания периода Сессии аренды ТС если
    причинами таких расходов явились действия (бездействие) Арендатора;</p>
  <p>&bull; убытки, связанные с оплатой административных штрафов, иных штрафов, пени, в т.ч. наложенные органами власти
    за нарушение ПДД, правил парковки, иных требований действующего законодательства о безопасности дорожного движения
    либо связанных с использованием ТС Арендатором, либо вытекающих из такого использования;</p>
  <p>&bull; убытки, связанные с состоянием арендуемого ТС на момент возврата, в т.ч. в связи с ненормальным износом;</p>
  <p>&bull; расходы на оплату перемещения и хранения ТС на специализированной (штрафной) стоянке;</p>
  <p>&bull; расходы Арендодателя по обязательствам перед третьими лицами, возникшими в связи с действиями (бездействием)
    Арендатора, нарушающими условия Договора и (или) действующего законодательства;</p>
  <p>&bull; расходы на услуги оценщика, экспертов, экспертных и иных организаций, на юридические услуги; комиссионное
    вознаграждение, взимаемое организациями (в том числе кредитными) при оплате Арендодателем денежных средств в связи с
    нарушениями, допущенными Арендатором;</p>
  <p>&bull; убытки, связанные с претензиями третьих лиц в связи с нарушениями, допущенными Арендатором;</p>
  <p>&bull; убытки, связанные с ненадлежащим оформлением Арендатором документов о ДТП (в т.ч. в связи с отказом в
    страховой выплате).</p>
  <p>7.5. Если Договором и документами об использовании Сервиса не предусмотрено иное, неустойка в связи с
    невозможностью использовать ТС из-за нарушений Арендатора, рассчитывается как количество минут, в течение которых ТС
    не могло использоваться из-за таких нарушений Договора (в т.ч. с момента эвакуации ТС до возврата ТС со
    специализированной (штрафной) стоянки), умноженное на стоимость нахождения ТС в режиме &laquo;Ожидание&raquo; в
    соответствии с базовым размером стоимости тарифного плана &laquo;Персональный&raquo;.</p>
  <p>7.6. Суммы администрирования списаний в связи с оплатой Компанией административных штрафов за нарушения, допущенные
    Арендатором, составляют 10 % (десять процентов) от суммы списания, но не менее 175 (сто семьдесят пять) рублей - за
    администрирование 1 (одного) списания.</p>
  <p>7.7. Арендатор несет риск угона, гибели, повреждения ТС, его составных частей, документов на ТС, оборудования ТС,
    стандартного автомобильного набора, иных устройств и/или оборудования, причинения вреда третьим лицам, иным
    транспортным средствам или объектам, в том числе при случайности - с момента начала Сессии аренды и до ее окончания,
    а в случаях, когда действия (бездействие) Арендатора (в т.ч. в лице Поверенного) явилось причиной наступления
    указанных негативных последствий, в т.ч. в случае оставления ТС с нарушениями Договора либо в месте или при
    обстоятельствах, при которых возможно повреждение ТС - также и после окончания Сессии аренды ТС.</p>
  <p>7.8. Неисправности, являющиеся следствием ненормальной эксплуатации ТС, определяются дополнительно в Инструкции по
    определению нормального износа (Приложение к Договору):</p>
  <p>7.8.1. механические повреждения элементов подвески, ходовой, тормозной системы, двигателя, трансмиссии, выхлопной
    системы, автошин, колесных дисков, колпаков колес, иных элементов и систем;</p>
  <p>7.8.2. механическое повреждение элементов кузова, остекления, световых приборов;</p>
  <p>7.8.3. механическое повреждение салона, багажника и/или грузового отсека;</p>
  <p>7.8.4. повреждение элементов двигателя, трансмиссии, ходовой, рулевого управления, топливной системы, системы
    охлаждения, системы кондиционирования, органов, приборов и элементов управления, систем безопасности и прочих систем
    ТС, если это вызвано нарушением правил эксплуатации, установленных производителем ТС, или неправильным обращением со
    стороны Арендатора;</p>
  <p>7.8.5. повреждения, вызванные запрещенной эксплуатацией, как это определено производителем ТС или Договором;</p>
  <p>7.8.6. другие неисправности, возникшие вследствие нарушений условий эксплуатации, определенных производителем ТС, и
    подтвержденных указанным Арендодателем сервисным центром либо независимым экспертом.</p>
  <p>7.9. Арендатор не несет ответственность за:</p>
  <p>7.9.1. повреждение ТС, полученные не по вине Арендатора (в т.ч. в лице Поверенного), что подтверждается
    соответствующими актами органов государственной власти (вступившие в силу постановление уполномоченного органа,
    судебное решение);</p>
  <p>7.9.2. ущерб, причиненный в результате хищения или угона ТС, при условии надлежащего исполнения Арендатором
    требований настоящего Договора, Договора Делимобиль, документов об использовании Сервиса в части порядка возврата ТС
    Арендодателю.</p>
  <p>Арендатор самостоятельно обеспечивает сбор и своевременное предоставление Арендодателю доказательств отсутствия
    своей виновности.</p>
  <p>7.10. Размер взыскания убытков за повреждение ТС (Таблица штрафов за повреждение ТС) с Арендатора в пользу
    Арендодателя по каждому случаю повреждения ТС при наличии оснований для возмещения в соответствии с Договором не
    может превышать:</p>
  <br/>
  <table style="border-collapse: collapse; border: none;">
    <tbody>
    <tr>
      <td style="width: 97.55pt; border: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: center;">Модель, марка поврежденного ТС</p>
      </td>
      <td
        style="width: 7cm; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: center;">Nissan&nbsp;Qashqai,&nbsp;Kia&nbsp;Sportage,&nbsp;Kia&nbsp;Stinger,&nbsp;Kia&nbsp;Soul,&nbsp;Mini&nbsp;Cooper,&nbsp;Ford&nbsp;Transit,&nbsp;VW&nbsp;Tiguan,&nbsp;BMW&nbsp;(независимо
          от модели),&nbsp;Mercedes-Benz&nbsp;(независимо от модели),&nbsp;Audi&nbsp;(независимо от модели),&nbsp;Toyota&nbsp;Camry,&nbsp;Toyota&nbsp;RAV4,&nbsp;Tesla</p>
      </td>
      <td
        style="width: 238.1pt; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: center;">Иные ТС</p>
      </td>
    </tr>
    <tr>
      <td
        style="width: 97.55pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: center;">Размер рассчитанных Арендодателем убытков за повреждение ТС</p>
      </td>
      <td
        style="width: 99.2pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Менее 100&nbsp;000 (сто тысяч) рублей</p>
      </td>
      <td
        style="width: 99.25pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">100&nbsp;000 (сто тысяч) рублей и более</p>
      </td>
      <td
        style="width: 119.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Менее 70&nbsp;000 (семьдесят тысяч) рублей</p>
      </td>
      <td
        style="width: 119.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">70&nbsp;000 (семьдесят тысяч) рублей и более</p>
      </td>
    </tr>
    <tr>
      <td
        style="width: 97.55pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: center;">Максимальный размер взыскания убытков за повреждение ТС</p>
      </td>
      <td
        style="width: 99.2pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">75&nbsp;000 (семьдесят пять тысяч) рублей</p>
      </td>
      <td
        style="width: 99.25pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">75&nbsp;000 (семьдесят пять тысяч) рублей + 25% от размера
          рассчитанных Арендодателем убытков и штрафов за повреждение ТС, превышающих 100&nbsp;000 (сто тысяч)
          рублей</p>
      </td>
      <td
        style="width: 119.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">50&nbsp;000 (пятьдесят тысяч) рублей</p>
      </td>
      <td
        style="width: 119.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">50 000 (пятьдесят тысяч) рублей + 25% от размера рассчитанных
          Арендодателем убытков и штрафов за повреждение ТС, превышающих 70 000 (семьдесят тысяч) рублей</p>
      </td>
    </tr>
    </tbody>
  </table>
  <br/>
  <p>Данное ограничение не распространяется на следующие случаи:</p>
  <p>а) Арендатор не исполнил обязательства, указанные в п. 4.2.9-4.2.15 настоящего Договора;</p>
  <p>б) в течение Сессии аренды имело место нарушение п. 4.3 Договора;</p>
  <p>в) умышленное причинение ТС повреждений, то есть в случаях, когда Арендатор (Поверенный) сознавал неблагоприятные
    последствия своих действий (бездействия), предвидел такие последствия и желал их наступления или сознательно
    допускал их либо относился к ним безразлично;</p>
  <p>г) в случае, если повреждение ТС возникло в связи с выездом арендуемого ТС на полосу встречного движения / проезда
    арендованного ТС на запрещающий сигнал светофора / разворота арендованного ТС в неустановленном для этого ПДД
    месте;</p>
  <p>д) в случае нарушения порядка заправки ТС;</p>
  <p>е) в случае, если непосредственно перед повреждением ТС имело место превышение данным ТС установленной скорости
    движения на величину более 40 км/ч (согласно данным средств фиксации административных правонарушений и/или
    Программного обеспечения), а равно в случаях, если такое превышение явилось причиной повреждения ТС.</p>
  <p>Ограничение ответственности не распространяется также на суммы неустойки за неоплату (несвоевременную оплату)
    подлежащих оплате сумм, а также на суммы возмещения Арендодателю расходов, понесенных им в связи с организацией
    взыскания долга с Арендатора: расходы на досудебное урегулирование, судебные расходы, в т.ч. привлечение сторонних
    специалистов, получение юридических и иных консультаций, почтовые расходы и проч.).</p>
  <p>В случае, если Арендатор допустил просрочку уплаты суммы ограниченного взыскания, предусмотренную в настоящем
    пункте Договора, ограничение ответственности, предусмотренное настоящим пунктом, не применяется.</p>
  <p>7.11. Арендодатель вправе осуществлять оплату административных штрафов и иных платежей, связанных с нарушениями за
    период Сессии аренды Арендатора или в связи с использованием ТС Арендатором, в т.ч. как лицо, в отношении которого
    выносится постановление по делу об административном правонарушении, равно как лицо, в отношении которого предъявлены
    соответствующие требования об оплате. В целях настоящего Договора Арендатор наделяет Арендодателя полномочиями
    производить оплату административных штрафов и иных задолженностей, с ними связанных, налагаемых непосредственно на
    Арендатора в процессе пользования ТС по настоящему Договору. Соответствующие платежи, совершаемые Арендодателем, в
    том числе расходы на выполнение указанного поручения, подлежат возмещению Арендатором в порядке, предусмотренном
    Договором для возмещения убытков Арендодателя.</p>
  <p>Основанием для взыскания с Арендатора сумм в счет оплаты административных штрафов или иных платежей, связанных с
    нарушениями, допущенных Арендатором (в т.ч. в лице Поверенного) и выставленных Арендодателю либо собственникам ТС,
    является получение Арендодателем сведений об нарушениях в период Сессии аренды Арендатора или в связи с
    использованием ТС Арендатором (в т.ч. постановление по делу об административном правонарушении, данные из открытых
    источников, в т.ч. gibdd.ru, <a href="https://avtokod.mos.ru/">https://avtokod.mos.ru/</a>, сообщения Арендатора
    (Поверенного), третьих лиц, сведения фото- / видеофиксации, документальное подтверждение нарушения и проч.).</p>
  <p>Убытки Арендодателя в связи с оплатой или последующей оплатой административных штрафов и иных платежей, связанных с
    нарушениями, допущенными Арендатором, подлежат оплате Арендатором независимо от того, были фактически оплачены
    соответствующие административные штрафы либо иные платежи или нет. После оплаты данных сумм Арендодателю Арендатор
    освобождается от каких-либо претензий от Арендодателя или собственника ТС по поводу соответствующих административных
    штрафов и иных платежей.</p>
  <p>В случае, если административный штраф предусматривает возможность его оплаты в размере 50 % (ч. 1.3 ст. 32.2 КоАП
    РФ), Арендатор обязан оплатить Арендодателю сумму в счет оплаты данного штрафа в указанном размере, а также суммы
    администрирования - в течение 5 (пяти) календарных дней со дня получения соответствующего уведомления от
    Арендодателя. В случае неоплаты сумм, указанных в настоящем абзаце, в течение 5 (пяти) календарных дней, Арендатор
    обязан помимо суммы в счет оплаты штрафа (в размере, в котором он должен быть уплачен с учетом положений ч. 1.3 ст.
    32.2 КоАП РФ) дополнительно уплатить Арендодателю штраф в размере 50 % от полного размера административного штрафа.
    Штраф не распространяется на случаи, когда Арендатором является юридическое лицо.</p>
  <p>В случае, если административный штраф не предусматривает возможность его оплаты в размере 50 % (ч. 1.3 ст. 32.2
    КоАП РФ) либо если срок для оплаты административного штрафа в 50 % размере истек, Арендатор оплачивает сумму в счет
    оплаты данного штрафа в полном размере.</p>
  <p>В случае получения Арендодателем за период Сессии аренды Арендатора административного штрафа за нарушение,
    квалифицированное как повторное в соответствии с КоАП РФ, Арендатор обязан возместить расходы Арендодателя на оплату
    штрафа в полном объеме.</p>
  <p>В случае, если по сведениям Арендодателя оплата за перемещение и/или хранение не была произведена Арендатором в
    течение 60 (шестидесяти) календарных дней со дня перемещения ТС на специализированную стоянку в связи с действиями
    (бездействием) Арендатора, Арендодатель вправе самостоятельно осуществить оплату перемещения и/или хранения ТС на
    специализированной (штрафной) стоянке с последующим предъявлением соответствующего требования Арендатору (ст. 313 ГК
    РФ). Данное положение не является услугой и основано на требованиях Договора по оплате Арендатором стоимости
    перемещения и хранения ТС на специализированных (штрафных) стоянках, а также на необходимости поддержания деловой
    репутации каршеринга как услуги.</p>
  <p>Стороны согласовали, что обработка административных штрафов и иных платежей, направление соответствующих
    уведомлений осуществляется Арендодателем в порядке очередности. Направление уведомления об оплате сумм в счет оплаты
    штрафа с превышением сроков для их оплаты в 50 % размере не может являться основанием для предъявления каких-либо
    требований к Арендодателю.</p>
  <p>7.12. Стороны согласовали, что достаточными и безусловными основаниями для определения Арендатора как лица,
    допустившего нарушение требований законодательства, настоящего Договора, Договора Делимобиль и иных документов об
    использовании Сервиса и, соответственно, как лица, несущего ответственность и обязанного оплатить убытки, неустойку
    (штрафы, пени), является одно из следующих условий:</p>
  <p>А) наличие акта органа власти (Справка о ДТП (если применимо), Постановление по делу об административном
    правонарушении и иные подобные документы), в которых зафиксированы обстоятельства произошедшего и нарушение
    требований действующего законодательства, Договора, документов об использовании Сервиса в период Сессии аренды
    данного Арендатора (в т.ч. в лице Поверенного), и / или;</p>
  <p>Б) сведения о том, что последним лицом, использовавшим ТС до выявления нарушения, равно как до перемещения ТС на
    специализированную (штрафную) стоянку, являлся Арендатор (Поверенный);</p>
  <p>В) документальные подтверждения нарушений, полученные от третьих лиц, в т.ч. с использованием
    фото/видеофиксации;</p>
  <p>Г) сообщения Арендатора (в т.ч. в лице Поверенного).</p>
  <p>Внешние повреждения или иные недостатки ТС, сведения о которых не были направлены Арендатором Арендодателю при
    принятии ТС в аренду и которые были выявлены после окончании Сессии аренды Арендатора, считаются возникшими в период
    Сессии аренды Арендатора.</p>
  <p>Стороны согласовали достаточность для целей применения настоящего Договора сведений, полученных с использованием
    программного обеспечения Арендодателя, осуществляющего контроль за арендами Арендаторов.</p>
  <p>7.13. Все действия и указания, исходящие с Мобильного устройства Арендатора (Поверенного), считаются исходящими
    соответственно от Арендатора (Поверенного).</p>
  <p align="center"><strong>8. Срок действия Договора </strong></p>
  <p>8.1. Предварительный договор аренды ТС вступает в силу в момент начала бронирования ТС. Предварительный договор
    аренды ТС прекращается в момент прекращении бронирования (в т.ч. при отзыве оферты Арендодателем, отмене
    бронирования Арендодателем или Арендатором) или при заключении Арендатором Основного договора аренды ТС.</p>
  <p>8.2. Основной договор аренды ТС вступает в силу в момент заключения, как это определено настоящим Договором.
    Основной договор аренды ТС прекращается в момент завершения Сессии аренды Арендатором или Арендодателем.</p>
  <p>8.3. Арендодатель вправе отказаться от Предварительного и / или Основного договора аренды ТС:</p>
  <p>- в случае угона / хищения ТС;</p>
  <p>- в случае, если настоящий Договор заключен на неопределенный срок;</p>
  <p>- в иных случаях, предусмотренных законом.</p>
  <p>В случае, если Арендатором не является потребитель в смысле Закона РФ О &laquo;О защите прав потребителей&raquo;,
    Арендодатель вправе отказаться от Договора также:</p>
  <p>- при наличии оснований полагать, что Арендатор нарушил условия настоящего Договора, Договора Делимобиль и условий
    об использовании Сервиса;</p>
  <p>- в случае принятия Арендодателем решения о наличии риска дальнейшего использования ТС;</p>
  <p>- в случае, когда дальнейшее использование ТС является невозможным или может повлечь за собой убытки
    Арендодателя.</p>
  <p>В случае отказа Арендодателя уведомление о прекращении Аренды осуществляется не менее чем за 5 (пять) минут до
    предполагаемого прекращения Договора посредством Мобильного приложения, через Личный кабинет или по иным средствам
    связи, сведения о которым содержатся в Учетной записи Арендатора или его Поверенного.</p>
  <p align="center"><strong>9. Порядок разрешения споров</strong></p>
  <p>9.1. Все споры и разногласия разрешаются Сторонами путем переговоров. Срок направления ответа на претензию не может
    превышать 10 (десять) календарных дней, если иной срок не согласован Сторонами или императивно не установлен
    действующим законодательством. Все претензии со стороны Пользователя направляются в письменном виде на почтовый
    адрес Компании способом, подтверждающим факт получения адресатом соответствующего документа и позволяющим определить
    отправителя и его полномочия.</p>
  <p>9.2. Ответ на претензию Арендатора может быть направлен Арендодателем на электронную почту Арендатора, указанную им
    при регистрации в Сервисе.</p>
  <p>9.3. В случае, если Стороны не пришли к решению посредством переговоров, споры и разногласия передаются на
    разрешение:</p>
  <p>- для споров с Арендаторами-юридическими лицами &ndash; в Арбитражный суд г. Москвы;</p>
  <p>- для споров с Арендаторами-физическими лицами &ndash; в Гагаринский районный суд г. Москвы, Судебный участок
    Мирового судьи № 212 по г. Москве, за исключением случаев, когда действующим законодательством для отдельных
    категорий споров должна применяться специальная (альтернативная, исключительная и проч.) подсудность. Данное условие
    не исключает и не умаляет право Пользователя как потребителя в смысле Закона РФ &laquo;О защите прав потребителей&raquo;
    на выбор подсудности в случаях, когда такое право императивно закреплено законом.</p>
  <p>9.4. Стороны согласовали, что в случае представления интересов Арендатора третьим лицом полномочия такого
    представителя должны быть выражены в нотариально удостоверенной доверенности (ч. 2 ст. 163 ГК РФ), нотариальная
    копия или оригинал которой предоставляется Арендодателю.</p>
  <p align="center"><strong>10. Приложения</strong></p>
  <p>10.1. Приложения к Договору аренды ТС:</p>
  <p>- Акт приема-передачи транспортного средства;</p>
  <p>- Акт возврата транспортного средства;</p>
  <p>- Положение о территориальных ограничениях в использовании ТС;</p>
  <p>- Положение о штрафах;</p>
  <p>- Инструкция по определению нормального износа ТС.</p>
  <p><strong>Форма для электронного подписания:</strong></p>
  <p>Документ подписан Арендатором (Поверенным) электронной подписью в соответствии с Договором Делимобиль (<a
    href="https://delimobil.ru/terms/contract">https://delimobil.ru/terms/contract</a>) и Договором аренды ТС</p>
  <br/>
  <table style="width: 320.25pt; margin-left: -.25pt; border-collapse: collapse; border: none;">
    <tbody>
    <tr>
      <td
        style="width: 320.25pt; border: 1pt solid #0070c0; padding: 0cm 5.4pt 0cm 5.15pt; height: 72.7pt; vertical-align: top;">
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ФИО Арендатора: ${full_name}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">Логин Арендатора: ${login}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ID Арендатора: ${client_id}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ID аренды: ${rent_id}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">Электронная подпись: ${digital_signature}</span>
        </p>
      </td>
    </tr>
    </tbody>
  </table>
  <br/>
  <p align="right"><strong>Приложение № 1 (1) к Договору аренды ТС</strong><br/>(в редакции Приказа № ДД-30 от &laquo;06&raquo;
    декабря 2019 г., вступило в силу в 00:00 11.12.2019 г.)</p>
  <p align="center"><strong>Акт приема-передачи транспортного средства</strong></p>
  <p>{дата, время}</p>
  <p>Общество с ограниченной ответственностью &ldquo;Каршеринг Руссия&rdquo; в лице Генерального Директора {_______},
    действующего на основании Устава Общества, именуемое в дальнейшем &ldquo;Арендодатель&rdquo;, с одной стороны, и
    ${renter} именуемый(-ая) в дальнейшем &laquo;Арендатор&raquo;, с другой стороны, подписали настоящий Акт о
    нижеследующем:</p>
  <p>1. В соответствии с Договором Делимобиль (https://delimobil.ru/terms/contract), Договором аренды ТС, приложениям к
    ним, Положением о тарифах &ndash; в редакции на момент подписания настоящего Акта - Арендодатель передает, а
    Арендатор принимает следующее транспортное средство:</p>
  <ul type="square">
    <li>марка, модель: {________}</li>
    <li>регистрационный знак: {__________}</li>
    <li>идентификационный номер: {_______________}</li>
    <li>год выпуска: {________________}</li>
    <li>тип ТС: {___________________}</li>
    <li>шасси (рама) №: {_______________}</li>
    <li>кузов (коляска) №: {_________________}</li>
    <li>цвет: {________________}</li>
  </ul>
  <p>2. Если в разделе мобильного приложения "Состояние автомобиля" не указано иное, то транспортное средство передается
    в исправном состоянии, без повреждений и отвечает требованиям, предъявляемым к эксплуатируемым транспортным
    средствам, используемым для перевозки пассажиров, а также ожиданиям Арендатора. Неотъемлемым приложением настоящего
    Акта является (при наличии) информация, внесенная Арендатором в приложение в момент оценки состояния автомобиля.</p>
  <p><strong>Форма для электронного подписания:</strong></p>
  <p>Документ подписан Арендатором (Поверенным) электронной подписью в соответствии с Договором Делимобиль
    (https://delimobil.ru/terms/contract) и Договором аренды ТС</p>
  <br/>
  <table style="width: 320.25pt; margin-left: -.25pt; border-collapse: collapse; border: none;">
    <tbody>
    <tr>
      <td
        style="width: 320.25pt; border: 1pt solid #0070c0; padding: 0cm 5.4pt 0cm 5.15pt; height: 72.7pt; vertical-align: top;">
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ФИО Арендатора: ${full_name}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">Логин Арендатора: ${login}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ID Арендатора: ${client_id}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ID аренды: ${rent_id}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">Электронная подпись: ${digital_signature}</span>
        </p>
      </td>
    </tr>
    </tbody>
  </table>
  <br/>
  <p><strong>Форма утверждена</strong></p>
  <p align="right"><strong>Приложение № 1 (2) к Договору аренды ТС</strong><br/>(в редакции Приказа № ДД-30 от &laquo;06&raquo;
    декабря 2019 г., вступило в силу в 00:00 11.12.2019 г.)</p>
  <p align="center"><strong>Акт приема-передачи транспортного средства</strong></p>
  <p>{дата, время}</p>
  <p>Общество с ограниченной ответственностью &ldquo;Каршеринг Руссия&rdquo; в лице Генерального Директора
    {________________}, действующего на основании Устава Общества, именуемое в дальнейшем &ldquo;Арендодатель&rdquo;, с
    одной стороны, и ${renter} именуемый(-ая) в дальнейшем &laquo;Арендатор&raquo;, с другой стороны, подписали
    настоящий Акт о нижеследующем:</p>
  <p>1. Во исполнение обязанности Арендатора по возврату ТС в соответствии с Договором Делимобиль
    (https://delimobil.ru/terms/contract) и Договором аренды ТС Арендатор передает, а Арендодатель принимает
    транспортное средство:</p>
  <ul type="square">
    <li>марка, модель: {________}</li>
    <li>регистрационный знак: {__________}</li>
    <li>идентификационный номер: {_______________}</li>
    <li>год выпуска: {________________}</li>
    <li>тип ТС: {___________________}</li>
    <li>шасси (рама) №: {_______________}</li>
    <li>кузов (коляска) №: {_________________}</li>
    <li>цвет: {________________}</li>
  </ul>
  <p>2. Подписание настоящего Акта не лишает Арендодателя возможности ссылаться впоследствии на факты выявленных
    повреждений ТС, утрату ТС и оборудования и/или документов ТС и/или стандартного автомобильного набора, возникших в
    результате его эксплуатации Арендатором.</p>
  <p><strong>Форма для электронного подписания:</strong></p>
  <p>Документ подписан Арендатором (Поверенным) электронной подписью в соответствии с Договором Делимобиль
    (https://delimobil.ru/terms/contract) и Договором аренды ТС</p>
  <br/>
  <table style="width: 320.25pt; margin-left: -.25pt; border-collapse: collapse; border: none;">
    <tbody>
    <tr>
      <td
        style="width: 320.25pt; border: 1pt solid #0070c0; padding: 0cm 5.4pt 0cm 5.15pt; height: 72.7pt; vertical-align: top;">
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ФИО Арендатора: ${full_name}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">Логин Арендатора: ${login}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ID Арендатора: ${client_id}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ID аренды: ${rent_id}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">Электронная подпись: ${digital_signature}</span>
        </p>
      </td>
    </tr>
    </tbody>
  </table>
  <br/>
  <p><strong>Форма утверждена</strong></p>
  <br/>
  <p align="right"><strong>Приложение № 2 к Договору Аренды ТС</strong><br/>(в редакции Приказа № ДД-71 от &laquo;25&raquo;
    апреля 2022 г., размещено 26.04.2022 г.)</p>
  <p align="center"><strong>ПОЛОЖЕНИЕ О ТЕРРИТОРИАЛЬНЫХ ОГРАНИЧЕНИЯХ В ИСПОЛЬЗОВАНИИ ТС</strong></p>
  <p align="center"><strong>I. РАЗРЕШЕННЫЕ ЗОНЫ</strong></p>
  <p>Действующие Разрешенные зоны отображаются в Мобильном приложении и указываются на Сайте. В случае, если для
    какой-либо Разрешенной зоны, указанной на Сайте или в Мобильном приложении, настоящим разделом не установлены
    специальные условия использования ТС, применяются Общие условия использования ТС в Разрешенных зонах,
    предусмотренные п. I.I настоящего Приложения.</p>
  <p align="center"><strong>I.I. ОБЩИЕ УСЛОВИЯ ИСПОЛЬЗОВАНИЯ ТС В РАЗРЕШЕННЫХ ЗОНАХ</strong></p>
  <p>1. Движение ТС, а также оставление ТС в режиме &laquo;Ожидание&raquo; допускается в пределах соответствующей
    Разрешенной зоны, определенной на карте в Мобильном приложении зеленой пунктирной линией.</p>
  <p>2. Завершение Сессии аренды допускается в пределах соответствующей Разрешенной зоны, определенной на карте в
    Мобильном приложении в виде закрашенного полупрозрачного единообразного фона.</p>
  <p>3. Движение, оставление ТС (остановка, стоянка, в т.ч. в режиме &laquo;Ожидание&raquo; или при окончании Сессии
    аренды) допускается только с соблюдением требований, установленных законодательством, в т.ч. ПДД, Договором
    Делимобиль, Договором аренды ТС и приложениям к ним, на местах, где допускается остановка (для остановки) или
    стоянка (для стоянки) легковых ТС, бесплатная парковка (стоянка) без ограничений (в т.ч по категории ТС или по
    времени) в соответствии с ПДД. Оставление (остановка, стоянка) ТС в любом случае не допускается с нарушением ПДД, на
    подземных / крытых парковках, на тротуарах, на территории закрытой или специализированной парковки, на территории
    частной парковки, на территории, для доступа в которую необходимо разрешение равно как на территориях с ограниченным
    доступом (в т.ч. оснащенные ограничивающими свободный въезд/выезд устройствами), на местах, где остановка / стоянка
    (бесплатная остановка / стоянка) допускается временно (в определенные часы, дни), в местах, где по данным Компании
    отсутствует или затруднен доступ к системе ГЛОНАСС/GPS и(или) к сети Интернет, и(или) мобильной связи, на
    парковочных местах для инвалидов, для грузовых ТС, на территории иной платной парковки, в.ч. на платных парковках,
    функционирующих в рамках региональных проектов.</p>
  <p>4. В случае, если нормативными правовыми актами введены ограничения на использование транспортных средств в
    пределах определенных территорий (движение, остановка, стоянка и проч.), Пользователь не вправе использовать
    транспортные средства в нарушение указанных ограничений вне зависимости от обозначенных в Мобильном приложении
    границ Разрешенной зоны (зоны использования, зоны завершения Сессии аренды).</p>
  <p align="center"><strong>I.II. РАЗРЕШЕННАЯ ЗОНА &laquo;МОСКВА&raquo;</strong></p>
  <p>1. Движение, оставление ТС (остановка, стоянка, в т.ч. в режиме &laquo;Ожидание&raquo; или при окончании Сессии
    аренды) допускается только с соблюдением требований, установленных законодательством, в т.ч. ПДД, а также Договором
    Делимобиль, Договором аренды ТС и приложениями к ним, на местах, где допускается остановка (для остановки) или
    стоянка (для стоянки) легковых ТС, бесплатная парковка (стоянка) без ограничений (в т.ч. по категории ТС или по
    времени) в соответствии с ПДД, на платных парковках для легковых ТС, входящих в зону платной городской парковки,
    определенной Постановлением Правительства Москвы от 17.05.2013 г. № 289-ПП &laquo;Об организации платных городских
    парковок в городе Москве&raquo;. Оставление (остановка, стоянка) ТС в любом случае не допускается с нарушением
    правил ПДД, на подземных / крытых парковках (в т.ч. входящих в зону платной городской парковки), на тротуарах, на
    территории закрытой или специализированной парковки, на территории частной парковки, на территории, для доступа в
    которую необходимо разрешение, равно как на территориях с ограниченным доступом (в т.ч. оснащенные ограничивающими
    свободный въезд/выезд устройствами), на местах, где остановка / стоянка (бесплатная остановка / стоянка) допускается
    временно (в определенные часы, дни), в местах, где по данным Компании отсутствует или затруднен доступ к системе
    ГЛОНАСС/GPS и(или) к сети Интернет, и(или) мобильной связи, на парковочных местах для инвалидов, для грузовых ТС, на
    территории платной парковки, не входящей в зону платной городской парковки, определенной Постановлением
    Правительства Москвы от 17.05.2013 г. № 289-ПП &laquo;Об организации платных городских парковок в городе Москве&raquo;.</p>
  <p align="center"><strong>II. ПРАВИЛА РАЗМЕЩЕНИЯ ТС НА ТЕРРИТОРИИ АЭРОПОРТОВ</strong></p>
  <p>Оставление ТС на территории аэропортов является исключением из общих ограничений к местам оставления ТС и
    допускается исключительно в соответствии со схемой размещения на территориях парковочного пространства аэропортов,
    указанных в настоящих Правилах.</p>
  <p>При использовании ТС на территории аэропорта, равно как и при оставлении ТС на территории аэропорта (независимо от
    режима использования ТС и применяемого тарифа), Пользователь обязуется соблюдать требования действующего
    законодательства, Договора Делимобиль, Договора аренды ТС и приложения к ним, требования и условия, определенные
    соответствующим владельцем территории, на которой допускается стоянка ТС. В случае, если в пределах зоны парковки,
    обозначенной на схемах, размещенных в настоящем разделе, допускается размещение только определенных ТС (такси,
    спецтранспорт и проч.) либо установлены иные ограничения, Пользователь размещает ТС при соблюдении указанных
    ограничений.</p>
  <p>В случае, если доступ на территории аэропортов (в т.ч. к парковочному пространству) осуществляется посредством
    сличения государственных регистрационных знаков, Пользователь должен убедиться в их читаемости и соответствии
    установленным требованиям.</p>
  <p>В любом случае при размещении ТС на территории аэропортов Пользователь не вправе:</p>
  <p>- загромождать проезды и выезды с территории парковки;</p>
  <p>- оставлять на парковке ТС при наличии утечки ГСМ, производить заправку жидкостей, ремонт и помывку ТС;</p>
  <p>- пользоваться открытым огнем в качестве светового источника и для прогрева двигателя, разжигать костры;</p>
  <p>- в случае, если допуск к использованию парковки осуществляется посредством контрольно-расчетных карт (въездных /
    выездных билетов и проч.), передавать их третьим лицам, обменивать их, пользоваться услугами третьих лиц,
    предлагающих обмен контрольно-расчетных карт (въездных / выездных билетов и проч.);</p>
  <p>- распивать спиртные напитки, курить, заходить в служебные помещения владельцев парковки и отвлекать работников
    парковки от их служебных обязанностей.</p>
  <p>Последствия несоблюдения требований аэропорта, владельца паркинга, а также настоящих Правил в полном объеме ложатся
    на Пользователя. Нарушение настоящих Правил, а также требований и условий использования Паркинга, установленных
    владельцем Паркинга, влечет за собой применение мер ответственности, предусмотренных Договором и приложениями к
    нему. Наряду с предусмотренными штрафами (договорная ответственность Пользователя) Пользователь обязуется в полном
    объеме возместить Компании все убытки, которые последняя понесла в связи с нарушением Пользователем настоящих
    Правил, требований должностных лиц, органов и организаций в связи с использованием (в т.ч. оставлением ТС) на
    территории аэропортов, указанных в настоящих Правилах.</p>
  <p align="center"><strong>II.I. Правила размещения ТС на территории Международного аэропорта Шереметьево-2</strong>
  </p>
  <p align="center">(далее &ndash; Правила Шереметьево)</p>
  <img src="/contract/1.png" alt=""/>
  <p>1.1. Оставление ТС допускается на 4-х уровневой парковке, расположенной в терминале D Международного аэропорта
    &laquo;Шереметьево&raquo; (далее &ndash; &laquo;Паркинг&raquo;). Уровень, предназначенный для оставления арендуемых
    ТС &ndash; 2 уровень. Оставление ТС за пределами Паркинга, в том числе на территории привокзальной площади не
    допускается и влечет за собой наложение штрафных санкций, а также возмещение убытков.</p>
  <p>1.2. В целях идентификации машиномест, на которых могут размещаться ТС, соответствующие машиноместа могут быть
    обозначены. В случае наличия соответствующих обозначений Пользователь обязуется оставлять ТС в пределах
    соответствующих обозначений.</p>
  <p>1.3. В целях Правил Шереметьево применяются следующие определения:</p>
  <p>1.3.1. Въездной билет &ndash; бумажный билет одноразового использования, выдаваемый устройствами Комплексной
    автоматизированной парковочной системы (КАПС) всем посетителям при въезде на территорию Паркинга.</p>
  <p>1.3.2. Выездной билет &ndash; бумажный билет одноразового использования, дающий право выезда с Паркинга.</p>
  <p>1.4. Условия, правила и требования, установленные аэропортом, владельцем Паркинга, в т.ч. Правила пользования
    парковками (на http://www.avtoparkm.ru/klientam/pravila-ispolzovaniya.html), являются обязательными для Пользователя
    и подлежат самостоятельному изучению Пользователем. Правила пользования парковками, установленные владельцем
    Паркинга, применяются в части, не противоречащей настоящим Правилам.</p>
  <p align="center"><strong>2. Особенности размещения ТС на Паркинге</strong></p>
  <p>2.1. Въезд/выезд на территорию Комплекса, в т.ч. для оставления ТС на Паркинге осуществляется с использованием
    соответственно Въездных и Выездных билетов.</p>
  <p>2.2. Для въезда/выезда с Паркинга Пользователь обязан получить Въездной билет на общих с иными посетителями
    основаниях. Оставить арендуемое ТС на 2-м уровне Паркинга.</p>
  <p>2.3. Въездной билет не дает права въезда на территорию &laquo;Экспресс-стоянки&raquo;.</p>
  <p>2.4. При выезде с Паркинга Пользователь получает в административной части Паркинга по адресу: Московская область,
    г. Химки, Международное шоссе, вл.2, стр.2, первый этаж, административная часть (перед выездными шлагбаумами) у
    представителя владельца Паркинга Выездной билет, предъявив документы, а именно: водительское удостоверение, СТС на
    арендуемое ТС, экран мобильного устройства с Мобильным приложением.</p>
  <p>2.5. При покидании Паркинга на арендованном ТС Пользователь обязан сохранять полученный талон. На стойке выезда с
    территории Международного аэропорта Шереметьево Пользователь должен поместить Выездной талон в приемное устройство
    стойки выезда, дождаться поднятия шлагбаума и покинуть территорию Международного аэропорта Шереметьево.</p>
  <p>2.6. Пользователь обязан покинуть территорию Паркинга в срок, не позднее 15 (пятнадцати) минут с момента проезда
    транспортного средства, управляемого данным Пользователем через выездной шлагбаум многоуровневого Паркинга.</p>
  <p>2.7. В случае отсутствия свободных мест на местах размещения транспортных средств, Пользователь обязуется
    незамедлительно сообщить об этом Компании и следовать инструкциям Информационного центра. Оставление ТС с нарушением
    настоящих Правил, а также условий паркинга, установленным владельцем паркинга, не допускается.</p>
  <p align="center"><strong>II.II. Правила размещения ТС на территории Московского аэропорта Домодедово</strong></p>
  <p align="center">(далее &ndash; &laquo;Правила Домодедово&raquo;)</p>
  <p>Схема</p>
  <img src="/contract/2.png" alt=""/>
  <p align="center"><strong>1. Общие положения</strong></p>
  <p>1.1. Оставление ТС допускается в пределах территории парковки Московского аэропорта Домодедово, отмеченной на схеме
    2 (далее &ndash; &laquo;Паркинг&raquo;) без ограничения количества ТС. Оставление ТС за пределами Паркинга не
    допускается.</p>
  <p>1.2. В целях идентификации машиномест, на которых могут размещаться ТС, соответствующие машиноместа могут быть
    обозначены. В случае наличия соответствующих обозначений Пользователь обязуется оставлять ТС в пределах
    соответствующих обозначений.</p>
  <p>1.3. Доступ ТС на Паркинг осуществляется по технологии &laquo;Белый список&raquo;, то есть согласно перечню
    государственных регистрационных номерных знаков транспортных средств, внесенных в базу данных ООО &laquo;Домодедово
    Эссет Менеджмент&raquo; (ООО &laquo;ДЭМ&raquo;, Оператор парковки Домодедово) и имеющих право въезда/выезда на/с
    Паркинг(а), путем считывания государственного регистрационного номера.</p>
  <p>1.4. Условия, правила и требования, установленные ООО &laquo;ДЭМ&raquo;, в т.ч. Правила пользования парковками ООО
    &laquo;ДЭМ&raquo;, утвержденные Приказом от 28.05.2015 г. № 0109/ОВ-ДАТ/15 (http://www.dme.ru/transport), являются
    обязательными для Пользователя и подлежат самостоятельному изучению Пользователем.</p>
  <p align="center"><strong>2. Особенности размещения ТС на Паркинге</strong></p>
  <p>2.1. В случае отсутствия свободных мест на местах размещения транспортных средств, Пользователь обязуется
    незамедлительно сообщить об этом Компании и следовать инструкциям Информационного центра. Оставление ТС с нарушением
    настоящих Правил, а также условий паркинга, установленным владельцем паркинга, не допускается.</p>
  <p align="center"><strong>II.III. Правила размещения ТС на территории Международного аэропорта Внуково</strong></p>
  <p align="center">(далее &ndash; &laquo;Правила Внуково&raquo;)</p>
  <p>Схема 3</p>
  <img src="/contract/3.png" alt=""/>
  <p align="center"><strong>1. Общие положения</strong></p>
  <p>1.1. Оставление ТС допускается в пределах территории парковок Р2 и Р3 аэропорта Пулково г. Санкт-Петербург,
    отмеченных на схеме 4 (далее &ndash; &laquo;Паркинг&raquo;) с ограничением количества ТС &ndash; не более 30
    (десять) единиц. Оставление ТС за пределами Паркинга не допускается.</p>
  <p>1.2. В целях идентификации машиномест, на которых могут размещаться ТС, соответствующие машиноместа могут быть
    обозначены. В случае наличия соответствующих обозначений Пользователь обязуется оставлять ТС в пределах
    соответствующих обозначений.</p>
  <p>1.3. Доступ ТС на Паркинг осуществляется согласно перечню государственных регистрационных номерных знаков
    транспортных средств, внесенных в базу данных ООО &laquo;Воздушные Ворота Северной Столицы&raquo; (ООО &laquo;ВВСС&raquo;,
    Оператор парковки Пулково) и имеющих право въезда/выезда на/с Паркинг(а), путем считывания государственного
    регистрационного номера.</p>
  <p>1.4. Условия, правила и требования, установленные ООО &laquo;ВВСС&raquo;, в т.ч. Правила пользования парковочным
    комплексом, размещенные на территории аэропорта, в сети Интернет, в т.ч. на стр.
    https://www.pulkovoairport.ru/transport/car/parking/, являются обязательными для Пользователя и подлежат
    самостоятельному изучению Пользователем.</p>
  <p>1.5. Помимо правил, указанных в п. 1.4, не допускается:</p>
  <p>- движение ТС на Линии подъезда к ЦПТ, по пандусу задним ходом;<br/> - движение ТС на Линии подъезда к ЦПТ, по
    пандусу передним ходом против направления дорожного движения;<br/> - въезд ТС вслед за находящимся впереди ТС до
    момента закрытия въездного шлагбаума;<br/> - выезд ТС вслед за находящимся впереди транспортным средством до момента
    закрытия выездного шлагбаума;<br/> - перемещение ТС между тарифными зонами путем движения вне установленных схем
    проезда (Зона подъезда к ЦПТ и Транзитные зоны);<br/> - оставление ТС в месте, не предназначенном для стоянки и
    остановки;<br/> - ручное снятие, поднятие, сбивание, стрел въездных и выездных шлагбаумов;<br/> - самовольное
    перемещение сигнальных и преграждающих конструкций, размещенных на территории Парковочной зоны, с целью
    несанкционированного проезда вне установленных схем проезда.</p>
  <br/>
  <p align="center"><strong>2. Особенности размещения ТС на Паркинге</strong></p>
  <p>2.1.<span style="white-space: pre;"> </span>Доступ ТС на Паркинг осуществляется согласно перечню государственных
    регистрационных номерных знаков транспортных средств, внесенных в базу данных ООО &laquo;Автопарк&raquo; и имеющих
    право въезда/выезда на/с Паркинг(а), путем считывания государственного регистрационного номера.</p>
  <p>2.2.<span style="white-space: pre;"> </span>Для въезда на Паркинг Пользователя, подъехав к шлагбауму, должен
    остановиться, дождаться полного поднятия стрелы шлагбаума, убедиться, что проезд свободен и начать передвижение на
    Паркинг со скоростью не более 5 км/час.</p>
  <p>2.3.<span style="white-space: pre;"> </span>Перед выездом с Паркинга Пользователь должен остановиться у линии СТОП,
    дождаться полного поднятия стрелы шлагбаума, убедиться, что проезд свободен, после чего начать движение на выезд. В
    случае, если проезд не свободен, Пользователь обязан остановить ТС у линии СТОП до въезда заезжающего на парковку
    другого ТС. После завершения маневра заехавшего ТС Пользователь может продолжить движение на выезд.</p>
  <p>2.4.<span style="white-space: pre;"> </span>В случае отсутствия свободных мест на местах размещения транспортных
    средств, Пользователь обязуется незамедлительно сообщить об этом Компании и следовать инструкциям Информационного
    центра. Оставление ТС с нарушением настоящих Правил, а также условий паркинга, установленным владельцем паркинга, не
    допускается.</p>
  <p align="center"><strong>II.IV. Правила размещения ТС на территории аэропорта Пулково г. Санкт-Петербург</strong></p>
  <p align="center">(далее &ndash; &laquo;Правила Пулково&raquo;)</p>
  <p>Схема 4</p>
  <img src="/contract/4.1.png" alt=""/><img src="/contract/4.2.png" alt=""/>
  <p align="center"><strong>1. Общие положения</strong></p>
  <p>1.1. Оставление ТС допускается в пределах территории парковок Р2 и Р3 аэропорта Пулково г. Санкт-Петербург,
    отмеченных на схеме 4 (далее &ndash; &laquo;Паркинг&raquo;) с ограничением количества ТС &ndash; не более 30
    (десять) единиц. Оставление ТС за пределами Паркинга не допускается.</p>
  <p>1.2. В целях идентификации машиномест, на которых могут размещаться ТС, соответствующие машиноместа могут быть
    обозначены. В случае наличия соответствующих обозначений Пользователь обязуется оставлять ТС в пределах
    соответствующих обозначений.</p>
  <p>1.3. Доступ ТС на Паркинг осуществляется согласно перечню государственных регистрационных номерных знаков
    транспортных средств, внесенных в базу данных ООО &laquo;Воздушные Ворота Северной Столицы&raquo; (ООО &laquo;ВВСС&raquo;,
    Оператор парковки Пулково) и имеющих право въезда/выезда на/с Паркинг(а), путем считывания государственного
    регистрационного номера.</p>
  <p>1.4. Условия, правила и требования, установленные ООО &laquo;ВВСС&raquo;, в т.ч. Правила пользования парковочным
    комплексом, размещенные на территории аэропорта, в сети Интернет, в т.ч. на стр.
    https://www.pulkovoairport.ru/transport/car/parking/, являются обязательными для Пользователя и подлежат
    самостоятельному изучению Пользователем.</p>
  <p>1.<span style="white-space: pre;"> </span>Общие положения</p>
  <p>1.1.<span style="white-space: pre;"> </span>Оставление ТС допускается в пределах территории парковок Р2 и Р3
    аэропорта Пулково г. Санкт-Петербург, отмеченных на схеме 4 (далее &ndash; &laquo;Паркинг&raquo;) с ограничением
    количества ТС &ndash; не более 30 (десять) единиц. Оставление ТС за пределами Паркинга не допускается.</p>
  <p>1.2.<span style="white-space: pre;"> </span>В целях идентификации машиномест, на которых могут размещаться ТС,
    соответствующие машиноместа могут быть обозначены. В случае наличия соответствующих обозначений Пользователь
    обязуется оставлять ТС в пределах соответствующих обозначений.</p>
  <p>1.3.<span style="white-space: pre;"> </span>Доступ ТС на Паркинг осуществляется согласно перечню государственных
    регистрационных номерных знаков транспортных средств, внесенных в базу данных ООО &laquo;Воздушные Ворота Северной
    Столицы&raquo; (ООО &laquo;ВВСС&raquo;, Оператор парковки Пулково) и имеющих право въезда/выезда на/с Паркинг(а),
    путем считывания государственного регистрационного номера.</p>
  <p>1.4.<span style="white-space: pre;"> </span>Условия, правила и требования, установленные ООО &laquo;ВВСС&raquo;, в
    т.ч. Правила пользования парковочным комплексом, размещенные на территории аэропорта, в сети Интернет, в т.ч. на
    стр. https://www.pulkovoairport.ru/transport/car/parking/, являются обязательными для Пользователя и подлежат
    самостоятельному изучению Пользователем.</p>
  <p>2.<span style="white-space: pre;"> </span>Особенности размещения ТС на Паркинге</p>
  <p>2.1.<span style="white-space: pre;"> </span>В случае отсутствия свободных мест на местах размещения транспортных
    средств, Пользователь обязуется незамедлительно сообщить об этом Компании и следовать инструкциям Информационного
    центра. Оставление ТС с нарушением настоящих Правил, а также условий паркинга, установленным владельцем паркинга, не
    допускается.</p>
  <p>II.V. Правила размещения ТС на территории Международного аэропорта &laquo;Стригино&raquo; (г. Нижний Новгород)</p>
  <p>(далее &ndash; &laquo;Правила Стригино&raquo;)</p>
  <p>Схема 5</p>
  <img src="/contract/5.png" alt=""/>
  <p>1. Общие положения</p>
  <p>1.1. Оставление ТС допускается в пределах территории парковки Международного аэропорта &laquo;Стригино&raquo; в г.
    Нижний Новгород (Нижний Новгород, Аэропорт &laquo;Стригино), отмеченной на схеме 5 (далее &ndash; &laquo;Паркинг&raquo;)
    &ndash; парковка Р1 &ndash; с ограничением количества ТС &ndash; не более 5 (пять) единиц. Оставление ТС за
    пределами Паркинга не допускается.</p>
  <p>1.2. В целях идентификации машиномест, на которых могут размещаться ТС, соответствующие машиноместа могут быть
    обозначены. В случае наличия соответствующих обозначений Пользователь обязуется оставлять ТС в пределах
    соответствующих обозначений.</p>
  <p>1.3. Доступ ТС на Паркинг осуществляется путем автоматической идентификации государственного регистрационного
    номера транспортного средства. Контроль въезда (выезда) автотранспорта на парковку осуществляется на пункте
    пропуска, установленном на парковке.</p>
  <p>Использование разового пропуска допускается только по согласованию с Компанией и при условии выезда Пользователя на
    одном и том же ТС с территории Р1 не позднее 15 (пятнадцать) минут с момента въезда. Все расходы, связанные с
    использованием разового пропуска Пользователем, возлагаются на Пользователя.</p>
  <p>1.4. Условия, правила и требования, установленные АО &laquo;Международный аэропорт Нижний Новгород&raquo; (АО
    &laquo;МАНН&raquo;), в т.ч. размещенные на территории аэропорта, в сети Интернет на стр. http://goj.aero, являются
    обязательными для Пользователя и подлежат самостоятельному изучению Пользователем.</p>
  <p>2. Особенности размещения ТС на Паркинге</p>
  <p>2.1. В случае отсутствия свободных мест на местах размещения транспортных средств, Пользователь обязуется
    незамедлительно сообщить об этом Компании и следовать инструкциям Информационного центра. Оставление ТС с нарушением
    настоящих Правил, а также условий паркинга, установленным владельцем паркинга, не допускается.</p>
  <p>II.VI. Правила размещения ТС на территории Международного аэропорта Курумоч (г. Самара) (далее &ndash; &laquo;Правила
    Самара&raquo;)</p>
  <p>Схема 6</p>
  <img src="/contract/6.png" alt=""/>
  <p>1. Общие положения</p>
  <p>1.1. Оставление ТС допускается в пределах территории парковки Международного аэропорта Курумоч в г. Самара,
    отмеченной на схеме 6 (далее &ndash; &laquo;Паркинг&raquo;) &ndash; парковка Р1 &ndash; с ограничением количества ТС
    &ndash; не более 10 (десяти) единиц. Оставление ТС за пределами Паркинга не допускается.</p>
  <p>1.2. В целях идентификации машиномест, на которых могут размещаться ТС, соответствующие машиноместа могут быть
    обозначены. В случае наличия соответствующих обозначений Пользователь обязуется оставлять ТС в пределах
    соответствующих обозначений.</p>
  <p>1.3. Доступ ТС на Паркинг осуществляется посредством автоматической идентификации государственного регистрационного
    номера транспортного средства. Контроль въезда (выезда) автотранспорта на парковку осуществляется на пункте
    пропуска, установленном на парковке. Использование разового пропуска допускается только по согласованию с
    Арендодателем и при условии выезда Пользователя на одном и том же ТС с территории Р1 не позднее 15 (пятнадцать)
    минут с момента въезда. Все расходы, связанные с использованием разового пропуска Пользователем, возлагаются на
    Пользователя.</p>
  <p>1.4. Условия, правила и требования, установленные АО &laquo;Международный аэропорт Курумоч&raquo; (АО &laquo;МАК&raquo;),
    размещенные на территории аэропорта, в сети Интернет, в т.ч. на стр. http://www.kuf.aero/ru/avtoparkovka являются
    обязательными для Пользователя и подлежат самостоятельному изучению Пользователем.&nbsp;</p>
  <p>2. Особенности размещения ТС на Паркинге</p>
  <p>2.1. В случае отсутствия свободных мест на местах размещения транспортных средств, Пользователь обязуется
    незамедлительно сообщить об этом Компании и следовать инструкциям Информационного центра. Оставление ТС с нарушением
    настоящих Правил, а также условий паркинга, установленным владельцем паркинга, не допускается.</p>
  <p>II.VII. Правила размещения ТС на территории Международного аэропорта Кольцово (г. Екатеринбург) (далее &ndash;
    &laquo;Правила Екатеринбург&raquo;)</p>
  <p>Схема 7</p>
  <img src="/contract/7.png" alt=""/>
  <p>1.<span style="white-space: pre;"> </span>Общие положения</p>
  <p>1.1.<span style="white-space: pre;"> </span>Оставление ТС допускается в пределах территории парковки Международного
    аэропорта Кольцово в г. Екатеринбург, отмеченной на схеме 7 (далее &ndash; &laquo;Паркинг&raquo;) &ndash; парковка
    Р1 &ndash; с ограничением количества ТС &ndash; не более 10 (десяти) единиц. Оставление ТС за пределами Паркинга не
    допускается.</p>
  <p>1.2.<span style="white-space: pre;"> </span>В целях идентификации машиномест, на которых могут размещаться ТС,
    соответствующие машиноместа могут быть обозначены. В случае наличия соответствующих обозначений Пользователь
    обязуется оставлять ТС в пределах соответствующих обозначений.</p>
  <p>1.3.<span style="white-space: pre;"> </span>Доступ ТС на Паркинг осуществляется в автоматическом режиме (путем
    автоматической идентификации государственного регистрационного знака транспортного средства). Контроль въезда
    (выезда) автотранспорта на парковку осуществляется на пункте пропуска, установленном на парковке. В отдельных
    случаях при возникновении сбоев в работе оборудования допускается обращение к диспетчеру парковки (связь с
    диспетчером парковки осуществляется при нажатии на терминале кнопки (i)).</p>
  <p>1.4.<span style="white-space: pre;"> </span>Условия, правила и требования, установленные ПАО &laquo;Аэропорт
    Кольцово&raquo;, размещенные на территории аэропорта, в сети Интернет, в т.ч. на стр. www.svx.aero/ru/parkingi,
    являются обязательными для Пользователя и подлежат самостоятельному изучению Пользователем.&nbsp;</p>
  <p>2. Особенности размещения ТС на Паркинге</p>
  <p>2.1.<span style="white-space: pre;"> </span>В случае отсутствия свободных мест на местах размещения транспортных
    средств, Пользователь обязуется незамедлительно сообщить об этом Компании и следовать инструкциям Информационного
    центра. Оставление ТС с нарушением настоящих Правил, а также условий паркинга, установленным владельцем паркинга, не
    допускается.</p>
  <p>II.VIII. Правила размещения ТС на территории Международного аэропорта Толмачево (г. Новосибирск) (далее &ndash;
    &laquo;Правила Новосибирск&raquo;)</p>
  <p>Схема 8</p>
  <img src="/contract/8.png" alt=""/>
  <p>1. Общие положения</p>
  <p>1.1. Оставление ТС допускается в пределах территории парковки Международного аэропорта Толмачево в г. Новосибирск,
    отмеченной на схеме 8 (далее &ndash; &laquo;Паркинг&raquo;). Оставление ТС за пределами Паркинга не допускается.</p>
  <p>1.2. В целях идентификации машиномест, на которых могут размещаться ТС, соответствующие машиноместа могут быть
    обозначены. В случае наличия соответствующих обозначений Пользователь обязуется оставлять ТС в пределах
    соответствующих обозначений.</p>
  <p>1.3. Доступ ТС на Паркинг осуществляется с использованием автоматизированной системы с обязательным использованием
    парковочной карты, находящейся в арендуемом ТС. Контроль въезда (выезда) автотранспорта на парковку осуществляется
    на пункте пропуска, установленном на парковке. После использования парковочной карты она должна быть оставлена в
    ТС.</p>
  <p>1.4. Условия, правила и требования к пользованию парковкой, размещенные на территории аэропорта, в сети Интернет, в
    т.ч. на стр. https://tolmachevo.ru/transport/parking/tariff/, являются обязательными для Пользователя и подлежат
    самостоятельному изучению Пользователем.</p>
  <p>2. Особенности размещения ТС на Паркинге</p>
  <p>2.1. В случае отсутствия свободных мест на местах размещения транспортных средств, Пользователь обязуется
    незамедлительно сообщить об этом Компании и следовать инструкциям Информационного центра. Оставление ТС с нарушением
    настоящих Правил, а также условий паркинга, установленным владельцем паркинга, не допускается.</p>
  <p>II.IX. Правила размещения ТС на территории Международного аэропорта &laquo;Казань&raquo; (г. Казань) (далее &ndash;
    &laquo;Правила Казань&raquo;)</p>
  <p>Схема 9</p>
  <img src="/contract/9.png" alt=""/>
  <p>1. Общие положения&nbsp;</p>
  <p>1.1. Оставление ТС допускается в пределах территории парковки Международного аэропорта &laquo;Казань&raquo; в г.
    Казань, отмеченной на схеме 9 (далее &ndash; &laquo;Паркинг&raquo;) &ndash; привокзальная площадь Терминала 1А.
    Оставление ТС за пределами Паркинга не допускается.</p>
  <p>1.2. В целях идентификации машиномест, на которых могут размещаться ТС, соответствующие машиноместа могут быть
    обозначены. В случае наличия соответствующих обозначений Пользователь обязуется оставлять ТС в пределах
    соответствующих обозначений.</p>
  <p>1.3. Доступ ТС на Паркинг осуществляется в автоматическом режиме (путем автоматической идентификации
    государственного регистрационного знака транспортного средства). Контроль въезда (выезда) автотранспорта на парковку
    осуществляется на пункте пропуска, установленном на парковке.</p>
  <p>1.4. Условия, правила и требования к пользованию парковкой, размещенные на территории аэропорта, в сети Интернет, в
    т.ч. на стр. http://kazan.aero/for-passengers-and-guests/how-to-get/parking, являются обязательными для Пользователя
    и подлежат самостоятельному изучению Пользователем.</p>
  <p>2. Особенности размещения ТС на Паркинге</p>
  <p>2.1. В случае отсутствия свободных мест на местах размещения транспортных средств, Пользователь обязуется
    незамедлительно сообщить об этом Арендодателю и следовать инструкциям Информационного центра. Оставление ТС с
    нарушением настоящих Правил, а также условий паркинга, установленным владельцем паркинга, не допускается.</p>
  <p>2.2. Последствия несоблюдения требований аэропорта, владельца паркинга, а также настоящих Правил в полном объеме
    ложатся на Пользователя. Нарушение настоящих Правил влечет за собой применение мер ответственности, предусмотренных
    Договором и приложениями к нему. Наряду с предусмотренными штрафами (договорная ответственность Пользователя)
    Пользователь обязуется в полном объеме возместить Арендодателю все убытки, которые Арендодатель понес в связи с
    нарушением Пользователем (Поверенным) настоящих Правил, требований должностных лиц, органов и организаций в связи с
    использованием (в т.ч. оставлением ТС) на территории аэропортов, указанных в настоящих Правилах.</p>
  <p>II.X. Правила размещения ТС на территории Международного аэропорта &laquo;Платов&raquo; (г. Ростов-на-Дону) (далее
    &ndash; &laquo;Правила Ростов-на-Дону&raquo;)</p>
  <p>(действует с 01.07.2021)</p>
  <p>Схема 10</p>
  <img src="/contract/10.png" alt=""/>
  <p>1. Общие положения&nbsp;</p>
  <p>Оставление ТС допускается в пределах территории парковки Международного аэропорта &laquo;Платов&raquo; в г.
    Ростов-на-Дону, отмеченной на схеме 11 (далее &ndash; &laquo;Паркинг&raquo;) &ndash; Краткосрочная парковка Р1.
    Оставление ТС за пределами Паркинга не допускается.</p>
  <p>В целях идентификации машиномест, на которых могут размещаться ТС, соответствующие машиноместа могут быть
    обозначены. В случае наличия соответствующих обозначений Пользователь обязуется оставлять ТС в пределах
    соответствующих обозначений.</p>
  <p>Доступ ТС на Паркинг осуществляется в автоматическом режиме (путем автоматической идентификации государственного
    регистрационного знака транспортного средства). Контроль въезда (выезда) автотранспорта на парковку осуществляется
    на пункте пропуска, установленном на парковке.</p>
  <p>Условия, правила и требования к пользованию парковкой, размещенные на территории аэропорта, в сети Интернет, в т.ч.
    на стр. http://rov.aero/parking/, являются обязательными для Пользователя и подлежат самостоятельному изучению
    Пользователем.</p>
  <p>2. Особенности размещения ТС на Паркинге</p>
  <p>В случае отсутствия свободных мест на местах размещения транспортных средств, Пользователь обязуется
    незамедлительно сообщить об этом Арендодателю и следовать инструкциям Информационного центра. Оставление ТС с
    нарушением настоящих Правил, а также условий паркинга, установленным владельцем паркинга, не допускается.</p>
  <p>Последствия несоблюдения требований аэропорта, владельца паркинга, а также настоящих Правил в полном объеме ложатся
    на Пользователя. Нарушение настоящих Правил влечет за собой применение мер ответственности, предусмотренных
    Договором и приложениями к нему. Наряду с предусмотренными штрафами (договорная ответственность Пользователя)
    Пользователь обязуется в полном объеме возместить Арендодателю все убытки, которые Арендодатель понес в связи с
    нарушением Пользователем (Поверенным) настоящих Правил, требований должностных лиц, органов и организаций в связи с
    использованием (в т.ч. оставлением ТС) на территории аэропортов, указанных в настоящих Правилах.</p>
  <p><strong>Форма для электронного подписания:</strong></p>
  <p>Документ подписан Арендатором (Поверенным) электронной подписью в соответствии с Договором Делимобиль
    (https://delimobil.ru/terms/contract) и Договором аренды ТС</p>
  <br/>
  <table style="width: 320.25pt; margin-left: -.25pt; border-collapse: collapse; border: none;">
    <tbody>
    <tr>
      <td
        style="width: 320.25pt; border: 1pt solid #0070c0; padding: 0cm 5.4pt 0cm 5.15pt; height: 72.7pt; vertical-align: top;">
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ФИО Арендатора: ${full_name}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">Логин Арендатора: ${login}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ID Арендатора: ${client_id}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ID аренды: ${rent_id}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">Электронная подпись: ${digital_signature}</span>
        </p>
      </td>
    </tr>
    </tbody>
  </table>
  <br/>
  <p>Приложение № 3 к Договору Аренды ТС</p>
  <p>(в редакции Приказа № ДД-71 от &laquo;25&raquo; апреля 2022 г., размещено 26.04.2022 г.)</p>
  <p>ПОЛОЖЕНИЕ О ШТРАФАХ</p>
  <p>В случае неисполнения или ненадлежащего исполнения Договора Делимобиль, Договора аренды ТС и приложений к ним,
    Пользователь уплачивает следующие штрафы / пени. Штрафы / пени назначаются за каждый случай нарушения. НДС не
    облагается.</p>
  <p>ТАБЛИЦА ШТРАФОВ / ПЕНИ ЗА ОБЩИЕ СЛУЧАИ НАРУШЕНИЯ ДОГОВОРА</p>
  <table style="width: 7.6e+2pt; margin-left: 6.85pt; border-collapse: collapse; border: none;">
    <tbody>
    <tr>
      <td style="width: 286pt; border: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;" colspan="2">
        <p style="margin: 0cm; text-align: center;"><strong>Нарушение</strong></p>
      </td>
      <td
        style="width: 212.6pt; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: center;"><strong>Размер штрафа / пени</strong></p>
      </td>
      <td
        style="width: 263.8pt; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: center;"><strong>Примечание</strong></p>
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">1</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Самовольное нанесение наклеек, либо&nbsp;</p>
        <p style="margin: 0cm; text-align: justify;">удаление товарного знака / знака обслуживания Компании
          или&nbsp;</p>
        <p style="margin: 0cm; text-align: justify;">иное повреждение внешнего вида ТС</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">5 000 (пять тысяч) рублей</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">2</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Самовольное отключение либо повреждение в&nbsp;</p>
        <p style="margin: 0cm; text-align: justify;">ТС трекера (устройство&nbsp;GPS/ГЛОНАСС)</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">150 000 (сто пятьдесят тысяч) рублей</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">3</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Невыполнение требований, указанных в п. 4.2.9-4.2.13 Договора
          аренды ТС</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">10 000 (десять тысяч) рублей</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">4</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Нарушение сроков предоставления Компании документов, указанных в п.
          4.2.10 &ndash; 4.2.13 Договора аренды ТС</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">1 000 (одна тысяча) рублей за один день просрочки,</p>
        <p style="margin: 0cm; text-align: justify;">3 000 (три тысячи) рублей за два дня просрочки,</p>
        <p style="margin: 0cm; text-align: justify;">6 000 (шесть тысяч) рублей за три дня просрочки,</p>
        <p style="margin: 0cm; text-align: justify;">10&nbsp;000 (десять тысяч) рублей за четыре дня просрочки,</p>
        <p style="margin: 0cm; text-align: justify;">15&nbsp;000 (пятнадцать тысяч) за пять и более дней просрочки</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">5</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Нахождение Пользователя (его Поверенного) за рулем (в т.ч.
          управление арендованным ТС) при отсутствии права на управление ТС, недееспособности или при наличии
          медицинских противопоказаний либо в состоянии алкогольного, наркотического, токсического или иного
          опьянения</p>
        <p style="margin: 0cm; text-align: justify;">Отказ от прохождения медицинского освидетельствования на состояние
          опьянения, а равно нарушение требования ПДД о запрещении водителю употреблять алкогольные напитки,
          наркотические или психотропные вещества после ДТП, к которому он причастен, либо после того, как транспортное
          средство было оставлено по требованию сотрудника полиции, до проведения уполномоченным должностным лицом
          освидетельствования в целях установления состояния опьянения или до принятия уполномоченным должностным лицом
          решения об освобождении от проведения от такого освидетельствования</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">110 000 (сто десять тысяч) рублей</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; height: 35.45pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">6</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 35.45pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Передача (допущение) управления ТС лицу, находящемуся в состоянии
          алкогольного, наркотического, токсического или иного опьянения либо отказавшемуся от прохождения медицинского
          освидетельствования на состояние опьянения, а равно передача (допущение) управления ТС лицу, не имевшему права
          на управление ТС, в том числе несовершеннолетнему, недееспособному, имеющего медицинские противопоказания</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 35.45pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">220&nbsp;000 (двести двадцать тысяч) рублей</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 35.45pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; height: 35.45pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">7</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 35.45pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Передача (допущение) управления ТС третьему лицу.</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 35.45pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">55&nbsp;000 (пятьдесят пять тысяч) рублей</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 35.45pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; height: 35.45pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">8</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 35.45pt; vertical-align: top;">
        <p style="text-align: justify; line-height: 13.7pt; background: transparent; margin: 18.0pt 0cm 18.0pt 0cm;">
          Опасное вождение (в т.ч. в соответствии с определением, данным ПДД), дрифт (применение управляемых заносов);
          вождение, которое может повредить транспортному средству, третьим лицам; <span style="color: black;">использование ТС в состязаниях/соревнованиях (в том числе неофициальных), пари, конкурсах, испытаниях любого вида.</span>
        </p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 35.45pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">А) при отсутствии вреда Арендодателю или третьим лицам &ndash; 15&nbsp;000
          (пятнадцать тысяч) рублей.</p>
        <p style="margin: 0cm; text-align: justify;">Б) неоднократно в течение использования Сервиса либо при причинении
          вреда Арендодателю или третьим лицам - 110&nbsp;000 (сто десять тысяч) рублей</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 35.45pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; height: 35.45pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">9</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 35.45pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Движение на ТС со скоростью, превышающей 150 (сто пятьдесят)
          км/ч</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 35.45pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">20&nbsp;000 (двадцать тысяч) рублей</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 35.45pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">10</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Оставление ТС (независимо от режима использования ТС) с нарушением
          ограничений в части мест оставления ТС, установленных Договором аренды ТС, Положением о территориальных
          ограничениях в использовании ТС и действующим законодательством, если Договором и приложениями к нему не
          предусмотрены отдельные меры ответственности. </p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">в случае нарушения в пределах зоны, в которой допускается
          завершение Сессии аренды &ndash; 2&nbsp;000 (две тысячи) рублей;</p>
        <p style="margin: 0cm; text-align: justify;">в случае нарушения за пределами зоны, в которой допускается
          завершение Сессии аренды &ndash; 15&nbsp;000 (пятнадцать тысяч) рублей</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">10.1</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Выезд за пределы Разрешенной зоны</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">30&nbsp;000 (тридцать тысяч) рублей</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; height: 9pt; vertical-align: top;"
        rowspan="2">
        <p style="margin: 0cm; text-align: justify;">11</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 9pt; vertical-align: top;"
        rowspan="2">
        <p style="margin: 0cm; text-align: justify;">Оставление ТС (независимо от режима использования ТС) на газоне или
          на иной территории, занятой зелеными насаждениями, а равно с нарушением правил благоустройства, а также
          требований природоохранного законодательства, в т.ч. в части защиты особо охраняемых природных территорий.</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 9pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">4&nbsp;000 (четыре тысячи) рублей</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 9pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">Применяется вне зависимости от исполнения Арендатором требования
          Арендодателя об устранении нарушения или содействии Арендодателю</p>
      </td>
    </tr>
    <tr>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 289.35pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">100&nbsp;000 (сто тысяч) рублей за каждый выявленный эпизод.</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 289.35pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">Указанный штраф не применяется в случае, если Арендатор
          своевременно исполнил указания Арендодателя о необходимости устранить нарушение (в т.ч. по требованию
          Арендодателя переместил ТС в разрешенное место стоянки), <span style="color: black;">обеспечил свое участие в оформлении протокола об административном правонарушении/постановления по делу об административном правонарушении (в случае необходимости его составления), подтвердил факт допущения нарушения в период использования ТС Арендодателя, принял участие во всех процедурах обжалования принятого органом власти постановления, выполнил иные требования Арендодателя, связанные с обжалованием соответствующего постановления органа власти.</span>
        </p>
        <p style="margin: 0cm; text-align: justify;">В случае применения штраф суммируется со штрафом, применяемом вне
          зависимости от устранения Арендатором допущенного нарушения. В случае применения штраф суммируется со штрафом,
          применяемым вне зависимости от исполнения Арендатором требования Арендодателя об устранении нарушения или
          содействии Арендодателю.</p>
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; height: 12.2pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">11.1</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 12.2pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Оставление ТС (независимо от режима использования ТС) с нарушением
          требований действующего законодательства к остановке и / или стоянке транспортных средств.</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 12.2pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">4&nbsp;000 (четыре тысячи) рублей</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 12.2pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; height: 77.55pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">12</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 77.55pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Оставление ТС (независимо от режима использования ТС) с нарушением
          требований Правил размещения ТС на территории аэропортов (Положение о территориальных ограничениях в
          использовании ТС), а также условий, правил и требований, установленных соответствующими парковками (местами
          оставления ТС)</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 77.55pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">10&nbsp;000 (десять тысяч) рублей</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 77.55pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; height: 43.3pt; vertical-align: top;"
        rowspan="15">
        <p style="margin: 0cm; text-align: justify;">13</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 43.3pt; vertical-align: top;"
        rowspan="15">
        <p style="margin: 0cm; text-align: justify;">Эвакуация (перемещение) ТС на специализированную (штрафную) стоянку
          вследствие действий (бездействия) Пользователя</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 43.3pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">При перемещении ТС на специализированную (штрафную) стоянку в
          пределах:</p>
      </td>
      <td
        style="width: 262.2pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 43.3pt; vertical-align: top;"
        rowspan="15">
        <p style="text-align: justify; line-height: normal; background: transparent; margin: 18.0pt 0cm 18.0pt 0cm;">
          <span style="color: black;">В случае, если Арендатор в установленные Арендодателем сроки получает разрешение на возврат ТС со специализированной (штрафной) стоянки, оплачивает стоимость услуг по перемещению и по содержанию ТС на специализированной (штрафной) стоянке (с обязательным предоставлением Арендодателю документов, подтверждающих оплату данных платежей), возмещает Арендодателю причиненные убытки - он освобождается от уплаты договорного штрафа за нарушение требований к месту оставления ТС.</span>
        </p>
      </td>
      <td style="border: none; padding: 0cm 0cm 0cm 0cm;">
      </td>
    </tr>
    <tr>
      <td
        style="width: 4cm; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 12.75pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: center;">Территория</p>
      </td>
      <td
        style="width: 99.2pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 12.75pt; vertical-align: top;">
        <p style="line-height: normal; text-align: center; margin: 0cm;">Штраф, руб.</p>
      </td>
      <td style="border: none; padding: 0cm 0cm 0cm 0cm;">
      </td>
    </tr>
    <tr>
      <td
        style="width: 4cm; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 34.9pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">г. Москва</p>
      </td>
      <td
        style="width: 99.2pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 34.9pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">9000 (девять тысяч)</p>
      </td>
      <td style="border: none; padding: 0cm 0cm 0cm 0cm;">
      </td>
    </tr>
    <tr>
      <td
        style="width: 4cm; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 28.25pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Московская область&nbsp;</p>
      </td>
      <td
        style="width: 99.2pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 28.25pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">9&nbsp;000 (девять тысяч)</p>
      </td>
      <td style="border: none; padding: 0cm 0cm 0cm 0cm;">
      </td>
    </tr>
    <tr>
      <td
        style="width: 4cm; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 12.55pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">г. Санкт-Петербург</p>
      </td>
      <td
        style="width: 99.2pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 12.55pt; vertical-align: top;">
        <p style="line-height: normal; margin: 0cm;">7500 (семь тысяч пятьсот)</p>
      </td>
      <td style="border: none; padding: 0cm 0cm 0cm 0cm;">
      </td>
    </tr>
    <tr>
      <td
        style="width: 4cm; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 15.75pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Ленинградская область</p>
      </td>
      <td
        style="width: 99.2pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 15.75pt; vertical-align: top;">
        <p style="line-height: normal; margin: 0cm;">7500 (семь тысяч пятьсот)</p>
      </td>
      <td style="border: none; padding: 0cm 0cm 0cm 0cm;">
      </td>
    </tr>
    <tr>
      <td
        style="width: 4cm; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 14.25pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Разрешенная зона &laquo;Самара&raquo;</p>
      </td>
      <td
        style="width: 99.2pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 14.25pt; vertical-align: top;">
        <p style="line-height: normal; margin: 0cm;">5500 (пять тысяч пятьсот)</p>
      </td>
      <td style="border: none; padding: 0cm 0cm 0cm 0cm;">
      </td>
    </tr>
    <tr>
      <td
        style="width: 4cm; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 12.55pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Разрешенная зона &laquo;Екатеринбург&raquo;</p>
      </td>
      <td
        style="width: 99.2pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 12.55pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">5500 (пять тысяч пятьсот)</p>
      </td>
      <td style="border: none; padding: 0cm 0cm 0cm 0cm;">
      </td>
    </tr>
    <tr>
      <td
        style="width: 4cm; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 22.65pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Разрешенная зона &laquo;Новосибирск&raquo;</p>
      </td>
      <td
        style="width: 99.2pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 22.65pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">5500 (пять тысяч пятьсот)</p>
      </td>
      <td style="border: none; padding: 0cm 0cm 0cm 0cm;">
      </td>
    </tr>
    <tr>
      <td
        style="width: 4cm; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 27pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Разрешенная зона &laquo;Нижний Новгород&raquo;</p>
      </td>
      <td
        style="width: 99.2pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 27pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">5500 (пять тысяч пятьсот)</p>
      </td>
      <td style="border: none; padding: 0cm 0cm 0cm 0cm;">
      </td>
    </tr>
    <tr>
      <td
        style="width: 4cm; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 22.85pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Разрешенная зона &laquo;Тула&raquo;</p>
      </td>
      <td
        style="width: 99.2pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 22.85pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">6500 (шесть тысяч пятьсот)</p>
      </td>
      <td style="border: none; padding: 0cm 0cm 0cm 0cm;">
      </td>
    </tr>
    <tr>
      <td
        style="width: 4cm; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 12pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Разрешенная зона &laquo;Ростов-на-Дону&raquo;</p>
      </td>
      <td
        style="width: 99.2pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 12pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">6500 (шесть тысяч пятьсот)</p>
      </td>
      <td style="border: none; padding: 0cm 0cm 0cm 0cm;">
      </td>
    </tr>
    <tr>
      <td
        style="width: 4cm; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 12.75pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Разрешенная зона &laquo;Казань&raquo;</p>
      </td>
      <td
        style="width: 99.2pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 12.75pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">9000 (девять тысяч)</p>
      </td>
      <td style="border: none; padding: 0cm 0cm 0cm 0cm;">
      </td>
    </tr>
    <tr>
      <td
        style="width: 4cm; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 27.85pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">В других регионах и разрешенных зонах</p>
      </td>
      <td
        style="width: 99.2pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 27.85pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">11&nbsp;000 (одиннадцать тысяч) рублей</p>
      </td>
      <td style="border: none; border-bottom: solid windowtext 1.0pt;">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; height: 51pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">14</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 51pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Эвакуация (перемещение) ТС на специализированную (штрафную) стоянку
          вследствие нахождения Пользователя (его Поверенного) за рулем (в т.ч. управления арендованным ТС) при
          отсутствии права на управление ТС, недееспособности или при наличии медицинских противопоказаний либо в
          состоянии алкогольного, наркотического, токсического или иного опьянения, равно как при отказе от прохождения
          медицинского освидетельствования на состояние опьянения; при передаче (допущении) управления ТС лицу,
          находящемуся в состоянии алкогольного, наркотического, токсического или иного опьянения либо отказавшемуся от
          прохождения медицинского освидетельствования на состояние опьянения, а равно при передаче (допущении)
          управления ТС лицу, не имевшему права на управление ТС, в том числе несовершеннолетнему, недееспособному,
          имеющему медицинские противопоказания</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 51pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">55&nbsp;000 (пятьдесят пять тысяч) рублей</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 51pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; height: 24.35pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">15</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 24.35pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Оставление ТС в ситуации потенциально возможного причинения ТС
          повреждения, угона, хищения ТС, вещей из ТС, иных неправомерных действий в отношении ТС, в т.ч. оставление ТС
          с запущенным двигателем, отрытыми окнами/дверями/багажником/капотом</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 24.35pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">20&nbsp;000 (двадцать тысяч) рублей</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 24.35pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; height: 11.25pt; vertical-align: top;"
        rowspan="3">
        <p style="margin: 0cm; text-align: justify;">16</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 11.25pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Утрата или повреждение документов на ТС</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 11.25pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">20 000 (двадцать тысяч) рублей&nbsp;</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 11.25pt; vertical-align: top;"
        colspan="2" rowspan="3">
        <p style="margin: 0cm; text-align: justify;">Применяется отдельно в отношении документов, оборудования,
          стандартного автомобильного набора. В случае, если применительно к утрате конкретных видов документов,
          оборудования предусмотрены самостоятельные штрафы, применяются такие самостоятельные штрафы.</p>
      </td>
    </tr>
    <tr>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 18.75pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Утрата оборудования ТС</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 18.75pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">4&nbsp;000 (четыре тысячи) рублей</p>
      </td>
    </tr>
    <tr>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 19.5pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;"><span style="color: black;">Утрата стандартного автомобильного набора</span>
        </p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 19.5pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">4&nbsp;000 (четыре тысячи) рублей</p>
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; height: 11.25pt; vertical-align: top;"
        rowspan="4">
        <p style="margin: 0cm; text-align: justify;">17</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 11.25pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Повреждение ТС, если за него не предусмотрены отдельные меры
          ответственности.</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 11.25pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">10% от определенного в соответствии с Договором размера убытков в
          связи с повреждением ТС</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 11.25pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">При повреждении ТС убытки взыскиваются сверх указанной суммы
          неустойки (штрафная неустойка, ст. 394 ГК РФ, п. 60 Постановления Пленума ВС РФ от 24.03.2016 г. № 7).</p>
      </td>
    </tr>
    <tr>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 102pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Загрязнение ТС</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 102pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">Небольшие загрязнения &ndash; 200 (двести) рублей</p>
        <p style="margin: 0cm; text-align: justify;">Существенные загрязнения:</p>
        <p style="margin: 0cm; text-align: justify;">а) при загрязнении одного элемента ТС - 3 000 (три тысячи)
          рублей;</p>
        <p style="margin: 0cm; text-align: justify;">б) при загрязнении более одного элемента ТС &ndash; 5&nbsp;000
          (пять тысяч) рублей.</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 102pt; vertical-align: top;"
        colspan="2" rowspan="3">
        <p style="margin: 0cm; text-align: justify;">Небольшие загрязнения (сиденья, внутренняя обивка, коврики) &ndash;
          мелкие (до 10 см) <strong><u>и</u></strong> немногочисленные (не более 3-х шт.; критерий не применяется при
          оценке загрязнения багажного отсека) загрязнения, которые могут быть полностью удалены (в т.ч. следы) уборкой
          салона (щетка, пылесос) без применения воды, моющих средств или специальных методов (химчистка, влажная уборка
          и подобное), и которые не относятся к существенным загрязнениям.</p>
        <p style="margin: 0cm; text-align: justify;">Существенные загрязнения (сиденья, внутренняя обивка, коврики):</p>
        <p style="margin: 0cm; text-align: justify;">- множественные (более 3-х шт.; критерий не применяется при оценке
          загрязнения багажного отсека) <strong><u>и/или</u></strong> крупные (более 10 см) загрязнения (грязь), которые
          могут быть полностью удалены (в т.ч. следы) уборкой салона (щетка, пылесос) без применения воды, моющих
          средств или без специальных методов (химчистка, влажная уборка и подобное);</p>
        <p style="line-height: normal; text-align: justify; margin: 0cm;">- загрязнения (грязь), которые не могут быть
          удалены без применения воды, моющих средств или без специальных методов (химчистка, влажная уборка и
          подобное);</p>
        <p style="line-height: normal; text-align: justify; margin: 0cm;">- следы обуви;</p>
        <p style="line-height: normal; text-align: justify; margin: 0cm;">- продукты жизнедеятельности органического
          происхождения независимо от состава и консистенции, следы от перевозки животного без специального контейнера
          (шерсть, следы лап, перья и проч.), пищевые отходы (кроме заводской упаковки, потребительская тара).</p>
        <p style="margin: 0cm; text-align: justify;">Понятие &laquo;грязь&raquo; применяется в общеупотребимом значении.
          В случае возникновения споров &laquo;грязь&raquo; определяется как вещество (в т.ч. следы вещества),
          инородного происхождения по отношению к объекту, на котором она обнаружена, и влияющая на качественные (в т.ч.
          эстетические) характеристики данного объекта.</p>
        <p style="margin: 0cm; text-align: justify;">Под &laquo;загрязнением&raquo; в зависимости от контекста
          понимается возникновение, нанесение, наличие грязи.</p>
        <p style="margin: 0cm; text-align: justify;">Мусор &ndash; предметы, которые могут быть полностью удалены без
          применения специальных средств (вода, моющее средство, щетка, пылесос и подобные) и / или методов (химчистка,
          влажная уборка и подобное). В целях Договора к мусору относятся заводская упаковка товаров, потребительская
          тара, оставленные Пользователем вещи.</p>
        <p style="margin: 0cm; text-align: justify;">Элемент ТС &ndash; конструктивный элемент ТС (в т.ч. обшивки;
          пластика; предметов, с которыми предоставлено ТС), который может быть демонтирован / извлечен без ущерба его
          целостности безотносительно к необходимости демонтажа / извлечения других, в т.ч. связанных с ним,
          элементов.</p>
        <p style="margin: 0cm; text-align: justify;">Штраф применяется отдельно в отношении каждого состава нарушения,
          предусмотренного настоящим пунктом.</p>
      </td>
    </tr>
    <tr>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 16.1pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Оставление в ТС мусора</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 16.1pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">500 (пятьсот) рублей</p>
      </td>
    </tr>
    <tr>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 56.25pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Курение, употребление электронных сигарет (в т.ч. бестабачных),
          парогенераторов (вейпов и подобных), кальянов (в т.ч. бестабачных), закиси азота (кроме употребления при
          наличии медицинских показаний)</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 56.25pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">7&nbsp;000 (семь тысяч) рублей</p>
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; height: 12.35pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">18</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 12.35pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Выявление Компанией, что объем топлива, на которое был пополнен бак
          ТС при заправке, осуществленной Пользователем, менее объема топлива, отпущенного по топливной карте/картам или
          по чеку, предъявленному Пользователем для начисления бонусов</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 12.35pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">а) 10&nbsp;000 (десять тысяч) рублей &ndash; в случае, если объем
          заправленного топлива по данным программного обеспечения меньше отпущенного по топливной карте/картам или по
          предъявленному Пользователем чеку, на количество топлива от 2 литров до 10 литров;</p>
        <p style="margin: 0cm; text-align: justify;">б) 20&nbsp;000 (двадцать тысяч) рублей &ndash; в случае, если объем
          заправленного топлива по данным программного обеспечения меньше отпущенного по топливной карте/картам или по
          предъявленному Пользователем чеку, на 11 литров и более.</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 12.35pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; height: 12.35pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">18.1</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 12.35pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Продолжение аренды ТС при уровне заряда батареи 10% и менее</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 12.35pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">10&nbsp;000 (десять тысяч) рублей</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 12.35pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">Применяется при аренде ТС, использующих электродвигатели.</p>
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">19</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Заправка ТС топливом марки, не указанной в Договоре аренды ТС, если
          это не привело к выходу ТС из строя</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">2 000 (две тысячи) рублей</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">20</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Заправка ТС топливом марки, не указанной в Договоре аренды ТС, если
          это привело к выходу ТС из строя</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">50&nbsp;000 (пятьдесят тысяч) рублей</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; height: 14.95pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">21</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 14.95pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Нарушение иных условий Договора аренды ТС, если за их нарушение
          Договором и приложениями к нему не предусмотрены отдельные меры ответственности.</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 14.95pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">15&nbsp;000 (пятнадцать тысяч) рублей</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; height: 14.95pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">Применяется в случаях, когда за соответствующее нарушение не
          предусмотрен отдельный штраф, пени</p>
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">22</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Пени за просрочку исполнения платежных обязательств</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">0,1 % от суммы задолженности за каждый день просрочки со дня, когда
          платеж должен был быть совершен</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">23</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Единовременный штраф за неоплату сумм в счет административного
          штрафа в 5-дневный срок</p>
        <p style="margin: 0cm; text-align: justify;">(кроме случаев, когда Пользователем является юридическое лицо)</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">50 % от полного размера административного штрафа</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">24</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Нарушение срока заключения Основного договора аренды ТС</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">2,5 рубля за минуту</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">Неустойка рассчитывается начиная с первой минуты превышения срока
          заключения Основного договора аренды ТС до прекращения Предварительного договора аренды ТС.</p>
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">25</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Неустойка в связи с невозможностью использовать ТС из-за нарушений
          Арендатора</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">рассчитывается как количество минут, в течение которых ТС не могло
          использоваться из-за таких нарушений Договора (в т.ч. с момента эвакуации ТС до возврата ТС со
          специализированной (штрафной) стоянки), умноженное на стоимость нахождения ТС в режиме &laquo;Ожидание&raquo;
          в соответствии с базовым размером стоимости тарифного плана &laquo;Персональный&raquo;</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
      </td>
    </tr>
    <tr>
      <td
        style="width: 33.95pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">26</p>
      </td>
      <td
        style="width: 252.05pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Пени за использование ТС сверх максимального срока действия Пакета
          &laquo;Посуточный&raquo;</p>
      </td>
      <td
        style="width: 212.6pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">равна стоимости превышения действия Пакета &laquo;Посуточный&raquo;
          (руб./мин), условия которого нарушены</p>
      </td>
      <td
        style="width: 263.8pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;"
        colspan="2">
        <p style="margin: 0cm; text-align: justify;">Рассчитывается начиная с момента истечения срока действия Пакета
          &laquo;Посуточный&raquo; с учетом времени его продления.</p>
      </td>
    </tr>
    </tbody>
  </table>
  <p>* Уплата штрафа / пени не освобождает от обязанности возместить убытки</p>
  <p>Документ подписан Арендатором (Поверенным) электронной подписью в соответствии с Договором Делимобиль
    (https://delimobil.ru/terms/contract) и Договором аренды ТС</p>
  <p><strong>Форма для электронного подписания:</strong></p>
  <p>Документ подписан Арендатором (Поверенным) электронной подписью в соответствии с Договором Делимобиль (<a
    href="https://delimobil.ru/terms/contract">https://delimobil.ru/terms/contract</a>) и Договором аренды ТС</p>
  <br/>
  <table style="width: 320.25pt; margin-left: -.25pt; border-collapse: collapse; border: none;">
    <tbody>
    <tr>
      <td
        style="width: 320.25pt; border: 1pt solid #0070c0; padding: 0cm 5.4pt 0cm 5.15pt; height: 72.7pt; vertical-align: top;">
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ФИО Арендатора: ${full_name}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">Логин Арендатора: ${login}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ID Арендатора: ${client_id}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ID аренды: ${rent_id}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">Электронная подпись: ${digital_signature}</span>
        </p>
      </td>
    </tr>
    </tbody>
  </table>
  <br/>
  <p><strong>Приложение № 4 к Договору Аренды ТС</strong></p>
  <p>(в редакции Приказа № ДД-30 от &laquo;06&raquo; декабря 2019 г., вступило в силу в 00:00 11.12.2019 г.)</p>
  <p align="center"><strong>ИНСТРУКЦИЯ ПО ОПРЕДЕЛЕНИЮ НОРМАЛЬНОГО ИЗНОСА ТС</strong></p>
  <p>Настоящей Инструкцией по определению нормального износа ТС (далее &ndash; Инструкция) установлены минимальные
    стандарты состояния ТС, которые определяются как &laquo;нормальный износ автомобиля&raquo;. Оценка нормального
    износа и причин повреждений осуществляется Компанией в соответствии с Договором Делимобиль, Договором аренды ТС и
    приложений к ним, и зависит, в том числе, от срока, характера эксплуатации ТС, фактического пробега ТС. Данные
    обстоятельства учитываются при определении состояния ТС. Вместе с тем ряд повреждений ТС выходит за рамки износа,
    который возможен при обычной эксплуатации ТС. Нормальным износом в любом случае не может являться получение
    повреждений в результате ДТП.</p>
  <p><strong>Характер повреждений</strong></p>
  <div style="line-height: 115%; margin: 0cm 0cm 10.0pt 0cm;" align="center">
    <table style="width: 524.25pt; border-collapse: collapse; border: none;">
      <tbody>
      <tr>
        <td
          style="width: 226.55pt; border: 1pt solid windowtext; background: #c4bc96; padding: 0cm 5.4pt; vertical-align: top;">
          <p style="margin: 0cm; text-align: center;"><strong>Приемлемо</strong></p>
        </td>
        <td
          style="width: 297.7pt; border-top: 1pt solid windowtext; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-image: initial; border-left: none; background: #c4bc96; padding: 0cm 5.4pt; vertical-align: top;">
          <p style="margin: 0cm; text-align: center;"><strong><span
            style="font-family: 'Times New Roman',serif; color: black;">Неприемлемо</span></strong></p>
        </td>
      </tr>
      <tr>
        <td
          style="width: 524.25pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; background: #c4bc96; padding: 0cm 5.4pt; vertical-align: top;"
          colspan="2">
          <p style="margin: 0cm; text-align: center;"><strong><span
            style="font-family: 'Times New Roman',serif; color: black;">Кузов и ЛКП</span></strong></p>
        </td>
      </tr>
      <tr>
        <td
          style="width: 226.55pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
          <p style="margin: 0cm; text-align: justify;">Сколы и царапины до 10 см, при условии, что они могут быть
            удалены механической полировкой;</p>
          <p style="margin: 0cm; text-align: justify;">Потертости и следы абразивного износа до 2 см, при условии, что
            они могут быть удалены механической полировкой;</p>
          <p style="margin: 0cm; text-align: justify;">Вмятины до 2 см при условии не более двух вмятин на детали;</p>
          <p style="margin: 0cm; text-align: justify;">Небольшие участки сколов от камней, при условии, что повреждено
            не более 25 % поверхности детали и под сколами нет коррозии;</p>
          <p style="margin: 0cm; text-align: justify;">Сколы, правильно обработанные во избежание коррозии</p>
        </td>
        <td
          style="width: 297.7pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
          <p style="margin: 0cm; text-align: justify;">Сколы и царапины более 10 см;</p>
          <p style="margin: 0cm; text-align: justify;">Потертости и следы абразивного износа более 2 см;</p>
          <p style="margin: 0cm; text-align: justify;">Сколы, царапины, потертости и следы абразивного износа, которые
            не могут быть удалены механической полировкой;</p>
          <p style="margin: 0cm; text-align: justify;">Вмятины более 2 см;</p>
          <p style="margin: 0cm; text-align: justify;">Более двух вмятин на детали;</p>
          <p style="margin: 0cm; text-align: justify;">Вмятины, потертости, царапины со следами коррозии;</p>
          <p style="margin: 0cm; text-align: justify;">Сколы (от камней), занимающие более 25 % поверхности детали;</p>
          <p style="margin: 0cm; text-align: justify;">Некачественная окраска с видимой разницей в цвете;</p>
          <p style="margin: 0cm; text-align: justify;">Некачественный кузовной ремонт;</p>
          <p style="margin: 0cm; text-align: justify;">Коррозия в результате повреждения любого типа;</p>
          <p style="margin: 0cm; text-align: justify;">Изменение цвета в результате внешнего воздействия (химические
            вещества, растения, экскременты животных и птиц и т.п.)</p>
        </td>
      </tr>
      <tr>
        <td
          style="width: 524.25pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; background: #c4bc96; padding: 0cm 5.4pt; vertical-align: top;"
          colspan="2">
          <p style="margin: 0cm; text-align: center;"><strong><span
            style="font-family: 'Times New Roman',serif; color: black;">Решетка радиатора и бампер</span></strong></p>
        </td>
      </tr>
      <tr>
        <td
          style="width: 226.55pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
          <p style="margin: 0cm; text-align: justify;">Сколы и царапины до 10 см, при условии, что они могут быть
            удалены механической полировкой;</p>
          <p style="margin: 0cm; text-align: justify;">Потертости и следы абразивного износа до 2 см, при условии, что
            они могут быть удалены механической полировкой;</p>
          <p style="margin: 0cm; text-align: justify;">Для текстурированных или неокрашенных бамперов: царапины и задиры
            до 10 см считаются приемлемыми;</p>
          <p style="margin: 0cm; text-align: justify;">Для текстурированных или неокрашенных бамперов: потертости и
            следы абразивного износа до 2 см считаются приемлемыми;</p>
          <p style="margin: 0cm; text-align: justify;">Вмятины до 2 см, не более 2 вмятин на бампере или решетке
            радиатора;</p>
          <p style="margin: 0cm; text-align: justify;">Изменение цвета в результате внешнего воздействия, например,
            погодных факторов</p>
        </td>
        <td
          style="width: 297.7pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
          <p style="margin: 0cm; text-align: justify;">Сколы и царапины более 10 см либо независимо от размера, если они
            не могут быть удалены механической полировкой;</p>
          <p style="margin: 0cm; text-align: justify;">Потертости и следы абразивного износа более 2 см либо независимо
            от размера, если они не могут быть удалены механической полировкой;</p>
          <p style="margin: 0cm; text-align: justify;">Для окрашенных бамперов: любые сколы, царапины, потертости и
            следы абразивного износа, которые не могут быть удалены механической полировкой;</p>
          <p style="margin: 0cm; text-align: justify;">Сломанные, треснувшие или деформированные решетки радиаторов и
            бамперы;</p>
          <p style="margin: 0cm; text-align: justify;">Вмятины более 2 см;</p>
          <p style="margin: 0cm; text-align: justify;">Более двух вмятин на бампере или решетке радиатора;</p>
          <p style="margin: 0cm; text-align: justify;">Повреждения в результате неправильного использования химических
            веществ и внешних воздействий (растения, экскременты животных, птиц и т.д.)</p>
        </td>
      </tr>
      <tr>
        <td
          style="width: 524.25pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; background: #c4bc96; padding: 0cm 5.4pt; vertical-align: top;"
          colspan="2">
          <p style="margin: 0cm; text-align: center;"><strong><span
            style="font-family: 'Times New Roman',serif; color: black;">Шины и диски</span></strong></p>
        </td>
      </tr>
      <tr>
        <td
          style="width: 226.55pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
          <p style="margin: 0cm; text-align: justify;">Шины с остаточной глубиной протектора не менее 1,6 мм для летней
            резины, 4 мм &ndash; для зимней, с протектором, отвечающим местным нормативным требованиям;</p>
          <p style="margin: 0cm; text-align: justify;">На поверхности колпака, стального или легкосплавного диска: одна
            царапина, потертость или след абразивного износа до 10 см;</p>
          <p style="margin: 0cm; text-align: justify;">На отбортовке стального или легкосплавного диска: одна царапина,
            потертость или след абразивного износа до 10 см</p>
        </td>
        <td
          style="width: 297.7pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
          <p style="margin: 0cm; text-align: justify;">Царапины, потертости или следы абразивного износа более 10
            см;</p>
          <p style="margin: 0cm; text-align: justify;">Деформация шины, вызванная наездом на бордюр;</p>
          <p style="margin: 0cm; text-align: justify;">Вздутия, трещины или порезы на шинах;</p>
          <p style="margin: 0cm; text-align: justify;">Повреждения боковин или протектора;</p>
          <p style="margin: 0cm; text-align: justify;">Проколы шин посторонними предметами;</p>
          <p style="margin: 0cm; text-align: justify;">Сломанный или деформированный колпак, стальной или легкосплавный
            диск;</p>
          <p style="margin: 0cm; text-align: justify;">Коррозия на стальном или легкосплавном диске</p>
        </td>
      </tr>
      <tr>
        <td
          style="width: 524.25pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; background: #c4bc96; padding: 0cm 5.4pt; vertical-align: top;"
          colspan="2">
          <p style="margin: 0cm; text-align: center;"><strong><span
            style="font-family: 'Times New Roman',serif; color: black;">Стекла и внешние световые приборы</span></strong>
          </p>
        </td>
      </tr>
      <tr>
        <td
          style="width: 226.55pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
          <p style="margin: 0cm; text-align: justify;">Сколы от камней не более 1 см и не находящиеся в зоне А лобового
            стекла (центральная часть стекла шириной 290 мм);</p>
          <p style="margin: 0cm; text-align: justify;">Сколы от камней на поверхности фар головного света,
            противотуманных фар или указателей поворота, не сквозные и не влияющие на работоспособность</p>
        </td>
        <td
          style="width: 297.7pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
          <p style="margin: 0cm; text-align: justify;">Разбитые стекло или внешние световые приборы;</p>
          <p style="margin: 0cm; text-align: justify;">Трещины или сколы на внешних световых приборах, ограничивающие их
            функции. Все лампочки должны работать исправно;</p>
          <p style="margin: 0cm; text-align: justify;">Самостоятельно нанесенные солнцезащитные или тонирующие полосы
            должны быть полностью удалены с лобового стекла и передовых стекол, со всех стекол, если они не были
            нанесены квалифицированным специалистом, порваны или начали отделяться по углам лобового стекла;</p>
          &sect;
          <p style="margin: 0cm; text-align: justify;">Сколы и трещины более 1 см и/или в зоне А</p>
        </td>
      </tr>
      <tr>
        <td
          style="width: 524.25pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; background: #c4bc96; padding: 0cm 5.4pt; vertical-align: top;"
          colspan="2">
          <p style="margin: 0cm; text-align: center;"><strong><span
            style="font-family: 'Times New Roman',serif; color: black;">Зеркала и внешняя отделка</span></strong></p>
        </td>
      </tr>
      <tr>
        <td
          style="width: 226.55pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
          <p style="margin: 0cm; text-align: justify;">Окрашенные корпуса зеркал: потертости и царапины до 5 см, при
            условии, что они могут быть удалены механической полировкой;</p>
          <p style="margin: 0cm; text-align: justify;">Для текстурированных или неокрашенных корпусов зеркал:
            потертости, царапины и задиры до 5 см</p>
        </td>
        <td
          style="width: 297.7pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
          <p style="margin: 0cm; text-align: justify;">Окрашенные корпуса зеркал: потертости и царапины более 5 см либо
            любые потертости и царапины, которые не могут быть удалены механической полировкой;</p>
          <p style="margin: 0cm; text-align: justify;">Для текстурированных или неокрашенных корпусов зеркал:
            потертости, царапины и задиры более 5 см;</p>
          <p style="margin: 0cm; text-align: justify;">Деформированные или сломанные зеркала и/или корпуса зеркал</p>
        </td>
      </tr>
      <tr>
        <td
          style="width: 524.25pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; background: #c4bc96; padding: 0cm 5.4pt; vertical-align: top;"
          colspan="2">
          <p style="margin: 0cm; text-align: center;"><strong><span
            style="font-family: 'Times New Roman',serif; color: black;">Салон</span></strong></p>
        </td>
      </tr>
      <tr>
        <td
          style="width: 226.55pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
          <p style="margin: 0cm; text-align: justify;">Загрязнения и пятна на сиденьях, внутренней обивке, резиновых или
            тканевых ковриках, которые можно удалить обычной уборкой салона (без мойки);</p>
          <p style="margin: 0cm; text-align: justify;">Сиденья со следами износа и вмятинами в результате обычного
            использования;</p>
          <p style="margin: 0cm; text-align: justify;">Изменение цвета панели в результате повседневного использования и
            износа</p>
        </td>
        <td
          style="width: 297.7pt; border-top: none; border-left: none; border-bottom: 1pt solid windowtext; border-right: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
          <p style="margin: 0cm; text-align: justify;">Загрязнения и пятна на сиденьях, внутренней обивке, резиновых или
            тканевых ковриках, требующие мойки или специальной химической чистки;</p>
          <p style="margin: 0cm; text-align: justify;">Порезы, следы абразивного износа, разрывы, отверстия и прочие
            повреждения и деформации салона, материала внутренней обивки, резиновых и тканевых ковриков;</p>
          <p style="margin: 0cm; text-align: justify;">Отверстия в консоли после удаления оборудования;</p>
          <p style="margin: 0cm; text-align: justify;">Порезы, зарубки или разошедшиеся строчки на рулевом колесе;</p>
          <p style="margin: 0cm; text-align: justify;">Неприятные запахи, требующие специальной чистки для удаления</p>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <p><strong>Форма для электронного подписания:</strong></p>
  <p>Документ подписан Арендатором (Поверенным) электронной подписью в соответствии с Договором Делимобиль (<a
    href="https://delimobil.ru/terms/contract">https://delimobil.ru/terms/contract</a>) и Договором аренды ТС</p>
  <br/>
  <table style="width: 320.25pt; margin-left: -.25pt; border-collapse: collapse; border: none;">
    <tbody>
    <tr>
      <td
        style="width: 320.25pt; border: 1pt solid #0070c0; padding: 0cm 5.4pt 0cm 5.15pt; height: 72.7pt; vertical-align: top;">
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ФИО Арендатора: ${full_name}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">Логин Арендатора: ${login}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ID Арендатора: ${client_id}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ID аренды: ${rent_id}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">Электронная подпись: ${digital_signature}</span>
        </p>
      </td>
    </tr>
    </tbody>
  </table>
  <br/>
  <p>УТВЕРЖДЕНО Приказом № ДД-43</p>
  <p>от &laquo;11&raquo; мая 2020 г.</p>
  <p>(в редакции Приказа № ДД-71 от 25.04.2022 г., размещено 26.04.2022 г.)</p>
  <p style="margin: 0cm; text-align: center;"><strong>ПОЛОЖЕНИЕ О ТАРИФАХ</strong></p>
  <p style="margin: 0cm; text-align: center;"><strong>(&laquo;ЛИЧНЫЙ&raquo; РЕЖИМ)</strong></p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Положение о тарифах является неотъемлемой частью
    документов об использовании Сервиса Делимобиль и устанавливает размеры вознаграждения Компании за услуги,
    предоставляемые по Договору аренды ТС.</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Термины и определения, используемые в Положении о
    тарифах, определены Сторонами в Договоре Делимобиль. Термины и определения, не раскрытые в настоящем Положении или
    Договоре Делимобиль, применяются в значении, определенном действующим законодательством.&nbsp;</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Актуальная информация о характеристиках (объем,
    стоимость и проч.) Тарифных планов, Пакетах, а также об имеющихся Опциях размещается в Мобильном приложении.</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Настоящее Положение о тарифах применяется в &laquo;Личном&raquo;
    режиме использования ТС.</p>
  <div style="line-height: 115%; margin: 0cm 0cm 10.0pt 0cm;">
    <ol style="margin-bottom: 0cm; list-style-type: upper-roman; margin-left: 0cmundefined;">
      <li style="line-height: 115%; margin: 0cm 0cm 10.0pt 0cm;"><strong>ТАРИФНЫЕ ПЛАНЫ</strong></li>
    </ol>
  </div>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Тарифные планы представляют собой размер
    вознаграждения Компании, связанный с временем использования ТС.</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Выбор тарифного плана осуществляется в Мобильном
    приложении перед бронированием ТС (заключение Предварительного договора аренды ТС).</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Размеры тарифных планов утверждаются Компанией и
    отображаются в Мобильном приложении при заключении Предварительного договора аренды ТС.</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Обязательство по оплате в соответствии с Тарифным
    планом возникает по завершении Сессии аренды.</p>
  <div style="line-height: 115%; margin: 0cm 0cm 10.0pt 0cm;">
    <ol style="margin-bottom: 0cm; list-style-type: decimal;">
      <li style="line-height: 115%; margin: 0cm 0cm 10.0pt 0cm;"><strong>&laquo;ПЕРСОНАЛЬНЫЙ&raquo;</strong></li>
    </ol>
  </div>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Тарифный план &laquo;Персональный&raquo; является
    основным для всех пользователей Сервиса и применяется ко всем Пользователям, если они не выбрали другой тарифный
    план в соответствии с правилами и условиями, установленными Положением о тарифах для соответствующего тарифа.</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Стоимость использования ТС по тарифному плану
    &laquo;Персональный&raquo; складывается из базового размера стоимости тарифного плана &laquo;Персональный&raquo; и
    коэффициентов:</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">- коэффициент рейтинг, определяемый в зависимости
    от нескольких факторов, в т.ч.: стажа вождения, анализа предыдущих Сессий аренды Пользователя;</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">- динамический коэффициент, определяемый в
    зависимости от нескольких факторов, в т.ч.: вероятность аренды ТС в ближайшее время (исходя из места нахождения ТС);
    времени, прошедшего с последней завершенной Сессии аренды данного ТС; марки ТС.</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Стоимость рассчитывается по специальной формуле.
    Результат расчета стоимости указывается в Мобильном приложении, фиксируется на момент Бронирования и
    распространяется на Сессию аренды, совершенную из данного Бронирования. В случае завершения Бронирования без аренды
    ТС, расчет стоимости осуществляется заново при очередном Бронировании.</p>
  <div style="line-height: 115%; margin: 0cm 0cm 10.0pt 0cm;">
    <ol style="margin-bottom: 0cm; list-style-type: decimal;">
      <li style="line-height: 115%; margin: 0cm 0cm 10.0pt 0cm;"><strong>&laquo;ПЕРСОНАЛЬНЫЙ.СКАЗКА&raquo;</strong></li>
    </ol>
  </div>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Тарифный план &laquo;Персональный.Сказка&raquo;
    является специальным альтернативным тарифным планом для пользователей Сервиса, который может быть предложен
    Пользователям в соответствии с ч.1 ст. 421 ГК РФ.</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Для рассмотрения Компанией вопроса о переводе
    клиента на тарифный план &laquo;Персональный.Сказка&raquo; Клиент должен направить заявку о подключении к этому
    тарифному плану с использованием Мобильного приложения посредством нажатия соответствующих активных клавиш.</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Доступность тарифного плана (в том числе
    прекращение доступа к нему) определяется Компанией самостоятельно, в т.ч. на основе следующих факторов: наличие у
    Пользователя задолженности перед Компанией; наличие фактов ДТП или иных повреждений ТС в рамках использования
    Сервиса; наличие фактов нарушения Договора Делимобиль, Договора аренды ТС, документов об использовании Сервиса;
    вероятность наступления указанных обстоятельств, а также иные факторы. При возникновении указанных в настоящем
    абзаце оснований Компания вправе прекратить доступ Пользователя к тарифному плану
    &laquo;Персональный.Сказка&raquo;.</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">В рамках тарифного плана &laquo;Персональный.Сказка&raquo;
    установленный Договором аренды ТС предел взыскания (ограничение ответственности) санкций (убытки, штрафы за
    повреждение ТС) с Пользователя по одному случаю повреждения ТС, составляет 0 (ноль) рублей.</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Исключения, при которых ограничение ответственности
    не применяется или применяется частично, остаются неизменными.</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Стоимость использования ТС по тарифному плану
    &laquo;Персональный.Сказка&raquo; складывается из базового размера стоимости тарифного плана &laquo;Персональный.Сказка&raquo;
    и коэффициента, определяемого в зависимости от нескольких факторов, в т.ч.: региона использования ТС, стажа
    вождения, анализа предыдущих Сессий аренды Пользователя.</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Стоимость рассчитывается по специальной формуле.
    Результат расчета стоимости указывается в Мобильном приложении, фиксируется на момент Бронирования и
    распространяется на Сессию аренды, совершенную из данного Бронирования. В случае завершения Бронирования без аренды
    ТС, расчет стоимости осуществляется заново при очередном Бронировании.</p>
  <div style="line-height: 115%; margin: 0cm 0cm 10.0pt 0cm;">
    <ol style="margin-bottom: 0cm; list-style-type: decimal;">
      <li style="line-height: 115%; margin: 0cm 0cm 10.0pt 0cm;"><strong>&laquo;ФИКСИРОВАННЫЙ&raquo;</strong></li>
    </ol>
  </div>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Фиксированный тарифный план &ndash; рассчитанная
    Программным обеспечением Делимобиль фиксированная стоимость Сессии аренды ТС по заданной Арендатором в Мобильном
    приложении характеристике &ndash; месте начала и завершения Сессии аренды. Место завершения Сессии аренды для целей
    применения Фиксированного тарифа определяется в Мобильном приложении радиусом, ограничивающим территорию завершения
    Сессии аренды.</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Стоимость Сессии аренды определяется в зависимости
    от нескольких факторов, в т.ч.: предполагаемое время поездки, рассчитанное исходя из маршрута; наличие заторов
    движения по пути следования; тарифного плана, применяемого пользователем; иных факторов.</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Результат расчета стоимости Сессии аренды
    указывается в Мобильном приложении, фиксируется на момент Бронирования и распространяется на всю Сессию аренды,
    совершаемую из данного Бронирования в заданный период времени в место завершения Сессии аренды.</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">В целях применения Фиксированного тарифного плана
    Сессия аренды должна быть завершена в пределах радиуса завершения Сессии аренды, указанном в Мобильном приложении
    без превышения Сессии аренды и расчетного максимального времени Сессии аренды. Радиус завершения Сессии аренды и
    расчетное максимальное время Сессии аренды указываются в Мобильном приложении.</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">В случае несоблюдения Пользователем условий
    Фиксированного тарифного плана, (превышения расчетного максимального времени Сессии аренды или завершении Сессии
    аренды за пределами определенного Мобильным приложением радиуса места завершения Сессии аренды) применяется
    наибольшая стоимость Сессии аренды из стоимости, рассчитанной по Фиксированному тарифному плану, и стоимости,
    рассчитанной из поминутного тарифного плана (&laquo;Персональный&raquo;, &laquo;Персональный.Сказка&raquo;).</p>
  <p style="margin: 0cm; text-align: center;"><strong>II</strong><strong>. ПАКЕТЫ</strong></p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Компания предусматривает возможность приобретения
    Пользователем Пакетов. Приобретение пакетов осуществляется посредством их выбора в Мобильном приложении перед
    бронированием ТС. Обязательство по оплате Пакета возникает при его приобретении. Наличие у Пользователя
    приобретенного Пакета не исключает право Компании заблокировать Учетную запись Пользователя.</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Скидки и бонусы, снижающие стоимость услуг Сервиса,
    а также условия, действующие в отношении Тарифных планов, в отношении Пакетов не применяются, за исключением
    случаев, когда возможность применения таких скидок, льгот и бонусов прямо предусмотрена Пакетом или условиями
    отдельных акций.</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Стоимость Пакетов утверждается Компанией и
    отображается в Мобильном приложении (актуальные редакции).</p>
  <p style="margin: 0cm; text-align: center;"><strong>1. ПАКЕТ &laquo;ПОСУТОЧНЫЙ&raquo;</strong></p>
  <div style="line-height: 115%; margin: 0cm 0cm 10.0pt 0cm;">
    <ol style="margin-bottom: 0cm; list-style-type: decimal;">
      <li style="line-height: 115%; margin: 0cm 0cm 10.0pt 0cm;">Варианты пакета &laquo;Посуточный&raquo; (далее в
        разделе &ndash; &laquo;Пакет&raquo;):
      </li>
    </ol>
  </div>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">А) &laquo;Посуточный&raquo;</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">При использовании Пакета &laquo;Посуточный&raquo;
    применяется ограничение взыскания с Пользователя, предусмотренное Договором аренды ТС.</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Б) &laquo;Посуточный. Сказка&raquo;</p>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">При использовании Пакета &laquo;Посуточный.Сказка&raquo;
    ограничение взыскания с Пользователя, предусмотренное Договором аренды ТС, составляет 0 (ноль) рублей. Исключения,
    при которых ограничение ответственности не применяется или применяется частично, остаются неизменными.</p>
  <div style="line-height: 115%; margin: 0cm 0cm 10.0pt 0cm;">
    <ol style="margin-bottom: 0cm; list-style-type: decimal;">
      <li style="line-height: 115%; margin: 0cm 0cm 10.0pt 0cm;">Стоимость Пакета</li>
    </ol>
  </div>
  <p style="margin: 0cm; text-align: justify; text-indent: 35.45pt;">Стоимость Пакета складывается из базового размера
    стоимости Пакета и коэффициента, определяемого в зависимости от нескольких факторов, в т.ч.: региона использования
    ТС, стажа вождения, анализа предыдущих Сессий аренды Пользователя. Стоимость рассчитывается по специальной
    формуле.</p>
  <table style="border-collapse: collapse; border: none;">
    <tbody>
    <tr>
      <td style="width: 529.9pt; border: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;"><strong>Пакет сроком действия 23 часа 59 минут</strong></p>
      </td>
    </tr>
    <tr>
      <td
        style="width: 529.9pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Базовая стоимость Пакета: стоимость использования ТС при условии
          соблюдения срока действия Пакета.</p>
      </td>
    </tr>
    </tbody>
  </table>
  <div style="line-height: 115%; margin: 0cm 0cm 10.0pt 0cm;">
    <ol style="margin-bottom: 0cm; list-style-type: decimal;">
      <li style="line-height: 115%; margin: 0cm 0cm 10.0pt 0cm;">Система расчетов</li>
    </ol>
  </div>
  <table style="border-collapse: collapse; border: none;">
    <tbody>
    <tr>
      <td style="width: 529.9pt; border: 1pt solid windowtext; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;"><strong>Пакет сроком действия 23 часа 59 минут</strong></p>
      </td>
    </tr>
    <tr>
      <td
        style="width: 529.9pt; border-right: 1pt solid windowtext; border-bottom: 1pt solid windowtext; border-left: 1pt solid windowtext; border-image: initial; border-top: none; padding: 0cm 5.4pt; vertical-align: top;">
        <p style="margin: 0cm; text-align: justify;">Полная предоплата стоимости Пакета.</p>
      </td>
    </tr>
    </tbody>
  </table>
  <p>При ведении Бонусного счета Пользователя допускается приобретение Пакета за Бонусы в соответствии с правилами
    применения Бонусов, предусмотренными Договором Делимобиль, из расчета 1 Бонус = 1 рубль, которые списываются с
    Бонусного счета Пользователя.</p>
  <p>Пакет является единым и не подразумевает разделение его на отдельные минуты. В случае, если Пользователь не
    использовал все включенные в пакет минуты при использовании выбранного ТС, они не могут быть использованы им в
    дальнейшем. Если иное не предусмотрено настоящим разделом, компенсация за неиспользованные составляющие Пакета не
    производится: стоимость неиспользованных в составе Пакета минут засчитывается как неустойка при отказе Пользователя
    от аренды.</p>
  <p>1.4. Пользователь выбирает срок действия Пакета среди вариантов, доступных в Мобильном приложении. Пакеты являются
    срочными (срочный договор аренды). Срок аренды соответствует сроку действия Пакета.</p>
  <p>Допускается установление минимального и максимального срока действия Пакета. Соблюдение минимального и
    максимального срока действия Пакета обусловливает его цену, стоимость, является условием его приобретения,
    использования ТС.</p>
  <p>1.5. Пакет действует в отношении только одного ТС, которое выбирается Пользователем до момента начала Сессии
    аренды.</p>
  <p>1.6. В случае завершения Сессии аренды (Пакета) в течение 1 (одного) часа с момента заключения Договора аренды ТС,
    Пользователь имеет право на возврат стоимости Пакета. В случае, если на момент завершения Сессии аренды (Пакета) ТС
    не осуществляло движения, возврату подлежит полная стоимость Пакета. В случае, если на момент завершения Сессии
    аренды (Пакета) ТС осуществляло движение, возврату подлежит стоимость Пакета за вычетом стоимости фактического
    времени аренды ТС, рассчитанной по тарифному плану &laquo;Персональный&raquo;, действующему в отношении
    Пользователя. При этом возврат стоимости Пакета и оплата стоимости фактического времени аренды ТС могут
    производиться как одной транзакцией (с вычетом), так и двумя транзакциями (возврат и оплата отдельно). Возврат
    стоимости Пакета осуществляется автоматически. Возврат потраченных на приобретение Пакета Бонусов осуществляется в
    виде возврата Бонусов на Бонусный счет.</p>
  <p>1.6.1. В случае завершения Сессии аренды (Пакета) по истечении 1 (одного) часа с момента заключения Договора аренды
    ТС, но до истечения срока действия Пакета, Пользователь имеет право на компенсацию стоимости Пакета в виде Бонусов
    на Бонусный счет Пользователя (1 Бонус = 1 рубль), а при направлении Пользователем заявления на возврат денежных
    средств &ndash; в рублях. В случае, если на момент завершения Сессии аренды (Пакета) ТС не осуществляло движения,
    компенсации (возврату) подлежит полная стоимость Пакета. В случае, если на момент завершения Сессии аренды (Пакета)
    ТС осуществляло движение, компенсации (возврату) подлежит стоимость Пакета за вычетом стоимости фактического времени
    аренды ТС, рассчитанной по тарифному плану &laquo;Персональный&raquo;, действующему в отношении Пользователя. При
    этом компенсация (возврат) стоимости Пакета и оплата стоимости фактического времени аренды ТС могут производиться
    как одной транзакцией (с вычетом), так и двумя транзакциями (возврат и оплата отдельно). Компенсация (возврат)
    стоимости Пакета осуществляется при условии обращения Пользователя в Службу поддержки Компании. Возврат потраченных
    на приобретение Пакета Бонусов осуществляется в виде возврата Бонусов на Бонусный счет. Указанная компенсация
    начисляется только в случае, если досрочное завершение Сессии аренды (Пакета) связано с невозможностью использования
    ТС по причине:</p>
  <p>- нарушения в работе программного обеспечения и (или) систем связи, обеспечивающих взаимодействие Пользователя и
    ТС, а также Пользователя и Компании, возникшие не по вине Пользователя;</p>
  <p>- завершения Сессии аренды до окончания действия Пакета в связи с ошибкой на стороне Компании;</p>
  <p>- не связанной, с действиями (бездействием) Пользователя.</p>
  <p>1.7. Сведения о приобретении Пакета заносятся в Учетную запись Пользователя до активации Пакета. Активация
    приобретенного Пакета осуществляется при выборе ТС и применения к нему соответствующего Пакета.</p>
  <p>1.8. Начало действия Пакета: Пакет начинает действовать с момента начала Сессии аренды ТС.</p>
  <p>1.9. Окончание действия Пакета: истечение срока действия Пакета.</p>
  <p>1.10. Превышение максимального пробега ТС, включенного в Пакет, тарифицируется в соответствии с условиями Пакета.
    Оплата дополнительного пробега списывается после окончания Сессии аренды данного ТС.</p>
  <p>1.11. В случае, если Пользователь в момент истечения срока действия Пакета (23 часа 59 минут) использует ТС, Сессия
    аренды не завершается. При этом Пакет сроком действия 23 часа 59 минут не прекращает свое действие, но продлевается
    на 96 (девяносто шесть) часов по стоимости превышения действия Пакета (руб./мин.), определенной условиями Пакета. По
    истечении предоставленных 96 (девяносто шесть) часов Пакет прекращает свое действие, и Пользователь обязан завершить
    Сессию аренды. В случае если Сессия аренды не была прекращена по истечении предоставленных 96 (девяносто шесть)
    часов, Пользователь уплачивает пени до завершения текущей Сессии аренды в размере стоимости превышения действия
    Пакета (руб./мин.), определенной условиями Пакета.</p>
  <p>1.12. Пользователь может приобрести новый Пакет только после окончания действующего Пакета.</p>
  <p>2. ПАКЕТ &laquo;ПРОЕЗДНОЙ&raquo;</p>
  <p>2.1. Пакет &laquo;Проездной&raquo; (далее в разделе &ndash; &laquo;Пакет&raquo;) - персональное предложение,
    стоимость и содержание которого определяется в зависимости от нескольких факторов, в т.ч.: вероятность аренды ТС в
    ближайшее время (исходя из места нахождения ТС); времени, прошедшего с последней завершенной Сессии аренды данного
    ТС; марки ТС. Также при расчете стоимости учитываются коэффициенты, основанные на анализе региона использования ТС,
    стажа вождения, анализа предыдущих Сессий аренды Пользователя. Стоимость рассчитывается по специальной формуле.
    Результат расчета стоимости пакета &laquo;Проездной&raquo; указывается в Мобильном приложении, фиксируется на момент
    его приобретения.</p>
  <p>2.2. Доступность Пакета определяется Компанией.</p>
  <p>2.3. Сведения о приобретении Пакета заносятся в Учетную запись Пользователя до активации Пакета. Активация
    приобретенного Пакета осуществляется при его приобретении.</p>
  <p>2.4. Приобретенный Пакет является приоритетным по отношению к другим тарифам. Новый Пакет приостанавливает срок
    действия ранее приобретенного Пакета. Динамический тариф полностью заменяется Пакетом. В период действия Пакета
    доступны иные пакты, но не доступна обычная тарификация по тарифным планам, в т.ч. по тарифному плану &laquo;Динамический&raquo;.</p>
  <p>2.5. Варианты пакета &laquo;Проездной&raquo;:</p>
  <p>А) &laquo;Проездной&raquo;</p>
  <p>При использовании пакета &laquo;Проездной&raquo; применяется ограничение взыскания с Пользователя, предусмотренное
    Договором аренды ТС.</p>
  <p>Б) &laquo;Проездной. Сказка&raquo;</p>
  <p>При использовании пакета &laquo;Проездной. Сказка&raquo; ограничение взыскания с Пользователя, предусмотренное
    Договором аренды ТС, составляет 0 (ноль) рублей. Исключения, при которых ограничение ответственности не применяется
    или применяется частично, остаются неизменными.</p>
  <p>Пакеты указанных в настоящем пункте видов могут подразделяться на типы в зависимости от категории ТС, на которые
    распространяется соответствующий Пакет.&nbsp;</p>
  <p>2.6. Пакет включает в себя следующие ограничения:</p>
  <p>- срок действия пакета;</p>
  <p>- количество минут в режиме &laquo;Ожидание&raquo;;</p>
  <p>- количество минут в режиме &laquo;Аренда&raquo;;</p>
  <p>- ограничение на виды ТС, в отношении которых может применяться пакет.</p>
  <p>2.7. Система расчетов: предоплата. Использование Бонусов при оплате Пакета &laquo;Проездной&raquo; не
    допускается.</p>
  <p>2.8. Пакет является единым и не подразумевает разделение его на отдельные минуты использования ТС или срок действия
    пакета. В случае, если Пользователь в течение действия Пакета не использовал все включенные в Пакет минуты, они не
    могут быть использованы им в дальнейшем. Компенсация за неиспользованные составляющие Пакета не компенсируются.</p>
  <p>2.9. В течение действия Пакета Пользователь может арендовать ТС, определенные соответствующим Пакетом.</p>
  <p>2.10. В случае нарушения срока заключения Основного договора аренды ТС неустойка за каждую минуту просрочки,
    предусмотренная Положением о штрафах, соответствует стоимости минуты использования ТС в режиме &laquo;Ожидание&raquo;
    и вычитается из включенных в Пакет минут использования ТС в режиме &laquo;Ожидание&raquo;.</p>
  <p>2.11. Для целей расчета количества использованных минут учитываются только полные минуты.</p>
  <p>2.12. Пакет действует до завершения Сессии аренды, в течение которой закончился срок действия Пакета или были
    исчерпаны включенные в Пакет минуты использования ТС в режиме &laquo;Аренда&raquo;. По окончании действия Пакета
    Сессия аренды должна быть завершена Пользователем самостоятельно.</p>
  <p>2.13. При исчерпании срока действия Пакета Пакет прекращает свое действие по завершении текущей Сессии аренды.</p>
  <p>При исчерпании включенных в Пакет минут использования ТС в режиме &laquo;Аренда&raquo; Пакет прекращает своей
    действие по завершении текущей Сессии аренды. При этом минуты использования ТС в режиме &laquo;Аренда&raquo; сверх
    включенных в Пакет до завершения текущей Сессии аренды тарифицируются по стоимости дополнительных минут
    использования ТС в режиме &laquo;Аренда&raquo; в соответствии с условиями соответствующего Пакета.</p>
  <p>При исчерпании включенных в Пакет минут использования ТС в режиме &laquo;Ожидание&raquo; Пакет не прекращает свое
    действие. При этом минуты использования ТС в режиме &laquo;Ожидание&raquo; сверх включенных в Пакет до завершения
    действия Пакета тарифицируются по стоимости дополнительных минут использования ТС в режиме &laquo;Ожидание&raquo; в
    соответствии с условиями соответствующего Пакета.</p>
  <p>По завершении действия Пакета включается новый ранее приобретенный Пакет. При отсутствии нового приобретенного
    Пакета использование ТС осуществляется в соответствии с иным приобретенным пакетом, а при отсутствии такового
    &ndash; по действующему в отношении пользователя тарифному плану.</p>
  <p>2.14. Пользователь может приобрести новый Пакет только после окончания действующего Пакета.</p>
  <p>2.15. Предельный срок одной Сессии аренды при использовании Пакета ограничивается действием Пакета с учетом п. 2.12
    настоящего раздела.</p>
  <p>III. ДОПОЛНИТЕЛЬНЫЕ ОПЦИИ</p>
  <p>Дополнительные опции, при их наличии, могут быть подключены при использовании Сервиса. Актуальные и действующие
    Дополнительные опции отражаются в Мобильном приложении.</p>
  <p>IV. ФИОЛЕТОВЫЕ И ЧЕРНЫЕ ЗОНЫ</p>
  <p>1. Общие положения</p>
  <p>1.1. Компанией могут быть определены зоны (далее &ndash; &laquo;фиолетовые зоны&raquo;, &laquo;черные зоны&raquo;),
    завершение Сессии аренды в которых влечет за собой увеличение стоимости Сессии аренды.</p>
  <p>1.2. Компания определяет:</p>
  <p>- территориальное размещение фиолетовых / черных зон;</p>
  <p>- стоимость, на которую увеличивается стоимость Сессия аренды в случае ее завершения в фиолетовой / черной
    зоне;</p>
  <p>- ограничение на завершение Сессии аренды в фиолетовых / черных зонах для отдельных видов ТС;</p>
  <p>- иные условия перемещения ТС в условиях наличия фиолетовых / черных зон.</p>
  <p>1.3. Соответствующие сведения размещаются в Мобильном приложении и доступны Пользователю для ознакомления до начала
    Сессии аренды.</p>
  <p>2. Общие условия перемещения ТС в условиях наличия фиолетовых / черных зон</p>
  <p>2.1. Увеличение стоимости Сессии аренды и предоставление скидки при использовании ТС в условиях наличия фиолетовых
    / черных зон не применяются при использовании Пакета «Проездной».</p>
  <p>2.2. Увеличение стоимости Сессии аренды осуществляется при условии ее завершения в фиолетовой / черной зоне.</p>
  <p>2.3. При первом с момента начала применения в Сервисе фиолетовых / черных зон завершении Сессии аренды в пределах
    фиолетовой / черной зоны увеличение стоимости аренды ТС не осуществляется.</p>
  <p>2.4. В случае завершения Сессии аренды в фиолетовой зоне Пользователю предоставляется право на скидку при
    совершении следующей Сессии аренды ТС, начатой в фиолетовой / черной зоне и завершенной вне фиолетовой / черной
    зоны. В случае завершения Сессии аренды в черной зоне указанное право на скидку Пользователю не предоставляется.</p>
  <p>2.5. Скидка предоставляется в размере, на который увеличилась стоимость завершенной в фиолетовой зоне Сессии
    аренды.</p>
  <p>2.6. Скидка позволяет уменьшить стоимость Сессии аренды на размер скидки (в рублях), но не более чем на 97% или не
    более, чем до стоимости 2 (два) рубля 50 копеек за Сессию аренды. Не полностью использованная скидка не переносится
    на другие Сессии аренды и "сгорает".</p>
  <p>2.7. Пользователь утрачивает право на применение скидки (скидка "сгорает") в следующих случаях:</p>
  <p>- приобретение Пользователем Пакета &laquo;Проездной&raquo;;</p>
  <p>- начало Сессии аренды вне фиолетовой зоны;</p>
  <p>- завершение Сессии аренды в фиолетовой / черной зоне;</p>
  <p>- истечение 24 часов с момента наступления оснований для получения права на скидку (п. 2.4).</p>
  <p>2.8. Скидка, предоставляемая в соответствии с настоящим порядком, обладает меньшим приоритетом по отношению к иным
    скидкам, предоставляемым в рамках Сервиса, и применяется после применения других скидок.</p>
  <p>2.9. Скидки и бонусы, применяемые в рамках сервиса &laquo;Делимобиль&raquo;, не уменьшают размер увеличения
    стоимости Сессии аренды, применяемый в соответствии с настоящим разделом.</p>
  <p>3. Специальные условия перемещения ТС в условиях наличия фиолетовых / черных зон</p>
  <p>3.1. Специальные условия дополняют общие условия и обладают преимуществом при определении приоритета применяемых
    правил. Вопросы, не урегулированные специальными условиями, регулируются общими условиями.&nbsp;</p>
  <p>3.2. В случае начала Сессии аренды в фиолетовой / черной зоне, для которой предусмотрено увеличение стоимости
    Сессии аренды Х, и завершении Сессии аренды в фиолетовой зоне, для которой предусмотрено увеличение стоимости Сессии
    аренды Y:</p>
  <p>3.2.1. если Х==Y, то увеличение стоимости Сессии аренды не применяется, "скидка сгорает" (п. 2.7), право на скидку
    (п. 2.4) не предоставляется;</p>
  <p>3.2.2. если X&gt;Y, то увеличение стоимости Сессии аренды не применяется, скидка "сгорает" (п. 2.7), право на
    скидку (п. 2.4) не предоставляется;</p>
  <p>3.2.3. если X&lt;Y, то увеличение стоимости Сессии аренды равно разнице между X и Y, скидка "сгорает" (п. 2.7.),
    право на скидку (п. 2.4) сохраняется.</p>
  <p><strong>Форма для электронного подписания:</strong></p>
  <p>Документ подписан Арендатором (Поверенным) электронной подписью в соответствии с Договором Делимобиль (<a
    href="https://delimobil.ru/terms/contract">https://delimobil.ru/terms/contract</a>) и Договором аренды ТС</p>
  <br/>
  <table style="width: 320.25pt; margin-left: -.25pt; border-collapse: collapse; border: none;">
    <tbody>
    <tr>
      <td
        style="width: 320.25pt; border: 1pt solid #0070c0; padding: 0cm 5.4pt 0cm 5.15pt; height: 72.7pt; vertical-align: top;">
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ФИО Арендатора: ${full_name}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">Логин Арендатора: ${login}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ID Арендатора: ${client_id}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">ID аренды: ${rent_id}</span></p>
        <p style="line-height: normal; margin: 0cm;"><span
          style="font-family: 'Times New Roman',serif; color: #0070c0;">Электронная подпись: ${digital_signature}</span>
        </p>
      </td>
    </tr>
    </tbody>
  </table>
</div>
`;
