<template>
  <router-view />
</template>

<script>
export default {
  name: 'EmptyLayout',
};
</script>

<style lang="less">
body {
  background-color: white !important;
}
</style>
