export default {
  namespaced: true,

  state: {
    loader: false,

    enterClassName: 'animated slideInRight',
    leaveClassName: 'animated slideOutLeft',
    enterClassNameVoile: 'animated',
    leaveClassNameVoile: 'animated fadeOut',

    isHeaderMenuOpen: false,
    isFooterMenuOpen: false,
  },

  mutations: {
    changeTransitionEffects(state, params) {
      if (params.isMobile) {
        state.enterClassName = 'animated fadeIn';
        state.leaveClassName = 'animated';
      } else {
        state.enterClassName = 'animated slideInRight';
        state.leaveClassName = 'animated slideOutLeft';
      }
    },
    changeVoileTransitionEffects(state) {
      state.enterClassNameVoile = 'animated fadeIn';
      state.leaveClassNameVoile = 'animated fadeOut';
    },
    loaderON(state) {
      state.loader = true;
    },
    loaderOFF(state) {
      state.loader = false;
    },

    toggleHeaderMenuOpen(state) {
      state.isHeaderMenuOpen = !state.isHeaderMenuOpen;
    },
    toggleFooterMenuOpen(state) {
      state.isFooterMenuOpen = !state.isFooterMenuOpen;
    },
    hideHeaderMenu(state) {
      state.isHeaderMenuOpen = false;
    },
    hideFooterMenu(state) {
      state.isFooterMenuOpen = false;
    },
  },

  actions: {},
};
