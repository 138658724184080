export const functions = {
  data() {
    return {
      magicTextBlocks: {},
      magicTextComplete: false,
    };
  },

  methods: {
    _(val) {
      let page = 'App';

      if (this.pageData && this.pageData.page) {
        page = this.pageData.page;
      }

      if (
        this.$store.state.pages.pages[page] &&
        this.$store.state.pages.pages[page][val]
      ) {
        return this.$store.state.pages.pages[page][val];
      }

      return '';
    },
    bgImgStyle(images) {
      const stages = [];
      const urlStages = [];

      for (const i in images) {
        stages.push('url(' + images[i] + ') ' + i);
        urlStages.push("url('" + images[i] + "')");
      }

      const str = 'image-set(' + stages.join(',') + ')';

      if (urlStages.length === 1) {
        return {
          'background-image': [urlStages[0], '-webkit-' + str, str],
        };
      } else {
        return {
          'background-image': [urlStages[1], '-webkit-' + str, str],
        };
      }
    },
    magicText(attempt, asArray, blockId, removeStyleOnlyOnMobile) {
      const _t = this;
      const magic = window.$('.page:not(.v-leave-to) .magic-area');

      if (_t.isMobile) {
        magic.removeAttr('style');
        _t.magicTextComplete = true;
        return;
      }

      if (!attempt) {
        if (_t.magicTextBlocks[blockId]) {
          return;
        }

        attempt = 0;

        if (removeStyleOnlyOnMobile !== true) {
          magic.removeAttr('style');
        }
      }

      this.$nextTick(() => {
        setTimeout(() => {
          const blockHeight =
            asArray === true
              ? _t.$refs.magicOuter[0].clientHeight
              : _t.$refs.magicOuter.clientHeight;
          const innerHeight =
            asArray === true
              ? _t.$refs.magicInner[0].clientHeight
              : _t.$refs.magicInner.clientHeight;
          const innerHeightFontSize =
            (parseInt(magic.css('font-size')) * 100) / window.$(window).width();
          const innerHeightLineHeight =
            (parseInt(magic.css('line-height')) * 100) /
            window.$(window).width();

          if (innerHeight > blockHeight && attempt < 10) {
            const css = {
              'font-size': innerHeightFontSize - 0.2 + 'vw',
              'line-height': innerHeightLineHeight - 0.2 + 'vw',
            };
            magic.css(css);
            _t.magicTextBlocks[blockId] = css;

            // check it, анимация длится 0,3 секунды
            _t.magicText(++attempt, asArray, blockId, removeStyleOnlyOnMobile);
          } else {
            _t.magicTextComplete = true;
          }
        }, 50);
      });
    },
  },
};
