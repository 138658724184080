import axios from 'axios';
import ENV from '@/environment';
import { UrgentServiceInfo } from '@/components/urgentService/http/dto/urgentService';

export async function getUrgentServiceInfo(
  uuid: string,
): Promise<{ data: UrgentServiceInfo }> {
  return await axios.get(
    `${ENV.GUIDO_API}/api/v1/maintenances/${uuid}/emergency-info`,
  );
}

export async function getFakeMechMoving(uuid: string, newStep: number) {
  return await axios.get(
    `${ENV.GUIDO_API}/api/v1/maintenances/${uuid}/emergency-info/`,
    { params: { step: newStep } },
  );
}
