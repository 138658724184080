import { LatLngTuple } from '@googlemaps/polyline-codec';

export function calcPointsBetweenCoordinates(
  start: LatLngTuple,
  end: LatLngTuple,
  dotsStep: number,
) {
  if (dotsStep <= 0) {
    return [];
  }
  const NUMBER_OF_POINTS = 1; // 1 = 100%

  const coordinates = [];
  for (let i = 0; i < NUMBER_OF_POINTS; i += dotsStep) {
    const lng = start[0] + (end[0] - start[0]) * i;
    const lat = start[1] + (end[1] - start[1]) * i;
    const newCoordinate = [lng, lat];
    coordinates.push(newCoordinate);
  }
  return coordinates;
}
