






import DefaultLayout from '@/layouts/DefaultLayout.vue';
import UrgentServiceLayout from '@/layouts/UrgentServiceLayout.vue';
import EmptyLayout from '@/layouts/EmptyLayout.vue';

window.$ = window.jQuery = require('jquery');
window.fn = require('owl.carousel');

export default {
  name: 'App',
  components: {
    DefaultLayout,
    UrgentServiceLayout,
    EmptyLayout,
  },
  computed: {
    currentLayout() {
      if (this.$route.name === 'UrgentService') {
        return UrgentServiceLayout;
      }
      if (this.$route.name === 'SearchCar') {
        return EmptyLayout;
      }
      return DefaultLayout;
    },
  },
};
