import axios from 'axios';
import router from '../router';
import ENV from '@/environment';

import { articles } from '../components/articles';

export default {
  namespaced: true,

  state: {
    apiUrl: ENV.APP_API_HOST,
    articlesLoaded: false,
    articleLoaded: false,
    articles: articles,
    articlesRules: {},
    article: {},
  },
  mutations: {
    articlesLoaded(state) {
      state.articlesLoaded = true;
    },
    articleLoaded(state, payload) {
      state.articleLoaded = true;
      state.article = payload;
    },
  },
  actions: {
    async loadArticle({ commit, state }, params) {
      let response;

      if (params.id === 'contract') {
        // load contract
        response = await axios.get(
          `${ENV.APP_DELITIME_API_HOST}/api/v3/contract/latest.html`,
        );

        if (!response.data) {
          await router.push('Home');
        }

        const data = response.data;
        const _adapter = {
          text: data,
          titleRu: 'Договор',
          title: 'Contract',
        };
        commit('articleLoaded', _adapter);
      } else {
        commit(
          'articleLoaded',
          state.articles.find((article) => article.id === params.id),
        );
      }
    },
  },
};
