














































































import Router from '@/router';
import { MaintenanceStatus } from '@/components/urgentService/http/dto/urgentService';
import ServiceInfoBlock from '@/components/urgentService/components/ServiceInfoBlock.vue';
import { isMobile } from '@/mixins/isMobile';
import SafetyMemo from '@/components/urgentService/components/SafetyMemo.vue';
import Map from '@/components/urgentService/components/Map.vue';
import GeoLocator from '@/components/urgentService/components/GeoLocator.vue';
import UrgentServiceFallBack from '@/components/urgentService/components/FallBack.vue';
import {
  getFakeMechMoving,
  getUrgentServiceInfo,
} from '@/components/urgentService/http/urgentService';
import { adaptBlockToMobile } from '@/utils/adaptBlockToMobile';
import {
  STARTED_URGENT_SERVICE_INFO_REQUEST_INTERVAL,
  URGENT_SERVICE_INFO_REQUEST_INTERVAL,
} from '@/components/urgentService/consts';

export default {
  name: 'UrgentService',
  mixins: [isMobile],
  components: {
    UrgentServiceFallBack,
    GeoLocator,
    SafetyMemo,
    ServiceInfoBlock,
    Map,
  },
  data: () => {
    return {
      userLng: 0,
      userLat: 0,
      mechLng: 0,
      mechLat: 0,
      serviceIdentifier: 0,
      serviceStatus: MaintenanceStatus.Default,
      mechanicName: '',
      timeToArrival: 0,
      isLoadingService: false,
      isSafetyMemoOpened: false,
      routeEncodedPolyline: '',
      isServiceInfoLoaded: false,
      mechUpdateInterval: null,
      isMechanicBlockHidden: false,
      previousEncodedPolyline: '',

      testMechStep: 0,
    };
  },
  async mounted() {
    adaptBlockToMobile('.urgent-service');
    this.serviceIdentifier = Router?.currentRoute?.params?.serviceIdentifier;
    await this.fetchMechInfo();
    setTimeout(async() => {
      await this.handleRouteUpdate();
    }, URGENT_SERVICE_INFO_REQUEST_INTERVAL);
  },
  destroyed() {
    clearInterval(this.mechUpdateInterval);
  },
  computed: {
    isServiceActual() {
      return (
        this.serviceStatus === MaintenanceStatus.Started ||
        this.serviceStatus === MaintenanceStatus.NotStarted ||
        this.serviceStatus === MaintenanceStatus.NoRoute
      );
    },
    isServiceFinished() {
      return (
        this.serviceStatus === MaintenanceStatus.Finished ||
        this.serviceStatus === MaintenanceStatus.Cancelled ||
        this.serviceStatus === MaintenanceStatus.Error
      );
    },
    shouldShowMap() {
      return (
        this.isServiceInfoLoaded &&
        this.isServiceActual &&
        !this.isServiceFinished
      );
    },
  },
  methods: {
    handleBlockStateChange(newValue: boolean) {
      this.isMechanicBlockHidden = newValue;
    },
    async handleRouteUpdate() {
      if (this.serviceStatus === MaintenanceStatus.Started) {
        this.mechUpdateInterval = setInterval(async() => {
          await this.fetchMechInfo();
          const isServiceFinished =
            this.serviceStatus === MaintenanceStatus.Finished ||
            this.serviceStatus === MaintenanceStatus.Cancelled;
          if (isServiceFinished) {
            clearInterval(this.mechUpdateInterval);
          }
        }, STARTED_URGENT_SERVICE_INFO_REQUEST_INTERVAL); // если обслуживание началось, необходимо реже запрашивать данные до момента, когда оно закончится
        return;
      }
      // DEV
      if (process.env.NODE_ENV === 'development') {
        await this.fetchMechInfo(this.testMechStep);
        this.testMechStep += 1;
      } else {
        await this.fetchMechInfo();
      }
    },
    toggleMemo(newValue: boolean) {
      this.isSafetyMemoOpened = newValue;
    },
    async fetchMechInfo(newStep?: number) {
      try {
        // DEMO TEST
        let service;
        if (newStep) {
          service = await getFakeMechMoving(this.serviceIdentifier, newStep);
          //
        } else {
          // PROD
          service = await getUrgentServiceInfo(this.serviceIdentifier);
        }

        const {
          userName,
          maintenanceStatus,
          userCoordinates: { lng: mechLng, lat: mechLat },
          vehicleCoordinates: { lng: userLng, lat: userLat },
        } = service.data;
        const traffic = service.data.routes?.[0]?.duration?.traffic;
        const polyline = service.data.routes?.[0]?.geometry?.polyline || '';

        if (polyline.length && this.previousEncodedPolyline === polyline) {
          setTimeout(async() => {
            await this.handleRouteUpdate();
          }, URGENT_SERVICE_INFO_REQUEST_INTERVAL);
          return;
        }

        this.mechanicName = userName;
        this.timeToArrival = Math.ceil(traffic / 60);
        this.routeEncodedPolyline = polyline;
        this.userLng = userLng;
        this.userLat = userLat;
        this.mechLng = mechLng;
        this.mechLat = mechLat;
        this.previousEncodedPolyline = polyline;

        if (!this.routeEncodedPolyline.length) {
          this.serviceStatus = MaintenanceStatus.NoRoute;
        } else {
          this.serviceStatus = maintenanceStatus;
        }
      } catch (e) {
        console.error(e);
        this.serviceStatus = MaintenanceStatus.Error;
      } finally {
        this.isServiceInfoLoaded = true;
      }
    },
  },
};
