import Vue from 'vue';
import VueI18n from 'vue-i18n';

import ruen from './i18n/ru/en.json';
import ruru from './i18n/ru/ru.json';

import byen from './i18n/by/en.json';
import byru from './i18n/by/ru.json';

import ENV from '@/environment';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'ru', // set locale
  messages: {
    // set locale messages
    ...(ENV.REGION === 'ru'
      ? {
          en: {
            lang: ruen,
          },
        }
      : {
          en: {
            lang: byru,
          },
        }),
    ru: {
      lang: ENV.REGION === 'by' ? byru : ruru,
    },
  },
});
