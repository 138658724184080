import Vue from 'vue';
import Vuex from 'vuex';

import ui from './uiStore';
import home from './homeStore';
import articles from './articleStore';
import pages from './pageLoaderStore';
import mapStore from './mapStore';

Vue.use(Vuex);

export default new Vuex.Store<any>({
  modules: {
    ui,
    home,
    mapStore,
    pages,
    articles,
  },
});
