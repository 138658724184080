








































































// import Vue from 'vue';
import carousel from './owlCarousel/Carousel.vue';

export default {
  name: 'Instruction',
  components: {
    carousel: carousel,
  },
  props: {
    instruction: {
      type: Object,
    },
  },
  data() {
    return {
      currentTab: 0,
    };
  },
  methods: {},
};
