















export default {
  name: 'GeoLocator',
  methods: {
    onCenterRoute() {
      this.$root.$emit('center-map-on-route');
    },
  },
};
