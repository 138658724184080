
































































import { MaintenanceStatus } from '@/components/urgentService/http/dto/urgentService';
import { PropType } from 'vue';
import { isMobile } from '@/mixins/isMobile';
import GeoLocator from '@/components/urgentService/components/GeoLocator.vue';
import { pluralize } from '@/utils/pluralize';
import { SwipeDirections } from '@/components/urgentService/types';

export default {
  name: 'ServiceInfoBlock',
  components: { GeoLocator },
  props: {
    mechanicName: {
      type: String,
      required: true,
    },
    serviceStatus: {
      type: String as PropType<MaintenanceStatus>,
      required: true,
    },
    timeToArrival: {
      type: Number,
      required: true,
    },
    isLoadingService: {
      type: Boolean,
      required: true,
    },
    isSafetyMemoOpened: {
      type: Boolean,
      required: true,
    },
  },
  data: () => {
    return {
      touchstartY: 0,
      touchendY: 0,
      swipeDirection: SwipeDirections.UP,
    };
  },
  mixins: [isMobile],
  computed: {
    isLandscapedSafetyMemoOpened() {
      return (
        this.isSafetyMemoOpened &&
        window.screen.orientation.type.includes('landscape')
      );
    },
    isMobileMechanicInfoBlockHidden() {
      return this.swipeDirection === SwipeDirections.DOWN;
    },
    hasMechanicArrived() {
      return this.serviceStatus === MaintenanceStatus.Started;
    },
    shouldShowTime() {
      return (
        this.serviceStatus === MaintenanceStatus.NotStarted ||
        this.serviceStatus === MaintenanceStatus.NoRoute
      );
    },
    hasRoute() {
      return this.serviceStatus !== MaintenanceStatus.NoRoute;
    },
    mechanicBlockText() {
      const isMechanicOnTheWay =
        this.serviceStatus === MaintenanceStatus.NotStarted;
      let mechanicStatusText = '';
      if (isMechanicOnTheWay) {
        mechanicStatusText = this.$t(
          'lang.urgentService.mechanicInfoBlock.mechanicStatus.enRoute',
        );
      }
      if (this.hasMechanicArrived) {
        mechanicStatusText = this.$t(
          'lang.urgentService.mechanicInfoBlock.mechanicStatus.arrived',
        );
      }
      return `${this.$t('lang.urgentService.mechanicInfoBlock.title')} ${
        this.mechanicName
      } ${mechanicStatusText}`;
    },
    minutesText() {
      return pluralize(this.timeToArrival, [
        this.$t('lang.urgentService.timeInfoBlock.minutesDeclension.one'),
        this.$t('lang.urgentService.timeInfoBlock.minutesDeclension.twoToFour'),
        this.$t('lang.urgentService.timeInfoBlock.minutesDeclension.fiveToTen'),
      ]);
    },
  },
  methods: {
    changeSwipeDirection() {
      this.touchendY < this.touchstartY
        ? (this.swipeDirection = SwipeDirections.UP)
        : (this.swipeDirection = SwipeDirections.DOWN);
      this.$emit('block-hide-change', this.isMobileMechanicInfoBlockHidden);
    },
    onSwipeStart(e: TouchEvent) {
      this.touchstartY = e.changedTouches[0].screenY;
    },
    onSwipeEnd(e: TouchEvent) {
      this.touchendY = e.changedTouches[0].screenY;
      this.changeSwipeDirection();
    },
  },
};
