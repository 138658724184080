import axios from 'axios';
import router from '../router';
import ENV from '@/environment';

export default {
  namespaced: true,

  state: {
    apiUrl: ENV.APP_API_HOST,
    openCarsFilter: false,
    carsLoaded: false,
    carsCityLoaded: {},
    cars: {},
    tariffShort: [],
    normalizedCars: [],
    car: {},
    cities: [],
    geozones: [],
    carLoaded: false,
    geozonesLoaded: false,
    showTariffsModal: false,
    showAirportInstructionModal: false,
    selectedModalTariff: '',
    selectedModalAirport: {},
  },
  mutations: {
    setShowTariffsModal(state, payload) {
      state.showTariffsModal = payload.showTariff;
    },
    setShowAirportInstructionModal(state, payload) {
      state.showAirportInstructionModal = payload.showInstructionModal;
    },
    setSelectedModalTariff(state, payload) {
      state.selectedModalTariff = payload.tariff;
    },
    setSelectedModalAirport(state, payload) {
      state.selectedModalAirport = payload.airport;
    },
    toggleCarsFilter(state, payload) {
      state.openCarsFilter =
        payload !== undefined ? !!payload : !state.openCarsFilter;
    },
    carToggle(state) {
      state.carLoaded = !state.carLoaded;
    },
    carLoaded(state, payload) {
      state.car = payload.car;

      const tariffs = Object.keys(payload.car.tariff).map((key) => {
        return {
          id: key,
          ...payload.car.tariff[key],
        };
      });

      const tariffShort = tariffs.filter(
        (tariff) => !tariff.id.includes('_staff'),
      );
      state.car.tariffList = tariffs
        .filter(
          (tariff) =>
            tariff.id.includes('_basic') && !tariff.id.includes('_staff'),
        )
        .reverse();

      if (tariffShort && tariffShort[2]['24_hours_description']) {
        tariffShort[0]['24_hours_description'] =
          tariffShort[2]['24_hours_description'];
        if (tariffShort[2].description) {
          tariffShort[0].description = tariffShort[2].description;
        }
      }

      if (tariffShort && tariffShort[3]['24_hours_description']) {
        tariffShort[1]['24_hours_description'] =
          tariffShort[3]['24_hours_description'];
        if (tariffShort[3].description) {
          tariffShort[1].description = tariffShort[3].description;
        }
      }

      state.tariffShort = tariffShort;
    },
    carsLoaded(state, payload) {
      state.carsLoaded = true;

      state.cars = payload.geojson;
      state.normalizedCars = payload.cars;
    },
    geozonesLoaded(state, payload) {
      state.geozones = payload;

      const cities = [];
      payload.forEach((element) => {
        cities.push({
          id: element.id,
          name: element.title,
          nameRu: element.titleRu,
          value: element.name,
        });
      });
      state.cities = cities;

      state.geozonesLoaded = true;
    },
  },
  actions: {
    async loadCars({ commit, state }, params) {
      const response = await axios.get(`${state.apiUrl}/api/cars`, {
        params: { regionId: params.regionId },
      });

      if (!response.data) {
        router.push('Home');
      }

      commit('carsLoaded', response.data);
    },

    async loadCar({ commit, state }, params) {
      const { carId } = params;

      const response = await axios.get(`${state.apiUrl}/api/cars/${carId}`);

      if (!response.data.success) {
        router.push('Home');
      }

      commit('carLoaded', response.data);
    },

    async loadGeozones({ commit, state }, params) {
      const response = await axios.get(`${state.apiUrl}/api/geozones`);

      if (!response.data.success) {
        router.push('Home');
      }

      commit('geozonesLoaded', response.data.data);
    },
  },
  getters: {
    sortedByOrderCities(state) {
      const citiesSortOrder = [
        'msk',
        'spb',
        'ekb',
        'kzn',
        'nnv',
        'nsb',
        'rnd',
        'sam',
        'tla',
      ];

      if (!state.cities.length) {
        return [];
      }

      return citiesSortOrder
        .map((orderElement) =>
          state.cities.find((city) => city.value === orderElement),
        )
        .filter((city) => !!city);
    },
  },
};
