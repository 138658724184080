<template>
  <div>
    <urgent-service />
  </div>
</template>

<script>
import UrgentService from '@/components/urgentService/UrgentService.vue';

export default {
  name: 'UrgentServiceLayout',
  components: { UrgentService },
};
</script>
