
















































































































export default {
  name: 'TariffsModal',
  props: {
    tariffs: {
      type: Array,
    },
    tariffType: {
      type: String,
    },
  },
  data() {
    return {};
  },
  methods: {
    filteredTariffs(key: string) {
      return this.tariffs.filter((tarif) => tarif.id.includes(key));
    },
  },
};
