














import { isMobile } from '@/mixins/isMobile';
import { MaintenanceStatus } from '@/components/urgentService/http/dto/urgentService';
import { PropType } from 'vue';

export default {
  name: 'UrgentServiceFallBack',
  props: {
    maintenanceStatus: {
      type: String as PropType<MaintenanceStatus>,
      required: true,
    },
  },
  mixins: [isMobile],
  methods: {
    async goBack() {
      await this.$router.push({ name: 'Home' });
    },
  },
};
