<template>
  <div class="download">
    <span rel="noopener" class="btn" v-if="!isMobile">{{
      $t('lang.menu.download')
    }}</span>
    <a
      :href="link"
      target="_blank"
      rel="noopener"
      class="btn"
      v-if="isMobile"
      >{{ $t('lang.menu.download') }}</a
    >
  </div>
</template>

<script>
import { isMobile } from '../../mixins/isMobile';
import { functions } from '../../mixins/functions';

import ENV from '@/environment';

export default {
  name: 'Download',
  mixins: [isMobile, functions],

  data() {
    return {
      link:
        ENV.REGION === 'by'
          ? 'https://anytimeby.onelink.me/V6SW/f6e85b12'
          : 'https://go.onelink.me/Jlks/e055cc89',
      ENV,
    };
  },

  computed: {
    isIOS() {
      const UA = window.navigator.userAgent.toLowerCase();
      return UA && /iphone|ipad|ipod|ios/.test(UA);
    },
    isAndroid() {
      const UA = window.navigator.userAgent.toLowerCase();
      return UA && UA.indexOf('android') > 0;
    },
  },
};
</script>
