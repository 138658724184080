import ENV from '@/environment';

export const agreementBreaker = `
  <div>
    <p>Чтобы расторгнуть договор и удалить аккаунт, нужно: </p>
    <p>1) зайти в раздел «Настройки» в боковом меню; </p>
    <p>2) нажать «Удалить аккаунт»; </p>
    <p>3) написать причину в свободной форме в появившемся поле (для телефонов на андроиде). </p>
    <p>Аккаунт и все персональные данные удалятся ${ENV.REGION === 'ru'? 'через десять рабочих дней.' : 'со сроком до шестидесяти дней.'}</p>
    <p>Если остался неоплаченный счет или штраф, удалить аккаунт получится только после оплаты. </p>
  </div>
`;
