export function pluralize(number: number, options: [string, string, string]) {
  let num = number % 100;
  if (num >= 5 && num <= 20) {
    return options[2];
  }
  num %= 10;
  if (num === 1) {
    return options[0];
  }
  if (num >= 2 && num <= 4) {
    return options[1];
  }
  return options[2];
}
