export const privacyPolicySmallBusiness = `
<p>
    Настоящим я, в соответствии со статьей 9 Федерального закона от 27.07.2006 № 152-ФЗ "О персональных данных", в целях:
</p>
<p>
- осуществления со мной взаимодействия посредством электронной почты или телефонной связи;
</p>
<p>
- направления Оператору акцепта о заключении договора Делимобиль на условиях, изложенных в Оферте о заключении договора Делимобиль (далее – Договор);
</p>
<p>
- проверки достоверности представленных мною сведений;
</p>
<p>
   - проверки моих полномочий на заключение и подписание Договора и иных документов в рамках исполнения Договора;
</p>
<p>
- формирования Учетной записи Пользователя и активации Пользователя в сервисе Оператора (Пользователь – юридическое лицо (или индивидуальный предприниматель), акцептовавшее Оферту о заключении Договора);
</p>
<p>
- исполнения Договора, в том числе осуществления расчетов по Договору, использования сервиса Оператора,
</p>
<p>
даю согласие Акционерному обществу «Каршеринг Руссия» (ОГРН 1237700701534, ИНН 9718236471), юридический адрес: 107023, г. Москва, ул. Электрозаводская, дом 27, ст.1А, именуемому также – «Оператор», на автоматизированную, а также без использования средств автоматизации обработку моих персональных данных, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (доступ), блокирование, удаление, уничтожение персональных данных.
</p>
<p>
    Перечень моих персональных данных, на обработку которых я даю согласие: фамилия, имя, отчество;  гражданство; пол; дата и место рождения; данные документа, удостоверяющего личность (серия, номер, дата выдачи, орган выдачи, код подразделения); адрес регистрации по месту жительства; сведения о занимаемой должности и месте работы; номер телефона; адрес личной электронной почты (e-mail).
</p>
<p>
    Срок действия моего согласия на обработку персональных данных – в течение срока действия Договора. Данное согласие может быть отозвано по моему письменному заявлению, при этом мне понятны юридически значимые последствия отзыва моего согласия.
</p>
<p>
    При достижении целей обработки мои персональные данные подлежат уничтожению в сроки, установленные законодательством РФ. Я уведомлен(а), что при отзыве мной согласия на обработку персональных данных Оператор вправе продолжить обработку моих персональных данных в случаях, предусмотренных Федеральным законом от 27.07.2006 № 152-ФЗ «О персональных данных». Требование об уничтожении не распространяется на персональные данные, для которых нормативными правовыми актами предусмотрена обязанность их хранения, в том числе после прекращения правоотношений.
</p>
<p>
    Мне известно, что письменному запросу я имею право на получение информации, касающейся обработки моих персональных данных (в соответствии с п.4 ст.14 Федерального закона "О персональных данных" от 27.06.2006 № 152-ФЗ).
</p>
<p>
    Подписанием настоящего согласия я подтверждаю, что ознакомлен (а) с положениями Федерального закона от 27.07.2006 №152-ФЗ «О персональных данных», права и обязанности в области защиты персональных данных мне понятны.
</p>
<p>
    Одновременно я гарантирую, что являюсь единоличным исполнительным органом Пользователя (в случае, если Пользователь является юридическим лицом)/являюсь индивидуальным предпринимателем, регистрируемым в качестве Пользователя, указанные выше персональные данные предоставлены Оператору непосредственно мной.
</p>
<p>
    Нажимая кнопку "согласен", я подтверждаю свое согласие на обработку персональных данных.
</p>
`;
