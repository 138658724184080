export const guidoPrivacy = `
    <div>
      <p align="center">
        <strong>
          ПОЛИТИКА ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
        </strong>
      </p>
      <p align="center">
        <strong>1. </strong>
        <strong>Термины и определения</strong>
      </p>
      <p>
        <strong>Оператор</strong>
        – Общество с ограниченной ответственностью «Каршеринг Руссия», АО «Каршеринг Руссия» (107023, г. Москва, ул. Электрозаводская, дом 27, ст.1А, эт. 1, пом. IV, ком. 26, ОГРН: 1237700701534).
      </p>
      <p>
        <strong>Мобильное приложение</strong>
        – электронное приложение GUIDO для мобильных устройств.
      </p>
      <p>
        <strong>Партнер</strong>
        – юридическое лицо или индивидуальный предприниматель, заключивший с Пользователем договор подряда или оказания услуг, по которому Пользователь выполняет работы/оказывает услуги Оператору.
      </p>
      <p>
        <strong>Персональные данные</strong>
        – любая информация, относящаяся к прямо или косвенно определенному, или определяемому физическому лицу (субъекту персональных данных).
      </p>
      <p>
        <strong>Обработка персональных данных</strong>
        – любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.
      </p>
      <p>
        <strong>Автоматизированная обработка персональных данных</strong>
        – обработка персональных данных с помощью средств вычислительной техники.
      </p>
      <p align="center">
        <strong>2. </strong>
        <strong>Общие положения</strong>
      </p>
      <p>
        Настоящий документ (далее – Политика) определяет политику Оператора при обработке персональных данных пользователей Мобильного приложения GUIDO (далее соответственно – Пользователь, Мобильное приложение), и разработан в целях реализации положений законодательства Российской Федерации в отношении обработки персональных данных, обеспечения прав и свобод субъектов персональных данных.
      </p>
      <p>
        Обработка персональных данных осуществляется Оператором на законной и справедливой основе, ограничивается достижением конкретных, заранее определенных и законных целей. Оператором не осуществляется обработка персональных данных, не совместимая с целями их сбора, а также объединение баз данных, содержащих персональные данные, обрабатываемые в несовместимых целях. Обработке подлежат только персональные данные, которые отвечают целям их обработки. Содержание и объем обрабатываемых персональных данных соответствуют заявленным целям обработки и не являются избыточными.
      </p>
      <p>
        При обработке персональных данных обеспечивается их точность, достаточность и актуальность. Оператор принимает необходимые меры по удалению или уточнению неполных или неточных данных.
      </p>
      <p>
        Хранение персональных данных осуществляется в форме, позволяющей определить субъекта персональных данных, не дольше, чем этого требуют цели обработки персональных данных.
      </p>
      <p align="center">
        <strong>3. </strong>
        <strong>Условия обработки персональных данных</strong>
      </p>
      <p>Оператор может получать Персональные данные как непосредственно от Пользователя, так и от Партнеров, при этом Пользователь уведомлен о факте и условиях обработки его персональных данных Оператором путем ознакомления с настоящей Политикой.</p>
      <p>Оператор осуществляет обработку персональных данных Пользователя на основании предоставленного согласия. Пользователь дает Оператору согласие на обработку своих персональных данных свободно, в своей воле и своем интересе.</p>
      <p>Согласие Пользователя на обработку персональных данных предоставляется Пользователем путем совершения конклюдентных действий. Получение доступа к функционалу Мобильного приложения в соответствии с ролью Пользователя посредством ввода пары «Логин + Код подтверждения» в Мобильном приложении означает предоставление согласия на обработку персональных данных на условиях, предусмотренных настоящей Политикой.</p>
      <p>Обработка персональных данных осуществляется следующими способами: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передача (предоставление, доступ), удаление.</p>
      <p>Обрабатываемые персональные данные уничтожаются по достижении целей обработки или в случае утраты необходимости в достижении таких целей, при отзыве субъектом согласия на обработку персональных данных.</p>
      <p>Срок обработки персональных данных – до даты отзыва согласия Пользователем. Согласие на обработку персональных данных может быть в любой момент отозвано Пользователем путем направления заявления на адрес электронной почты Оператора: info@delimobil.ru, при этом Пользователю известно, что в случае отзыва такого согласия он не сможет пользоваться функционалом Мобильного приложения.</p>
      <p align="center">
        <strong>4. </strong>
        <strong>
          Права Пользователей в отношении персональных данных
        </strong>
      </p>
      <p>
        Пользователь имеет право на доступ к его персональным данным, получение информации об обработке в составе и с учетом ограничений, установленных ст. 14 Федерального закона «О персональных данных».
      </p>
      <p>Пользователь вправе требовать от Оператора уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.</p>
      <p>Если Пользователь считает, что Оператор осуществляет обработку его персональных данных с нарушением требований законодательства или иным образом нарушает его права и свободы, Пользователь вправе обжаловать действия или бездействие Оператора в уполномоченный орган по защите прав субъектов персональных данных или в судебном порядке.</p>
      <p align="center">
        <strong>5. </strong>
        <strong>Категории персональных данных:</strong>
      </p>
      <p>Обрабатываемые персональные данные Пользователей относятся к иным категориям персональных данных. Оператором не осуществляется обработка биометрических персональных данных, а также персональных данных специальных категорий.</p>
      <p>Оператором не принимаются решения, порождающие юридические последствия в отношении субъектов персональных данных или иным образом затрагивающие их права и законные интересы, на основании исключительно автоматизированной обработки их персональных данных.</p>
      <p>
        Состав обрабатываемых персональных данных Пользователей:
        </p>
      <ul>
        <li>фамилия, имя, отчество;</li>
        <li>номер мобильного телефона;</li>
        <li>информация о геолокации;</li>
        <li>функционал (полномочия/роли);</li>
        <li>сведения о работах и услугах, оказанных с использованием функционала Мобильного приложения.</li>
      </ul>
      <p>Целью обработки персональных данных является согласование транспортных средств, а также перечня услуг/работ, подлежащих оказанию Пользователем в отношении согласованных транспортных средств, получение и исполнение Пользователем заявок на обслуживание транспортных средств по договору, заключенному между Партнером и Пользователем, получение и хранение сведений об оказанных услугах и работах.</p>
      <p>Сбор и обработка информации о геолокации осуществляется Оператором для отслеживания факта следования Пользователя в место расположения согласованного транспортного средства после принятия заявки на обслуживание, получения данных о времени нахождения в пути, а также времени, оставшемся до прибытия Пользователя в место нахождения транспортного средства.</p>
      <p align="center">
        <strong>6. </strong>
        <strong>Трансграничная передача персональных данных и локализация</strong>
      </p>
      <p>Оператор не осуществляет трансграничную передачу персональных данных Пользователей.</p>
      <p>При сборе персональных данных Оператором обеспечена запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение персональных данных с использованием баз данных, находящихся на территории Российской Федерации.</p>
      <p>Пользователь дает согласие Оператору на поручение обработки его персональных данных ООО «ДАТАЛАЙН» (111020, г. Москва, ул. Боровая, дом 7, ст. 10, пом. VII, эт. 2, ком. 8-13, ОГРН: 1077760860792) для цели их хранения в центре обработки данных Оператора.</p>
      <p align="center">
        <strong>7. </strong>
        <strong>Заключительные положения</strong>
      </p>
      <p>Настоящая Политика является общедоступной и размещена Мобильном приложении Оператора.</p>
      <p>Внесение изменений в настоящую Политику может быть вызвано изменениями в законодательстве, информационных системах Оператора, а также в процессах обработки персональных данных.</p>
      <p>Оператор имеет право вносить изменения в настоящую Политику при условии обязательной публикации таких изменений.</p>
    </div>
`;
