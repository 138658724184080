export const privacyPolicy = `
    <div>
      <p align="center">
        <strong>
          Политика обработки и защиты персональных данных в ООО «Каршеринг
          Руссия»
        </strong>
      </p>
      <p align="center">
        <strong>1. </strong>
        <strong>Термины и определения</strong>
      </p>
      <p>
        <strong>Общество</strong>
        – Общество с ограниченной ответственностью «Каршеринг Руссия», ООО
        «Каршеринг Руссия» (107023, г. Москва, ул. Электрозаводская, дом 27,
        ст.1А, эт. 1, пом. IV, ком. 26, ОГРН: 1237700701534).
      </p>
      <p>
        <strong>Персональные данные</strong>
        – любая информация, относящаяся к прямо или косвенно определенному, или
        определяемому физическому лицу (субъекту персональных данных).
      </p>
      <p>
        <strong>Обработка персональных данных</strong>
        – любое действие (операция) или совокупность действий (операций),
        совершаемых с использованием средств автоматизации или без использования
        таких средств с персональными данными, включая сбор, запись,
        систематизацию, накопление, хранение, уточнение (обновление, изменение),
        извлечение, использование, передачу (распространение, предоставление,
        доступ), обезличивание, блокирование, удаление, уничтожение персональных
        данных.
      </p>
      <p>
        <strong>Автоматизированная обработка персональных данных</strong>
        – обработка персональных данных с помощью средств вычислительной
        техники.
      </p>
      <p>
        <strong>Информационная система персональных данных</strong>
        – совокупность содержащихся в базах данных персональных данных и
        обеспечивающих их обработку информационных технологий и технических
        средств.
      </p>
      <p>
        <strong>Носитель персональных данных</strong>
        – физическое лицо или материальный объект, в том числе физическое поле,
        в котором персональные данные находят свое отражение в виде символов,
        образов, сигналов, технических решений и процессов, количественных
        характеристик физических величин.
      </p>
      <p>
        <strong>
          Ответственный за организацию обработки персональных данных
        </strong>
        – лицо, осуществляющее внутренний контроль за соблюдением Обществом и
        его работниками законодательства РФ о персональных данных, в том числе
        требований к защите персональных данных.
      </p>
      <p>
        <strong>
          Ответственный за обеспечение безопасности персональных данных
        </strong>
        - лицо, ответственное за обеспечение безопасности персональных данных,
        за реализацию и непрерывность соблюдения установленных мер защиты и
        осуществляющих поддержку функционирования средств защиты информации,
        применяемых в информационной системе персональных данных Общества.
      </p>
      <p>
        <strong>Пользователь</strong>
        – работник Общества, которому разрешено выполнять некоторые действия
        (операции) по обработке персональных данных в информационной системе
        персональных данных или использующее результаты ее функционирования.
      </p>
      <p>
        <strong>Безопасность персональных данных</strong>
        – состояние защищенности персональных данных, при котором обеспечены их
        конфиденциальность, доступность и целостность.
      </p>
      <p>
        <strong>Конфиденциальность персональных данных</strong>
        – обязательное для выполнения лицом, получившим доступ к персональным
        данным, требование не передавать такие персональные данные третьим лицам
        без согласия ее обладателя.
      </p>
      <p>
        <strong>Доступность персональных данных</strong>
        – состояние персональных данных, при котором субъекты, имеющие права
        доступа, могут реализовать их беспрепятственно.
      </p>
      <p>
        <strong>Целостность персональных данных</strong>
        – состояние персональных данных, при котором их изменение осуществляется
        только преднамеренно субъектами, имеющими на него право.
      </p>
      <p>
        <strong>Предоставление персональных данных</strong>
        – действия, направленные на раскрытие персональных данных определенному
        лицу или определенному кругу лиц.
      </p>
      <p>
        <strong>Блокирование персональных данных</strong>
        – временное прекращение обработки персональных данных (за исключением
        случаев, если обработка необходима для уточнения персональных данных).
      </p>
      <p>
        <strong>Несанкционированный доступ</strong>
        (несанкционированные действия) – доступ к персональным данным или
        действия с персональными данными, нарушающие правила разграничения
        доступа с использованием штатных средств, предоставляемых
        информационными системами персональных данных.
      </p>
      <p align="center">
        <strong>2. </strong>
        <strong>Общие положения</strong>
      </p>
      <p>
        Политика обработки и защиты персональных данных в Обществе с
        ограниченной ответственностью «Каршеринг Руссия» (далее – Политика)
        разработана в соответствии с п.2 ч.1 ст.18.1 Федерального закона РФ от
        27.07.2006 № 152-ФЗ «О персональных данных» и определяет основные
        принципы, цели и условия обработки персональных данных (далее – ПДн), а
        также стратегию их защиты в Обществе с ограниченной ответственностью
        «Каршеринг Руссия» (далее – Общество, Оператор).
      </p>
      <p>
        Настоящая Политика является основным руководящим внутренним документом
        Общества, определяющим требования, предъявляемые в отношении обработки и
        обеспечения безопасности ПДн.
      </p>
      <p>
        Внутренние документы Общества, регламентирующие вопросы, рассматриваемые
        в настоящей Политике, должны разрабатываться с учетом положений
        настоящей Политики и не противоречить им.
      </p>
      <p>
        Настоящая Политика разработана в целях реализации положений
        законодательства Российской Федерации в отношении обработки ПДн, а также
        требований нормативных и методических документов по защите ПДн.
      </p>
      <p align="center">
        <strong>3. </strong>
        <strong>Принципы обработки персональных данных Общества</strong>
      </p>
      <p>Обработка ПДн Обществом осуществляется на основе принципов:</p>
      <ul>
        <li>законности и справедливости целей и способов обработки ПДн;</li>
        <li>
          соответствия целей обработки ПДн законным целям, заранее определенным
          и заявленным при сборе ПДн, а также полномочиям Общества;
        </li>
        <li>
          соответствия объема и содержания обрабатываемых ПДн, способов
          обработки ПДн целям обработки ПДн;
        </li>
        <li>
          точности ПДн, их достаточности и актуальности по отношению к целям
          обработки ПДн;
        </li>
        <li>
          недопустимости обработки ПДн, избыточных по отношению к целям,
          заявленным при сборе ПДн;
        </li>
        <li>
          недопустимости объединения созданных для несовместимых между собой
          целей баз данных, содержащих ПДн;
        </li>
        <li>
          хранения ПДн в форме, позволяющей определить субъекта ПДн, не дольше,
          чем этого требуют цели обработки ПДн, или устанавливающий срок
          хранения федеральный закон, договор, стороной которого,
          выгодоприобретателем или поручителем, по которому является субъект
          ПДн;
        </li>
        <li>
          уничтожения ПДн по достижении целей их обработки, в случае утраты
          необходимости в достижении целей обработки или по окончании срока
          хранения ПДн, установленного федеральным законом, договором, стороной
          которого, выгодоприобретателем или поручителем, по которому является
          субъект ПДн.
        </li>
      </ul>
      <p>
        Общество в своей деятельности исходит из того, что субъект ПДн
        предоставляет точную и достоверную информацию, во время взаимодействия с
        Обществом, извещает представителей Общества об изменении своих ПДн.
      </p>
      <p align="center">
        <strong>4. </strong>
        <strong>
          Цели и правовые основания обработки персональных данных
        </strong>
      </p>
      <p>
        Общество осуществляет сбор и дальнейшую обработку персональных данных в
        следующих целях:
      </p>
      <p>
        Соблюдение законов и иных нормативных правовых актов, обеспечение личной
        безопасности работников, контроль количества и качества выполняемой
        работы и обеспечение сохранности имущества Общества, получение
        работниками образования, в том числе дополнительного профессионального
        образования, осуществление расчетов по оплате труда (в том числе путем
        совершения безналичных платежей - оформления банковской карты и
        перечисления на нее заработной платы), обеспечения работников
        социальными льготами, исчисление и уплата предусмотренных
        законодательством РФ налогов, сборов и взносов на обязательное
        социальное и пенсионное страхование; идентификация и рассмотрения
        кандидатов на вакантные должности Оператора, проведение собеседований с
        целью трудоустройства; заключение и исполнение гражданско-правовых
        договоров, в том числе заключения и исполнения договора на услуги по
        доставке водителем-курьером товаров в период исполнения Договора
        Делимобиль; защита прав и интересов оператора в качестве кредитора в
        договорных и деликтных обязательствах; заключения и исполнения Договора
        Делимобиль (https://delimobil.ru/dogovor), а также договоров аренды ТС,
        заключенных в рамках Договора Делимобиль, и реализации Оператором
        полномочий, указанных в данных договорах, оказания Оператором
        дополнительных услуг, участия в проводимых Оператором акциях, опросах,
        исследованиях, принятия решений или совершения иных действий,
        порождающих юридические последствия, представление информации об
        оказываемых Оператором услугах, рекламной и иной информации,
        предоставление Оператором консультационных услуг.
      </p>
      <p>Основаниями для обработки ПДн субъектов ПДн Обществом являются:</p>
      <ul>
        <li>
          «Гражданский кодекс Российской Федерации (часть первая)» от 30 ноября
          1994 г. № 51-ФЗ;
        </li>
        <li>
          «Гражданский кодекс Российской Федерации (часть вторая)» от 26 января
          1996 г. № 14-ФЗ;
        </li>
        <li>
          «Налоговый кодекс Российской Федерации» от 31 июля 1998 года № 146-ФЗ;
        </li>
        <li>
          «Трудовой кодекс Российской Федерации» от 30 декабря 2001 г. № 197-ФЗ;
        </li>
        <li>
          Федеральный закон Российской Федерации от 27 июля 2006 г. № 149-ФЗ «Об
          информации, информационных технологиях и защите информации»;
        </li>
        <li>
          Федеральный закон Российской Федерации от 27 июля 2006 г. № 152-ФЗ «О
          персональных данных» (далее – ФЗ «О персональных данных»);
        </li>
        <li>
          Федеральный закон от 1 апреля 1996 г. № 27-ФЗ «Об индивидуальном
          (персонифицированном) учете в системе обязательного пенсионного
          страхования»;
        </li>
        <li>
          Статья 13.2 Федерального закона от 25 июля 2002 года № 115-ФЗ «О
          правовом положении иностранных граждан в Российской Федерации»;
        </li>
        <li>
          Федеральный закон от 29 декабря 2006 года № 255-ФЗ «Об обязательном
          социальном страховании на случай временной нетрудоспособности и в
          связи с материнством»;
        </li>
        <li>
          Федеральный закон от 06.12.2011г. № 402-ФЗ «О бухгалтерском учете»;
        </li>
        <li>
          Федеральный закон от 03.07.2016г. №230-ФЗ «О защите прав и законных
          интересов физических лиц при осуществлении деятельности по возврату
          просроченной задолженности и о внесении изменений в Федеральный закон
          "О микрофинансовой деятельности и микрофинансовых организациях»;
        </li>
        <li>
          Постановление Госкомстата России от 5 января 2004 года № 1 «Об
          утверждении унифицированных форм первичной учётной документации по
          учёту труда и его оплаты»;
        </li>
        <li>
          Постановление Правительства Российской Федерации от 15 сентября 2008
          г. № 687 «Об утверждении Положения об особенностях обработки
          персональных данных, осуществляемой без использования средств
          автоматизации»;
        </li>
        <li>
          Постановление правительства Российской Федерации от 01 ноября 2012 г.
          № 1119 «Об утверждении требований к защите персональных данных при их
          обработке в информационных системах персональных данных» (далее – №
          1119-ПП);
        </li>
        <li>
          согласие субъектов персональных данных на обработку ПДн (п. 1 ч. 1 ст.
          6 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных»);
        </li>
        <ul>
          <li>трудовые договоры;</li>
        </ul>
        <li>
          гражданско-правовые договоры, заключаемые между Оператором и Субъектом
          ПДн;
        </li>
        <li>
          договор с оператором персональных данных, который поручает Обществу
          обработку персональных данных;
        </li>
        <li>Устав Общества.</li>
      </ul>
      <p>
        Общество обрабатывает ПДн, ставшие известными Обществу в связи с
        реализацией уставных задач и целей деятельности Общества, а также в
        результате, но не ограничиваясь:
      </p>
      <ul>
        <li>заключения гражданско-правовых договоров;</li>
        <li>заключения соглашений о сотрудничестве;</li>
        <li>
          оформления и/или получения доверенностей (в том числе от имени
          Общества);
        </li>
        <li>
          получения любых иных документов от клиентов, контрагентов Общества,
          необходимых для заключения Обществом договоров с такими лицами;
        </li>
        <li>
          поступления в Общество письменных, в том числе электронных обращений,
          запросов, заявлений, жалоб, ходатайств;
        </li>
        <li>переписки по электронной почте;</li>
        <li>
          получения Обществом документов посетителей при посещении ими помещений
          Общества;
        </li>
        <li>
          осуществления иных действий, предусмотренных действующим
          законодательством Российской Федерации или внутренними политиками
          Общества.
        </li>
      </ul>
      <p align="center">
        <strong>5. </strong>
        <strong>Обрабатываемые персональные данные</strong>
      </p>
      <p>Общество обрабатывает ПДн следующих категорий субъектов ПДн:</p>
      <ul>
        <li>
          кандидатов на трудоустройство - фамилия, имя, отчество (в том числе
          прежние фамилии, имена и (или) отчества (при наличии), в случае их
          изменения); гражданство; пол; возраст; дата и место рождения;
          паспортные данные; адрес регистрации по месту жительства и адрес
          фактического проживания; номер телефона (домашний, мобильный); адрес
          личной электронной почты (email) (при наличии); данные документов об
          образовании, квалификации, профессиональной подготовке, сведения о
          повышении квалификации, знании иностранных языков; сведения о трудовой
          деятельности (о трудовом стаже, местах работы, датах приема и
          увольнения, должностях, среднемесячном доходе, количестве подчиненных,
          основных должностных обязанностях, службе в армии, работах на выборных
          должностях, на государственной или муниципальной службе и др.);
          сведения о наличии водительского удостоверения и стаже вождения
          автомобиля; сведения, указанные в миграционных документах (для
          иностранных граждан); отношение к воинской обязанности и сведения
          воинского учета; информация о приеме, переводе, увольнении и иных
          событиях, относящихся к трудовой деятельности, сведения о доходах;
          сведения о деловых и иных личных качествах, носящих оценочный
          характер; фотоизображение;
        </li>
        <li>
          работников Оператора, включая лиц, с которыми прекращен трудовой
          договор - фамилия, имя, отчество (в том числе прежние фамилии, имена и
          (или) отчества (при наличии), в случае их изменения); гражданство;
          пол; возраст; дата и место рождения; данные документа, удостоверяющего
          личность (серия, номер, дата выдачи, орган выдачи, код подразделения);
          адрес регистрации по месту жительства и адрес фактического проживания;
          номер телефона (домашний, мобильный); идентификационный номер
          налогоплательщика; номер страхового свидетельства государственного
          пенсионного страхования; адрес личной электронной почты (e-mail);
          данные банковского счета и карты для выплаты заработной платы; данные
          документов об образовании, квалификации, профессиональной подготовке,
          сведения о повышении квалификации, знании иностранных языков; сведения
          о трудовой деятельности (о трудовом стаже, местах работы, датах приема
          и увольнения, должностях, работах на выборных должностях, на
          государственной службе); сведения о занимаемой должности; сведения,
          указанные в миграционных документах (для иностранных граждан);
          отношение к воинской обязанности и сведения воинского учета; категория
          инвалидности и данные заключения медико-социальной экспертной комиссии
          (при наличии); медицинское заключение о возможности либо невозможности
          работы в конкретных условиях труда (при наличии); сведения о доходах;
          сведения о моей частной жизни (состояние в браке, состав семьи,
          которые могут понадобиться работодателю для предоставления льгот,
          предусмотренных трудовым и налоговым законодательством);
          фотоизображение;
        </li>
        <li>
          пользователей сервиса краткосрочной аренды - фамилия, имя, отчество;
          дата и место рождения; паспортные данные: серия и номер паспорта,
          наименование выдавшего документ органа, код подразделения, дата
          выдачи; данные водительского удостоверения: серия и номер документа,
          наименование органа, выдавшего документ, дата выдачи, дата окончания
          срока действия документа, категория прав; адрес места регистрации и
          проживания; данные свидетельства о регистрации по месту пребывания:
          дата выдачи, наименование органа (учреждения), выдавшего документ,
          период регистрации, адрес регистрации (для лиц, не имеющих постоянной
          регистрации в Москве или Московской области); номер, дата принятия
          решения о предоставлении вида на жительство на территории РФ, дата
          выдачи документа, подтверждающего предоставление вида на жительства,
          срок окончания разрешения, серия и номер указанного документа (для
          иностранных граждан, обладающих видом на жительство на территории РФ);
          номера контактных телефонов (мобильного телефона, зарегистрированного
          на имя Пользователя); адрес электронной почты; сведения, необходимые
          для совершения Пользователем платежей за услуги, предоставляемые
          Оператором; информация об истории пользования Сервисом Оператора:
          количество, стоимость, продолжительность услуг и иная информация об
          услугах Сервиса, полученных Пользователем, сведения об исполнении
          Пользователем Договора аренды ТС без экипажа, включая статистическую
          информацию о количестве правонарушений, совершенных Пользователем,
          выписанных и оплаченных штрафах, ДТП с участием Пользователя, имевших
          место в процессе пользования Сервисом; информация об услугах третьих
          лиц, полученных Пользователем в связи с обращением к Сервису Оператора
          (страховые продукты); информация об участии Пользователя в акциях
          Оператора; фотоизображения Пользователя;
        </li>
        <li>
          физических лиц, оказывающих услуги по доставке товаров
          (водители-курьеры) - фамилия, имя, отчество; дата и место рождения;
          паспортные данные: серия и номер паспорта, наименование выдавшего
          документ органа, код подразделения, дата выдачи; данные водительского
          удостоверения: серия и номер документа, наименование органа, выдавшего
          документ, дата выдачи, дата окончания срока действия документа,
          категория прав; адрес места регистрации и проживания; данные
          свидетельства о регистрации по месту пребывания: дата выдачи,
          наименование органа (учреждения), выдавшего документ, период
          регистрации, адрес регистрации (для лиц, не имеющих постоянной
          регистрации в Москве или Московской области); номер, дата принятия
          решения о предоставлении вида на жительство на территории РФ, дата
          выдачи документа, подтверждающего предоставление вида на жительства,
          срок окончания разрешения, серия и номер указанного документа (для
          иностранных граждан, обладающих видом на жительство на территории РФ);
          номера контактных телефонов; адрес электронной почты Пользователя;
          сведения о состоянии здоровья, содержащиеся в личной медицинской
          книжке; информация об истории использования транспортных средств
          Оператора в целях оказания услуг, включая статистическую информацию о
          количестве правонарушений, совершенных Субъектом, выписанных и
          оплаченных штрафах, ДТП с участием Субъекта, имевших место в процессе
          использования транспортных средств, информация об услугах третьих лиц,
          полученных в связи с обращением к Сервису Оператора (страховые
          продукты); фотоизображения; идентификационный номер цифрового
          пропуска; координаты начала и окончания поездки; номер ИНН; номер
          СНИЛС; реквизиты банковского счета;
        </li>
        <li>
          представителей контрагентов/ клиентов - фамилия, имя, отчество;
          гражданство; пол; дата и место рождения; данные документа,
          удостоверяющего личность (серия, номер, дата выдачи, орган выдачи, код
          подразделения), сведения, указанные в миграционных документах (для
          иностранных граждан), адрес, сведения о занимаемой должности, номер
          телефона, адрес электронной почты;
        </li>
        <li>
          физических лиц, с которыми Оператор заключил или планирует заключить
          гражданско-правовые договоры - фамилия, имя, отчество (информация о
          смене фамилии, имени, отчества); гражданство; пол; дата и место
          рождения; данные документа, удостоверяющего личность (серия, номер,
          дата выдачи, орган выдачи, код подразделения); сведения, указанные в
          миграционных документах (для иностранных граждан); реквизиты
          банковского счета; сведения о постановке на учет в качестве
          плательщика НПД или присвоении статуса Индивидуального предпринимателя
          (если применимо); номер СНИЛС; ИНН; ОГРНИП (если применимо); адрес
          регистрации по месту жительства и адрес фактического проживания;
          сведения о занимаемой должности; номер телефона; адрес личной
          электронной почты (e-mail);
        </li>
        <li>
          должников по деликтным обязательствам - фамилия, имя, отчество; дата
          рождения; адрес регистрации; номер телефона; данные водительского
          удостоверения: серия и номер документа, дата выдачи, категория прав;
        </li>
        <li>
          посетителей офисов - фамилия, имя, отчество, фирменное наименование
          организации, представителем которой является субъект, дата и период
          времени посещения, серия и номер, дата выдачи документа,
          удостоверяющего личность (паспорта или водительского удостоверения);
        </li>
        <li>
          посетители сайтов - фамилия, имя, отчество, гражданство, пол, дата и
          место рождения, данные документа, удостоверяющего личность (серия,
          номер, дата выдачи, орган выдачи, код подразделения), адрес, сведения
          о занимаемой должности, номер телефона, адрес электронной почты.
        </li>
        <li>
          Общество осуществляет обработку персональных данных с использованием
          средств автоматизации и без использования средств автоматизации.
        </li>
        <li>В Обществе обрабатываются следующие категории ПДн:</li>
        <li>
          иные категории персональных данных – персональные данные, не
          отнесенные к категориям: специальные, биометрические и общедоступные
          персональные данные;
        </li>
        <li>
          специальные категории персональных данных – сведения о состоянии
          здоровья.
        </li>
      </ul>
      <p>
        Перечень ПДн и категории субъектов ПДн утверждается «Перечнем
        обрабатываемых персональных данных».
      </p>
      <p align="center">
        <strong>6. </strong>
        <strong>ПОРЯДОК И Условия обработки персональных данных</strong>
      </p>
      <p>
        Обработка ПДн осуществляется следующими способами: сбор, запись,
        систематизацию, накопление, хранение, уточнение (обновление, изменение),
        извлечение, использование, передачу (предоставление, доступ),
        обезличивание, блокирование, удаление.
      </p>
      <p>Обработка ПДн Общества допускается в следующих случаях:</p>
      <ul>
        <li>
          обработка ПДн осуществляется с согласия субъекта ПДн на обработку его
          ПДн;
        </li>
        <li>
          обработка ПДн необходима для достижения целей, предусмотренных
          международным договором Российской Федерации или законом, для
          осуществления и выполнения возложенных законодательством Российской
          Федерации на оператора функций, полномочий и обязанностей;
        </li>
        <li>
          обработка ПДн необходима для осуществления правосудия, исполнения
          судебного акта, акта другого органа или должностного лица, подлежащих
          исполнению в соответствии с законодательством Российской Федерации;
        </li>
        <li>
          обработка ПДн необходима для исполнения договора, стороной которого
          либо выгодоприобретателем или поручителем, по которому является
          субъект ПДн, а также для заключения договора по инициативе субъекта
          ПДн;
        </li>
        <li>
          обработка ПДн необходима для осуществления прав и законных интересов
          оператора или третьих лиц, в том числе в случаях, предусмотренных
          Федеральным законом "О защите прав и законных интересов физических лиц
          при осуществлении деятельности по возврату просроченной задолженности
          и о внесении изменений в Федеральный закон "О микрофинансовой
          деятельности и микрофинансовых организациях", либо для достижения
          общественно значимых целей при условии, что при этом не нарушаются
          права и свободы субъекта персональных данных
        </li>
        <li>
          обработка ПДн осуществляется в статистических или иных
          исследовательских целях при условии обязательного обезличивания ПДн;
        </li>
        <li>
          осуществляется обработка ПДн, подлежащих опубликованию или
          обязательному раскрытию в соответствии с федеральным законом.
        </li>
      </ul>
      <p>
        Общество осуществляет хранение ПДн в базах данных, находящихся на
        территории Российской Федерации.
      </p>
      <p>
        Обществом соблюдаются требования о конфиденциальности персональных
        данных, установленные ст. 7 ФЗ «О персональных данных».
      </p>
      <p>
        Общество вправе поручить обработку ПДн другому лицу с согласия субъекта
        ПДн, если иное не предусмотрено федеральным законом, на основании
        заключаемого с этой стороной договора (поручения на обработку ПДн).
        Лицо, осуществляющее обработку ПДн по поручению Общества, обязано
        соблюдать принципы и правила обработки ПДн, предусмотренные ФЗ «О
        персональных данных».
      </p>
      <p>
        В поручении третьему лицу указываются цели обработки и перечень действий
        (операций) с ПДн, которые могут быть совершены данным лицом,
        устанавливаются его обязанности по обеспечению конфиденциальности и
        безопасности ПДн при их обработке, а также требования к защите
        обрабатываемых ПДн в соответствии с ФЗ «О персональных данных».
      </p>
      <p>
        Обществом не принимаются решения, порождающие юридические последствия в
        отношении субъектов персональных данных или иным образом затрагивающие
        их права и законные интересы, на основании исключительно
        автоматизированной обработки их персональных данных.
      </p>
      <p>Общество прекращает обработку ПДн в следующих случаях:</p>
      <ul>
        <li>
          при выявлении неправомерных действий с ПДн в срок, не превышающий трех
          рабочих дней с даты такого выявления, Общество устраняет допущенные
          нарушения. В случае невозможности устранения допущенных нарушений
          Общество в срок, не превышающий десяти рабочих дней с даты выявления
          неправомерности действий с ПДн, уничтожает ПДн. Об устранении
          допущенных нарушений или об уничтожении ПДн Общество уведомляет
          субъекта ПДн или его законного представителя, а в случае, если
          обращение или запрос были направлены в уполномоченный орган по защите
          прав субъектов ПДн, также этот орган;
        </li>
        <li>
          при достижении цели обработки ПДн Общество незамедлительно прекращает
          обработку ПДн и уничтожает соответствующие ПДн в срок, не превышающий
          тридцати рабочих дней с даты достижения цели обработки ПДн;
        </li>
        <li>
          при отзыве субъектом ПДн согласия на обработку своих ПДн Общество
          прекращает обработку ПДн и уничтожает (за исключением ПДн, которые
          хранятся в соответствии с действующим законодательством) ПДн в срок,
          не превышающий тридцати рабочих дней с даты поступления указанного
          отзыва. Об уничтожении ПДн Общество уведомляет субъекта ПДн.
        </li>
      </ul>
      <p align="center">
        <strong>7. </strong>
        <strong>Согласие на обработку персональных данных</strong>
      </p>
      <p>
        Получение и обработка ПДн в случаях, предусмотренных ФЗ «О персональных
        данных», осуществляются Обществом с согласия субъекта ПДн, в том числе в
        письменной форме.
      </p>
      <p>Письменное согласие субъекта ПДн должно включать:</p>
      <ul>
        <li>
          фамилию, имя, отчество, адрес субъекта ПДн, номер основного документа,
          удостоверяющего его личность, сведения о дате выдачи указанного
          документа и выдавшем его органе;
        </li>
        <li>наименование и адрес Общества;</li>
        <li>цель обработки ПДн;</li>
        <li>
          перечень ПДн, на обработку которых дается согласие субъекта ПДн;
        </li>
        <li>
          наименование или фамилию, имя, отчество и адрес лица, осуществляющего
          обработку ПДн по поручению Общества, если обработка будет поручена
          такому лицу;
        </li>
        <li>
          перечень действий с ПДн, на совершение которых дается согласие, общее
          описание используемых Обществом способов обработки ПДн;
        </li>
        <li>срок, в течение которого действует согласие;</li>
        <li>подпись субъекта ПДн.</li>
      </ul>
      <p>
        Субъект ПДн дает Обществу согласие на обработку своих ПДн свободно, в
        своей воле и своем интересе. Согласие на обработку ПДн может быть
        отозвано субъектом ПДн путем направления в Общество письменного
        заявления в свободной форме. В этом случае (в отсуствие иных оснований
        для обработки) Общество обязуется прекратить обработку, а также
        уничтожить все имеющиеся в Обществе ПДн в сроки, установленные ФЗ «О
        персональных данных».
      </p>
      <p>
        Общество вправе обрабатывать ПДн без согласия субъекта ПДн (или при
        отзыве субъектом ПДн указанного согласия) при наличии оснований,
        указанных в пп. 2-11 ч. 1 ст. 6, ч. 2. ст. 10 и ч. 2 ст. 11 ФЗ «О
        персональных данных».
      </p>
      <p>
        Передача ПДн третьим лицам осуществляется Обществом с согласия субъекта
        ПДн в соответствии с требованиями действующего законодательства.
      </p>
      <p align="center">
        <strong>8. </strong>
        <strong>Права субъектов персональных данных</strong>
      </p>
      <p>
        Для обеспечения соблюдения установленных законодательством прав
        субъектов ПДн, в Обществе разработан и введен порядок работы с
        обращениями и запросами субъектов ПДн, а также порядок предоставления
        субъектам ПДн информации, установленной законодательством РФ в области
        ПДн.
      </p>
      <p>
        Субъект ПДн имеет право на получение информации, касающейся обработки
        Обществом его ПДн, в том числе содержащей:
      </p>
      <ul>
        <li>подтверждение факта обработки ПДн Обществом;</li>
        <li>правовые основания и цели обработки ПДн;</li>
        <li>цели и применяемые Обществом способы обработки ПДн;</li>
        <li>
          наименование и местонахождение Общества, сведения о лицах (за
          исключением работников Общества), которые имеют доступ к ПДн или
          которым могут быть раскрыты ПДн на основании договора с Обществом или
          на основании федерального закона;
        </li>
        <li>
          обрабатываемые ПДн, относящиеся к соответствующему субъекту ПДн,
          источник их получения, если иной порядок представления таких данных не
          предусмотрен федеральным законом;
        </li>
        <li>сроки обработки ПДн, в том числе сроки их хранения;</li>
        <li>
          порядок осуществления субъектом ПДн прав, предусмотренных ФЗ «О
          персональных данных»;
        </li>
        <li>
          информацию об осуществленной или о предполагаемой трансграничной
          передаче данных;
        </li>
        <li>
          наименование или фамилию, имя, отчество и адрес лица, осуществляющего
          обработку ПДн по поручению Общества, если обработка поручена или будет
          поручена такому лицу;
        </li>
        <li>
          иные сведения, предусмотренные ФЗ «О персональных данных» или другими
          федеральными законами.
        </li>
      </ul>
      <p>
        Общество предоставляет указанную информацию на основании
        соответствующего письменного запроса субъекта ПДн, содержащего: номер
        удостоверяющего личность документа, сведения о дате выдачи указанного
        документа и выдавшем его органе, сведения, подтверждающие участие
        субъекта ПДн в отношениях с Обществом (номер договора, дата заключения
        договора, условное словесное обозначение и (или) иные сведения) и
        подпись субъекта ПДн.
      </p>
      <p>
        Общество обязуется сообщить субъекту ПДн информацию о наличии ПДн,
        относящихся к соответствующему субъекту ПДн в течение тридцати дней с
        даты получения Запроса субъекта ПДн.
      </p>
      <p>
        Субъект ПДн вправе требовать от Общества уточнения его ПДн, их
        блокирования или уничтожения в случае, если ПДн являются неполными,
        устаревшими, неточными, незаконно полученными или не являются
        необходимыми для заявленной цели обработки, а также принимать
        предусмотренные законом меры по защите своих прав.
      </p>
      <p>
        Для реализации и защиты своих прав и законных интересов субъект ПДн
        имеет право обратиться к Оператору. Оператор рассматривает любые
        обращения и жалобы со стороны субъектов ПДн, тщательно расследует факты
        нарушений и принимает все необходимые меры для их немедленного
        устранения, наказания виновных лиц и урегулирования спорных и
        конфликтных ситуаций в досудебном порядке.
      </p>
      <p>
        Субъект ПДн имеет право на защиту своих прав и законных интересов, в том
        числе на возмещение убытков и/или компенсацию морального вреда в
        судебном порядке.
      </p>
      <p>
        Если субъект ПДн считает, что Общество осуществляет обработку его ПДн с
        нарушением требований ФЗ «О персональных данных» или иным образом
        нарушает его права и свободы, субъект ПДн вправе обжаловать действия или
        бездействие Общества в уполномоченный орган по защите прав субъектов ПДн
        или в судебном порядке.
      </p>
      <p>
        Право субъекта ПДн на доступ к его ПДн может быть ограничено в
        соответствии с федеральными законами, в том числе, если доступ субъекта
        ПДн к его персональным данным нарушает права и законные интересы третьих
        лиц.
      </p>
      <p align="center">
        <strong>9. </strong>
        <strong>Обеспечение безопасности персональных данных</strong>
      </p>
      <p>
        Для обеспечения безопасности ПДн Общество принимает необходимые и
        достаточные организационные и технические меры для защиты ПДн субъектов
        ПДн от неправомерного или случайного доступа к ним, уничтожения,
        изменения, блокирования, копирования, распространения, а также от иных
        неправомерных действий, включающие в том числе:
      </p>
      <ul>
        <li>
          назначение лица, ответственного за организацию обработки ПДн и лица,
          ответственного за обеспечение безопасности ПДн, а также определение их
          функций и полномочий;
        </li>
        <li>
          разработка и поддержание актуальности комплекта внутренних нормативных
          документов в отношении обработки и защиты ПДн;
        </li>
        <li>
          периодический внутренний контроль, а также внешний аудит соответствия
          обработки ПДн требованиям ФЗ «О персональных данных» и принятым в
          соответствии с ним нормативно-правовым актам;
        </li>
        <li>
          проведение оценки вреда, который может быть причинен субъектам ПДн в
          случае нарушения ФЗ «О персональных данных», а также соотношение
          указанного вреда с принимаемыми мерами по безопасности ПДн;
        </li>
        <li>
          ознакомление работников Общества, непосредственно осуществляющих
          обработку ПДн, с положениями законодательства Российской Федерации и
          внутренних нормативных документов Общества в отношении обработки и
          защиты ПДн, периодическое обучение вопросам обработки и защиты ПДн;
        </li>
        <li>определение угроз безопасности ПДн при их обработке в ИСПДн;</li>
        <li>
          применение прошедших в установленном порядке процедуру оценки
          соответствия средств защиты информации;
        </li>
        <li>учет работников, допущенных к обработке ПДн;</li>
        <li>учет материальных носителей ПДн;</li>
        <li>
          обнаружение фактов несанкционированного доступа к ПДн и принятием мер;
        </li>
        <li>
          восстановление ПДн, модифицированных или уничтоженных вследствие
          несанкционированного доступа к ним;
        </li>
        <li>
          установление правил доступа к ПДн, обрабатываемым в ИСПДн, а также
          обеспечение регистрации и учета всех действий, совершаемых с ПДн в
          ИСПДн;
        </li>
        <li>
          контроль за принимаемыми мерами по обеспечению безопасности ПДн и
          уровня защищенности ИСПДн.
        </li>
      </ul>
      <p>
        Комплекс мероприятий и технических средств по обеспечению безопасности
        ПДн в Обществе формулируется с учетом результатов оценки возможного
        вреда субъекту ПДн, который может быть нанесен в случае нарушения
        безопасности его ПДн, актуальности угроз безопасности ПДн, а также
        установления уровня защищенности ПДн.
      </p>
      <p align="center">
        <strong>10. </strong>
        <strong>
          Контроль за соблюдением законодательства РФ в области персональных
          данных
        </strong>
      </p>
      <p>
        Контроль за соблюдением локальных нормативных актов Общества в области
        ПДн осуществляется с целью проверки соответствия процессов обработки и
        защиты ПДн требованиям законодательства РФ в области ПДн, а также
        выявления возможных каналов утечки и несанкционированного доступа к ПДн.
      </p>
      <p>
        Внутренний контроль за соблюдением структурными подразделениями Общества
        требований законодательства РФ и локальных нормативных актов Общества в
        области ПДн осуществляется лицами, ответственными за обработку и защиту
        ПДн в Обществе.
      </p>
      <p>
        Работники Общества, виновные в нарушении норм, регулирующих обработку и
        защиту ПДн, установленных в Обществе, могут быть привлечены к
        дисциплинарной, материальной, гражданско-правовой, административной или
        уголовной ответственности в соответствии с законодательством РФ.
      </p>
      <p align="center">
        <strong>11. </strong>
        <strong>Заключительные положения</strong>
      </p>
      <p>
        Настоящая Политика является общедоступной и подлежит размещению на
        официальном сайте Общества.
      </p>
      <p>
        Внесение изменений в настоящую Политику может вызвано изменениями в
        законодательстве Российской Федерации, внутренних документах Общества,
        информационных системах ПДн, системе обработки и защиты ПДн.
      </p>
      <p>
        Все изменения и дополнения, внесенные в настоящую Политику, утверждаются
        генеральным директором Общества.
      </p>
      <p>
        Все работники Общества подлежат обязательному ознакомлению с настоящей
        Политикой и несут предусмотренную законодательством Российской Федерации
        ответственность за нарушение её положений.
      </p>
    </div>
`;
