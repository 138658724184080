<template>
  <div class="qr-download">
    <div class="qr-download__header">
      <h2
        class="qr-download__title"
        v-html="$t('lang.qrDownloadModal.title')"
      />
      <button
        @click="$emit('close')"
        type="button"
        class="qr-download__close-button"
      />
    </div>
    <div class="qr-download__tiles">
      <div class="qr-download__tile">
        <img
          class="qr-download__qr-img"
          alt="qr for download"
          :src="currentRegionQRImg"
        />
      </div>
      <div
        class="qr-download__tile qr-download__tile--text"
        :class="{ 'qr-download__tile--copy-success': isCopySuccess }"
      >
        <p
          class="qr-download__text"
          @click="copyToClipboard"
          v-html="$t('lang.qrDownloadModal.clickToCopy')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ENV from '@/environment';

export default {
  name: 'QRDownload',
  data() {
    return {
      isCopySuccess: false,
    };
  },
  computed: {
    currentRegion() {
      return ENV.REGION;
    },
    currentRegionQRUrl() {
      switch (this.currentRegion) {
        case 'ru': {
          return 'https://go.onelink.me/Jlks/e055cc89';
        }
        case 'by': {
          return 'https://anytimeby.onelink.me/V6SW/f6e85b12';
        }
        default: {
          return 'https://go.onelink.me/Jlks/e055cc89';
        }
      }
    },
    currentRegionQRImg() {
      switch (this.currentRegion) {
        case 'ru': {
          return 'img/download_qr_ru.png';
        }
        case 'by': {
          return 'img/download_qr_by.png';
        }
        default: {
          return 'img/download_qr_ru.png';
        }
      }
    },
  },
  methods: {
    copyToClipboard() {
      if (navigator) {
        navigator.clipboard
          .writeText(this.currentRegionQRUrl)
          .then(() => {
            this.isCopySuccess = true;
          })
          .catch(() => {
            this.isCopySuccess = false;
          });
      }
    },
  },
};
</script>

<style lang="less">
@fraction: 1 / 1280 * 100vw;

.qr-download__title {
  font-weight: 700;
  font-size: 16 * @fraction;
  line-height: 24 * @fraction;
  text-transform: none;
}

.qr-download {
  width: 460 * @fraction;
  padding: 40 * @fraction;

  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 24 * @fraction;
  }

  &__tiles {
    display: flex;
    justify-content: space-between;
  }

  &__tile {
    width: 180 * @fraction;
    background-color: @gray16;
    border-radius: 20px;
    transition: background-color 0.3s ease;

    &--text {
      padding: 60 * @fraction 24 * @fraction;
      text-align: center;
    }

    &--copy-success {
      background-color: rgba(220, 248, 242, 1);
    }
  }

  &__text {
    color: @green11;
    font-weight: 500;
    font-size: 14 * @fraction;
    line-height: 20 * @fraction;
  }

  &__qr-img {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__title {
    margin: 0;
  }

  &__close-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    min-width: 32 * @fraction;
    width: 32 * @fraction;
    height: 32 * @fraction;
    border-radius: 50%;
    transform: rotate(45deg);
    background: #e6e6ea;
    border: none;
    cursor: none;

    &::before,
    &::after {
      content: '';
      position: absolute;
      background: #000;
    }

    &::before {
      width: 20px;
      height: 2px;
    }

    &::after {
      width: 2px;
      height: 20px;
    }
  }
}
</style>
