export const events = [
  'initialize',
  'initialized',
  'resize',
  'resized',
  'refresh',
  'refreshed',
  'update',
  'updated',
  'drag',
  'dragged',
  'translate',
  'translated',
  'to',
  'changed',
];
