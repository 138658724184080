


















import { load as loadMap } from '@2gis/mapgl';
import { decode } from '@googlemaps/polyline-codec';
import { isMobile } from '@/mixins/isMobile';
import { adaptBlockToMobile } from '@/utils/adaptBlockToMobile';

import { calcPointsBetweenCoordinates } from '@/components/urgentService/utils/calcPointsBetweenCoordinates';
import { URGENT_SERVICE_INFO_REQUEST_INTERVAL } from '@/components/urgentService/consts';
import { calcDistanceBetweenCoordinates } from '@/components/urgentService/utils/calcDistanceBetweenCoordinates';

export default {
  name: 'Map',
  props: {
    routeEncodedPolyline: {
      type: String,
      required: true,
    },
    mechFallbackLng: {
      type: Number,
      required: true,
    },
    mechFallbackLat: {
      type: Number,
      required: true,
    },
    userFallbackLng: {
      type: Number,
      required: true,
    },
    userFallbackLat: {
      type: Number,
      required: true,
    },
    isMechanicBlockHidden: {
      type: Boolean,
      required: true,
    },
  },
  mixins: [isMobile],
  data: () => {
    return {
      mechLng: 0,
      mechLat: 0,
      userLat: 0,
      userLng: 0,
      mapglAPI: null,
      mapInst: null,
      userCar: null,
      mechCar: null,
      totalMechRoute: null,
      fakeMechPolylineCoords: [],
      currentMechRoute: null,
      fakeRouteMovingInterval: null,
      fakeMechLng: 0,
      fakeMechLat: 0,
      savedRouteCoords: [],
    };
  },
  async mounted() {
    window.addEventListener('resize', this.initMap);
    this.$root.$on('center-map-on-route', this.centerMapOnRoute);
    await this.initMap();
  },
  destroyed() {
    this.$root.$off('center-map-on-route');
    window.removeEventListener('resize', this.initMap);
  },
  watch: {
    decodedMechRoutePolyline(newValue, oldValue) {
      this.renderTotalMechanicRoute();

      const routesLengthDifference = oldValue.length - newValue.length;
      const newStartLng = this.fakeMechLng || oldValue[0][0];
      const newStartLat = this.fakeMechat || oldValue[0][1];
      const startCoords = [newStartLng, newStartLat];

      if (routesLengthDifference < 0) {
        this.fakeMechPolylineCoords = [
          startCoords,
          [newValue[0][0], newValue[0][1]],
        ];
      }

      if (routesLengthDifference === 0) {
        this.fakeMechPolylineCoords = [
          startCoords,
          [newValue[0][0], newValue[0][1]],
        ];
      }

      if (routesLengthDifference === 1) {
        this.fakeMechPolylineCoords = [startCoords, oldValue[1], newValue[0]];
      }

      const MAX_ADEQUATE_POLYLINE_LENGTH = 5;

      if (
        routesLengthDifference > 1 &&
        routesLengthDifference < MAX_ADEQUATE_POLYLINE_LENGTH
      ) {
        this.fakeMechPolylineCoords = [
          startCoords,
          ...oldValue.slice(1, routesLengthDifference - 1),
          this.decodedMechRoutePolyline[0],
        ];
      }

      if (routesLengthDifference >= MAX_ADEQUATE_POLYLINE_LENGTH) {
        this.fakeMechPolylineCoords = [
          startCoords,
          this.decodedMechRoutePolyline[0],
        ];
      }

      this.mechCar.setCoordinates(
        this.fakeMechPolylineCoords[0][0],
        this.fakeMechPolylineCoords[0][1],
      );

      this.handleFakeMove();
    },
  },
  computed: {
    decodedMechRoutePolyline() {
      return decode(this.routeEncodedPolyline)?.map((item) => {
        [item[1], item[0]] = [item[0], item[1]]; // lat / long -> long / lat у 2гиса и гугла различается порядок
        return item;
      });
    },
  },
  methods: {
    calcCenterBounds() {
      let mostNorthernLng = 0;
      let mostSouthernLng = 100;
      let mostEasternLat = 100;
      let mostWesternLat = 0;
      if (this.decodedMechRoutePolyline.length >= 2) {
        this.decodedMechRoutePolyline.forEach(([lng, lat]) => {
          if (lng > mostNorthernLng) {
            mostNorthernLng = lng;
          }
          if (lng < mostSouthernLng) {
            mostSouthernLng = lng;
          }
          if (lat < mostEasternLat) {
            mostEasternLat = lat;
          }
          if (lat > mostWesternLat) {
            mostWesternLat = lat;
          }
        });
      } else {
        mostNorthernLng = Math.max(this.userFallbackLng, this.mechFallbackLng);
        mostSouthernLng = Math.min(this.userFallbackLng, this.mechFallbackLng);
        mostEasternLat = Math.min(this.userFallbackLat, this.mechFallbackLat);
        mostWesternLat = Math.max(this.userFallbackLat, this.mechFallbackLat);
      }

      return {
        northEast: [mostNorthernLng, mostEasternLat],
        southWest: [mostSouthernLng, mostWesternLat],
      };
    },
    async initMap() {
      adaptBlockToMobile('.urgent-service');
      if (this.mapInst?.destroy) {
        this.mapInst.destroy();
      }
      await this.renderMap();

      this.renderCars();
      this.renderTotalMechanicRoute();
      this.centerMapOnRoute();
    },
    async renderMap() {
      this.mapglAPI = await loadMap();
      const intermediateLat = this.calcIntermediateCoords(
        this.userFallbackLat,
        this.mechFallbackLat,
      );
      const intermediateLong = this.calcIntermediateCoords(
        this.userFallbackLng,
        this.mechFallbackLng,
      );

      this.mapInst = new this.mapglAPI.Map(this.$refs.map, {
        center: [intermediateLong, intermediateLat],
        minZoom: 6,
        style: 'bf35123e-4d35-40df-875e-4c185c31332e',
        key: '1ee831fa-177f-41e6-ada1-bba9bfe34f69',
        disableRotationByUserInteraction: true,
        disablePitchByUserInteraction: true,
        copyright: 'top-left',
        zoomControl: false,
      });
    },
    calcIntermediateCoords(usersCoords: number, mechCoors: number) {
      const coordsDiff = Math.abs(usersCoords - mechCoors);
      let intermediateCoords = mechCoors - coordsDiff / 2;
      if (usersCoords > mechCoors) {
        intermediateCoords = usersCoords - coordsDiff / 2;
      } else {
        intermediateCoords = mechCoors - coordsDiff / 2;
      }
      return intermediateCoords;
    },
    calcVisibleServiceInfoPanelHeight() {
      const serviceInfoPanel = document.querySelector(
        '.service-info-block',
      ) as HTMLElement;
      const panelHeight = parseInt(getComputedStyle(serviceInfoPanel).height);
      const windowHeight = window.innerHeight;
      const visiblePanelRect = serviceInfoPanel.getBoundingClientRect();
      const visiblePanelTop = visiblePanelRect.top;
      const visiblePaneBot = visiblePanelRect.bottom;
      const realPanelHeight = Math.max(
        0,
        visiblePanelTop > 0
          ? Math.min(panelHeight, windowHeight - visiblePanelTop)
          : Math.min(visiblePaneBot, windowHeight),
      );
      const GEO_LOCATOR_HEIGHT = 60;
      return realPanelHeight + GEO_LOCATOR_HEIGHT;
    },
    centerMapOnRoute() {
      const isScreenLandscaped =
        window.innerWidth <= 992 &&
        window.matchMedia('(orientation: landscape)').matches;
      const SERVICE_INFO_BLOCK_WIDTH = 425;
      const DEFAULT_PADDING = 30;
      const LANDSCAPED_INFO_BLOCK_RIGHT_PADDING = 30;
      // расчет отступа для центрирования карты ровно на маршруте
      const bottomMapPadding =
        window.innerWidth <= 992 && !isScreenLandscaped
          ? this.calcVisibleServiceInfoPanelHeight() - 40
          : DEFAULT_PADDING;
      const rightMapPadding = isScreenLandscaped
        ? SERVICE_INFO_BLOCK_WIDTH + LANDSCAPED_INFO_BLOCK_RIGHT_PADDING
        : DEFAULT_PADDING;
      const leftMapPadding =
        isScreenLandscaped || window.innerWidth <= 992 // иногда вочер миксина isMobile не успевает срабатывать при смене ориентации экрана, поэтому для этой задачи он не подходит
          ? DEFAULT_PADDING
          : SERVICE_INFO_BLOCK_WIDTH;

      const routeBounds = this.calcCenterBounds();
      const paddingObject = {
        top: DEFAULT_PADDING,
        left: leftMapPadding,
        right: rightMapPadding,
        bottom: bottomMapPadding,
      };

      this.mapInst.fitBounds(routeBounds, {
        maxZoom: 40,
        padding: paddingObject,
      });
    },
    renderCar(lng, lat, carStorage, carRef) {
      this[carStorage] = new this.mapglAPI.HtmlMarker(this.mapInst, {
        coordinates: [lng, lat],
        html: carRef,
      });
    },
    renderCars() {
      // Из-за небольшой разницы в текущих координатах механика / клиента и координатах маршрута от механика до клиента
      // было принято решение в качестве координат механика брать первые координаты маршрута, а для координат клиента - последние.
      // В случае, если маршрут не удалось построить, клиент и механик отображаются по их текущим координатам
      this.userLng =
        this.decodedMechRoutePolyline?.[
          this.decodedMechRoutePolyline.length - 1
        ]?.[0] || this.userFallbackLng;
      this.userLat =
        this.decodedMechRoutePolyline?.[
          this.decodedMechRoutePolyline.length - 1
        ]?.[1] || this.userFallbackLat;

      this.renderCar(this.userLng, this.userLat, 'userCar', this.$refs.userCar);

      this.mechLng =
        this.decodedMechRoutePolyline?.[0]?.[0] || this.mechFallbackLng;
      this.mechLat =
        this.decodedMechRoutePolyline?.[0]?.[1] || this.mechFallbackLat;

      this.renderCar(this.mechLng, this.mechLat, 'mechCar', this.$refs.mechCar);
    },
    renderTotalMechanicRoute() {
      this.renderRoute('totalMechRoute', this.decodedMechRoutePolyline);
    },
    renderRoute(routeStorageName, routeCoordinates, color?: string) {
      if (this[routeStorageName]?.destroy) {
        this[routeStorageName].destroy();
      }
      if (routeCoordinates.length) {
        this[routeStorageName] = new this.mapglAPI.Polyline(this.mapInst, {
          coordinates: routeCoordinates,
          color: color || '#55555A',
          width: 4,
        });
      }
    },
    calcFakeMechMove(polylineToUse) {
      const currentPolyline = polylineToUse;
      const dotsBetween = []; // получаем точки между точками кривой маршрута

      currentPolyline.forEach((coord, index) => {
        if (index === currentPolyline.length - 1) return;
        const [currentLat, currentLng] = currentPolyline[index];
        const [nextLat, nextLng] = currentPolyline[index + 1];
        const distance = calcDistanceBetweenCoordinates(
          currentLat,
          currentLng,
          nextLat,
          nextLng,
        );

        const BIGGEST_OPTIMAL_STEP = 0.1;
        const BIG_DISTANCE_OPTIMAL_STEP = 0.01;
        let POINTS_SEPARATION_STEP = Math.abs(BIGGEST_OPTIMAL_STEP - distance); // чем больше расстояние, тем меньше нужен шаг, чтобы получить как можно больше точек на большом расстоянии, чтобы избежать телепортации
        if (
          POINTS_SEPARATION_STEP > BIGGEST_OPTIMAL_STEP ||
          POINTS_SEPARATION_STEP < BIG_DISTANCE_OPTIMAL_STEP
        ) {
          // если получившийся шаг больше максимально оптимального, то взята большая дистанция, для которой лучше взять минимально возможный шаг
          // если получившийся шаг меньше минимально оптимального, то точек будет слишком много и карта начнет зависать
          POINTS_SEPARATION_STEP = BIG_DISTANCE_OPTIMAL_STEP;
        }

        const dots = calcPointsBetweenCoordinates(
          coord,
          currentPolyline[index + 1],
          POINTS_SEPARATION_STEP,
        );
        dotsBetween.push(...dots);
      });

      return dotsBetween;
    },
    handleFakeMove() {
      clearInterval(this.fakeRouteMovingInterval);

      const totalDots = this.calcFakeMechMove([
        ...(this.fakeMechPolylineCoords || []),
      ]);

      const totalDotsLength = totalDots.length;

      const speed = URGENT_SERVICE_INFO_REQUEST_INTERVAL / totalDotsLength;

      const makeFakeMove = (dotIndex: number) => {
        if (this.fakeMechPolylineCoords.length) {
          this.fakeMechLng = totalDots[dotIndex][0]; // необходимо для проверки на конец маршрута - если координаты механика совпадают с координатами конца маршрута, то маршрут пройден
          this.fakeMechLat = totalDots[dotIndex][1]; // необходимо для проверки на конец маршрута
          this.mechCar.setCoordinates([this.fakeMechLng, this.fakeMechLat]);
          this.fakeMechPolylineCoords[0] = totalDots[dotIndex]; // необходимо для проверки на конец маршрута
          const totalDotsLength = totalDots.length;
          this.renderRoute(
            // отрисовка фейкового уменьшающегося маршрута
            'currentMechRoute',
            [
              ...totalDots.slice(dotIndex, totalDotsLength - 1),
              this.fakeMechPolylineCoords[
                this.fakeMechPolylineCoords.length - 1 // массив всех промежуточных точек между точками маршрута не включает точки маршрута
              ],
            ],
          );
        }
      };

      makeFakeMove(0); // для гладкого первого шага

      let moveI = 1;
      this.fakeRouteMovingInterval = setInterval(() => {
        if (moveI >= totalDots.length - 1) {
          clearInterval(this.fakeRouteMovingInterval);
          this.handleFakeRouteIsOver();
          return;
        }

        if (totalDots?.[moveI].length) {
          makeFakeMove(moveI);
        }
        moveI += 1;
      }, speed);
    },
    handleFakeRouteIsOver() {
      const isLngMatchesEnd =
        this.fakeMechLng === this.fakeMechPolylineCoords?.[0]?.[0];
      const isLatMatchesEnd =
        this.fakeMechLat === this.fakeMechPolylineCoords?.[0]?.[1];
      if (isLatMatchesEnd && isLngMatchesEnd) {
        this.$emit('need-update-route');
      }
    },
  },
};
